<template>
  <!-- 个人中心 -->
  <div class="pages">
    <div class="content">
      <div class="titleIcon"></div>
      <!-- 头部 -->
      <div class="top_title">
        <div style="color:#333">个人中心</div>
        <div class="quitPopup" @click="personalChang"></div>
      </div>
      <!-- 内容 -->
      <div class="flex content_box">
        <div class="left_box flex-dir">
          <!-- 用户头像信息 -->
          <div class="userData flex-dir">
            <img :src="userInfo.avatar" v-if="userInfo.avatar" alt="" class="headImg"  />
            <img src="../../../src/image/room/89.png" v-else alt="" class="headImg"  />
            <div class="flex font-14 padding_8" style="color:#333">
              {{ userInfo.nickname }}
              <!-- <img src="../../static/user1.png" alt="" class="padding_r4" /> -->
            </div>
            <div
              class="userId flex-content"
              @click="copyUserId(userInfo.userNo)"
            >
              ID:{{ userInfo.userNo }}
            </div>
          </div>
          <!-- 用户头像信息end -->
          <!-- 左侧列表start -->
          <div class="flex-dir">
            <div
              class="left_item flex-content"
              :class="leftType == key0 ? 'left_itemPitch' : ''"
              v-for="(item, key0) in leftArr"
              :key="key0"
              @click="leftType = key0"
            >
              <img
                :src="item.url"
                alt=""
                class="padding_r4"
                style="width: 16px; height: 16px"
              />
              <div>{{ item.name }}</div>
            </div>
            <div
              class="left_item flex-content"
              :class="leftType == 8 ? 'left_itemPitch' : ''"
              @click="leftType = 8"
            >
              <!-- v-if="userInfo.isPlayer == 1" -->
              <div class="flex">
                <img
                  src="../../static/left7.png"
                  alt=""
                  class="padding_r4"
                  style="width: 16px; height: 16px"
                />
                <div>陪玩管理</div>
              </div>
            </div>
          </div>
          <!-- 左侧列表end -->
        </div>
        <div class="right_box">
          <!-- 我的信息 -->
          <myMessage
            @userUpdate="getInfo()"
            :userId="myId"
            v-if="leftType == 0"
          ></myMessage>
          <!-- 我的钱包 -->
          <myWallet v-if="leftType == 1"></myWallet>
          <!-- 财富特权 -->
          <wealthPrivileges v-if="leftType == 2"></wealthPrivileges>
          <!-- 道具商城 -->
          <stagePropertyStore v-if="leftType == 3"></stagePropertyStore>
          <!-- 我的订单 -->
          <myOrder v-if="leftType == 4"></myOrder>
          <!-- 我的礼物 -->
          <myPresent v-if="leftType == 5"></myPresent>
          <!-- 房间管理 -->
          <roomManage v-if="leftType == 6"></roomManage>
          <!-- 广场 -->
          <guangchang v-if="leftType == 7" :othUserId="userInfo.id"></guangchang>
          <!-- 陪玩管理 -->
          <playWithManage v-if="leftType == 8"></playWithManage>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myMessage from "../personalCenterAll/myMessage.vue";
import myWallet from "../personalCenterAll/myWallet.vue";
import wealthPrivileges from "../personalCenterAll/wealthPrivileges.vue";
import stagePropertyStore from "../personalCenterAll/stagePropertyStore.vue";
import myOrder from "../personalCenterAll/myOrder.vue";
import myPresent from "../personalCenterAll/myPresent.vue";
import roomManage from "../personalCenterAll/roomManage.vue";
import guangchang from "../personalCenterAll/guangchang.vue";
import playWithManage from "../personalCenterAll/playWithManage.vue";
export default {
  name: "",
  components: {
    myMessage, //我的信息
    myWallet, //我的钱包
    wealthPrivileges, //财富特权
    stagePropertyStore, //道具商城
    myOrder, //我的订单
    myPresent, //我的礼物
    roomManage, //房间管理
    playWithManage, //陪玩管理
    guangchang,//我的广场
  },
  props: {
    userId: {
      // type:Array, //类型属性
      required: true, //必传属性
      default: "", //默认属性
    },
  },
  data() {
    return {
      leftType: 0,
      myId: this.userId,
      userInfo: {}, //用户信息
      leftArr: [
        {
          url: require("@/static/left1.png"),
          name: "我的信息",
        },
        {
          url: require("@/static/left2.png"),
          name: "我的钱包",
        },
        {
          url: require("@/static/left3.png"),
          name: "财富特权",
        },
        {
          url: require("@/static/left4.png"),
          name: "道具商城",
        },
        {
          url: require("@/static/left5.png"),
          name: "我的订单",
        },
        {
          url: require("@/static/left6.png"),
          name: "我的礼物",
        },
        {
          url: require("@/static/left7.png"),
          name: "房间管理",
        },
        {
          url: require("@/static/left7.png"),
          name: "广场管理",
        },
      ],
    };
  },
  created(){
    this.getInfo();
  },
  mounted() {
    
  },
  methods: {
    copyUserId(id) {
      var input = document.createElement("input"); // 创建input对象
      input.value = id; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    personalChang() {
      this.$emit("closePersonal");
    },
    getInfo() {
      this.$api.queryUser({ otherUserId: this.myId }).then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
        }
      });
    },
    //设置tab值  我的订单使用   zwp
    setLeft(type) {
      this.$nextTick(() => {
        this.leftType = type;
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.pages {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.8);
}
.content {
  width: 1200px;
  height: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  .top_title {
    width: 1200px;
    height: 39px;
    font-size: 14px;
    color: #fff;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255,221,0,0.1);
    box-sizing: border-box;
  }
  .content_box {
    justify-content: space-between;
    padding: 10px 20px;
  }
  //左侧内容
  .left_box {
    width: 188px;
    height: 100%;
    display: flex;
    padding: 28px 0;
    align-content: center;
    justify-content: center;
    background: #fff;
    .userData {
      margin-bottom: 24px;
      .headImg {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
      .userId {
        width: 82px;
        height: 20px;
        font-size: 12px;
        color: #FFA700;
        background: rgba(255,167,0,0.2);
        border-radius: 10px;
      }
    }
    .left_item {
      width: 185px;
      color: #333;
      padding: 10px 0;
      font-size: 14px;
      border-radius: 8px 0px 0px 8px;
    }
    .left_itemPitch {
      background-color: #ffdd00;
      color: #212121;
    }
  }
  .right_box {
    width: 992px;
    height: 680px;
    padding: 10px ;
    background: #f7f7f9;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .quitPopup {
    width: 15px;
    height: 15px;
    background: url(../../static/quit.png) no-repeat;
    cursor: pointer;
  }
}
</style>