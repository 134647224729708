<template>
  <div class="playPage">
    <!-- 我的公会 -->
    <div class="play_title font-14 flex-aling">我的公会</div>
    <div class="society_box" v-if="associationData">
      <div class="flex-aling">
        <img :src="associationData.guildLogo" alt="" class="society_head" />
        <div>
          <div class="flex-aling">
            <p class="font-16" style="color: #363659">
              申请加入 {{ associationData.guildName }}
            </p>
            <p
              class="font-12"
              style="color: #ff2a3e; margin-left: 11px"
              v-if="associationData.type == 0"
            >
              审核中
            </p>
            <p
              class="font-12"
              style="color: #ff2a3e; margin-left: 11px"
              v-if="associationData.type == 1"
            >
              退会申请中
            </p>
            <p
              class="font-12"
              style="color: #ff2a3e; margin-left: 11px"
              v-if="associationData.type == 2"
            >
              已入会
            </p>
          </div>
          <div class="font-12" style="color: #8e92b1; margin-top: 12px">
            公会编号：{{ associationData.inviteCode }}
          </div>
        </div>
      </div>
      <div
        class="society_manage flex-content"
        @click="withdrawById()"
        v-if="associationData.type == 0"
      >
        撤销申请
      </div>
      <div
        class="society_manage flex-content"
        @click="revocation()"
        v-if="associationData.type == 1"
      >
        撤销申请
      </div>
      <div
        class="society_manage1 flex-content"
        @click="getQueryByUserId()"
        v-if="associationData.type == 2"
      >
        公会管理
      </div>
    </div>
    <div class="society_box" v-else>
      <div class="flex-aling">
        <img src="../../static/186.png" alt="" class="society_head" />
        <div>
          <div class="flex-aling">
            <p class="font-16" style="color: #363659">暂未加入公会</p>
          </div>
        </div>
      </div>
      <div class="society_manage flex-content" @click="guildUserList()">
        加入公会
      </div>
    </div>
    <!-- 个人中心--陪玩管理 -->
    <div
      style="justify-content: space-between; display: flex; align-items: center"
    >
      <div class="play_title font-14 flex-aling">大神技能</div>
      <div @click="addNewAptitude">+增加资质</div>
    </div>

    <div class="flex_wrap">
      <div
        v-for="(item, key0) in certificationList"
        :key="key0"
        style="margin-right: 10px"
      >
        <!-- 资质类型 -->
        <div class="certification_type">
          <img
            src="../../static/165.png"
            alt=""
            class="certification_rightIcon"
            @click="getPlayerSkillInfo(item)"
          />
          <div class="flex">
            <div style="position: relative">
              <!-- <img :src="item.certificationImg" alt="" class="certification_img" /> -->
              <img :src="item.labelLogo" alt="" class="certification_img" />
              <div class="chatOfTime" @click="playMusic(item)">
                <img src="../../static/22.png" alt="" class="chatOfTimeImg" />
                <p>{{ item.voiceLen }}“</p>
              </div>
            </div>
            <div>
              <p class="font-16" style="color: #333">{{ item.labelName }}</p>
              <p class="padding-r4" style="color: #8e92b1">
                {{ item.levelName }}
              </p>
              <p style="color: #333">
                <b class="font-20" style="color: rgba(255, 114, 104, 1)">{{
                  item.price
                }}</b
                >元宝/小时
              </p>
            </div>
          </div>
          <!-- 分割线 -->
          <div class="frameBar"></div>
          <!-- apply_status  申请状态（0-申请中  1-通过  2-驳回） -->
          <div
            class="flex-aling"
            style="justify-content: space-between"
            v-if="item.applyStatus == '0'"
          >
            <div></div>
            <div>审核中...</div>
          </div>
          <div
            class="flex-aling"
            style="justify-content: space-between"
            v-else-if="item.applyStatus == 1"
          >
            <div class="flex-aling">
              <!-- onOffState 0开启 1关闭 -->
              <el-switch
                v-model="item.onOffState"
                active-color="#4DC777"
                inactive-color="#ccc"
                :active-value="0"
                :inactive-value="1"
                @change="skillStateChange(item)"
              />
              <p
                class="font-12 padding_r4"
                style="color: #6a6a94"
                v-if="item.onOffState == 0"
              >
                接单中
              </p>
            </div>
            <div class="flex-aling" @click="runningAccountChange(item)">
              <p style="color: #333">流水</p>
              <img src="../../static/oth6.png" alt="" class="water_icon" />
            </div>
          </div>
          <div class="flex-aling" style="justify-content: space-between" v-else>
            <div></div>
            <div style="color: #ff2a3e">审核未通过，请重新提交！</div>
          </div>
        </div>
        <!-- 资质类型end -->
      </div>
    </div>
    <!-- 活动设置 -->
    <div class="play_title font-14 flex-aling" >
      <div>活动设置</div>
      <div class="font-12 padding_r10" style="color: #8e92b1">
        活动二选一，开启活动专区推荐位和活动标签，提高曝光
      </div>
      <div
        class="font-12 flex-aling"
        style="color: #7c4ff0"
        @click="activityExplainShow = true"
      >
        活动说明
        <img src="../../image/room/76.png" alt="" style="margin: 0 4px" />
      </div>
    </div>
    <!-- 活动内容 -->
    <div class="flex" >
      <div class="activity_box" style="margin-right: 10px">
        <div class="flex">
          <img src="../../static/112.png" alt="" style="margin-right: 10px" />
          <div>
            <p class="font-14">{{ systemSet.onePrice }}元宝专区</p>
            <p class="font-12" style="color: #d3d4dc">
              所有游戏技能单价均{{ systemSet.onePrice }}元宝
            </p>
          </div>
        </div>
        <el-switch
          v-model="systemSet.onePriceParticipate"
          :active-value="1"
          :inactive-value="0"
          active-color="#4DC777"
          inactive-color="#ccc"
          @change="activityOpening(1)"
        />
      </div>
      <div class="activity_box">
        <div class="flex">
          <!-- <img src="../../static/113.png" alt="" style="margin-right: 10px" /> -->
          <div class="activity_icon flex-dir">
            <p>买{{ systemSet.buyXy[0] }}</p>
            <p>送{{ systemSet.buyXy[1] }}</p>
          </div>
          <div>
            <p class="font-14">
              买{{ systemSet.buyXy[0] }}赠{{ systemSet.buyXy[1] }}
            </p>
            <p class="font-12" style="color: #d3d4dc">
              所有游戏技能均单次下单数量{{ systemSet.buyXy[0] }}，赠送{{
                systemSet.buyXy[1]
              }}
            </p>
          </div>
        </div>
        <el-switch
          v-model="systemSet.buyXyParticipate"
          :active-value="1"
          :inactive-value="0"
          active-color="#4DC777"
          inactive-color="#ccc"
          @change="activityOpening(2)"
        />
      </div>
    </div>
    <!-- 消息设置 -->
    <div class="play_title font-14 flex-aling" >消息设置</div>
    <div class="activity_box" >
      <div class="flex">
        <img src="../../image/room/75.png" alt="" style="margin-right: 10px" />
        <div>
          <p class="font-14">系统派单消息</p>
          <p class="font-12" style="color: #d3d4dc">
            关闭后将不接收派单厅派单消息
          </p>
        </div>
      </div>
      <el-switch
        v-model="systemSet.sysOrderMsg"
        :active-value="1"
        :inactive-value="0"
        active-color="#4DC777"
        inactive-color="#ccc"
        @change="orderTypeChange(systemSet.sysOrderMsg)"
      />
    </div>
    <div v-if="associationData">
      <img
        style="width: 950px; margin-top: 16px"
        src="../../static/121.png"
        alt=""
        @click="ischeckBtn"
      />
    </div>
    <!-- 加入公会弹窗 -->
    <div class="popUp_box box1" v-if="joinGuildsShow">
      <div class="pop_up" style="width: 500px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">加入公会</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="joinGuildsShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="joinGuilds_box">
          <div class="flex">
            <div style="position: relative">
              <el-input
                placeholder="请输入邀请码"
                v-model="inviteCodeVal"
              ></el-input>
              <div
                style="
                  position: absolute;
                  top: 25%;
                  right: 16px;
                  color: #6bbe64;
                "
                @click="getQueryByInviteCode()"
              >
                查询
              </div>
            </div>
            <div class="joinGuilds_btn flex-content" style="margin-left: 10px">
              申请加入
            </div>
          </div>
          <div class="joinGuilds_name">加入公会：--</div>
          <div class="dealText">
            <img
              src="../../static/107.png"
              alt=""
              @click="dealType = !dealType"
              v-if="dealType"
              style="width: 16px"
            />
            <img
              src="../../static/106.png"
              alt=""
              @click="dealType = !dealType"
              v-else
              style="width: 16px"
            />
            <span>请阅读</span>
            <span style="color: #7c4ff0" @click="signedChange()"
              >《公会签约说明》</span
            >
          </div>
          <div class="font-16">推荐公会</div>
          <div class="scrollBox" v-if="inviteCodeVal">
            <div
              class="flex-spacebetween joinGuilds_item"
              v-for="item in associationList"
              :key="item.id"
            >
              <div class="flex">
                <img :src="item.guildLogo" alt="" class="joinGuilds_head" />
                <div>
                  <div>{{ item.guildName }}</div>
                  <div class="font-12" style="color: #8e92b1">
                    邀请码：{{ item.inviteCode }}
                  </div>
                  <div class="font-12" style="color: #8e92b1">
                    {{
                      `${item.nickname || item.userNickName}(ID：${
                        item.userNo
                      })`
                    }}
                    <img
                      src="../../static/168.png"
                      alt=""
                      @click="copyUserId(item.userNo)"
                    />
                  </div>
                </div>
              </div>
              <div
                class="joinGuilds_btn flex-content"
                @click="applyToJoin(item)"
              >
                申请加入
              </div>
            </div>
          </div>
          <div class="scrollBox" v-else>
            <div
              class="flex-spacebetween joinGuilds_item"
              v-for="item in associationList"
              :key="item.id"
            >
              <div class="flex">
                <img :src="item.guildLogo" alt="" class="joinGuilds_head" />
                <div>
                  <div style="color: #333">{{ item.guildName }}</div>
                  <div class="font-12" style="color: #8e92b1">
                    邀请码：{{ item.inviteCode }}
                  </div>
                  <div class="font-12" style="color: #8e92b1">
                    {{
                      `${item.userNickName || item.nickname}(ID：${
                        item.userNo
                      })`
                    }}
                    <img
                      src="../../static/168.png"
                      alt=""
                      @click="copyUserId(item.userNo)"
                    />
                  </div>
                </div>
              </div>
              <div
                class="joinGuilds_btn flex-content"
                @click="applyToJoin(item)"
              >
                申请加入
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的公会弹窗 -->
    <div class="popUp_box box1" v-if="myGuildShow">
      <div class="pop_up" style="width: 510px; height: 630px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">我的公会</div>
          <img src="../../static/quit.png" alt="" @click="myGuildChange()" />
        </div>
        <!-- 头部end -->
        <div class="joinGuilds_box" style="width: 520px">
          <!-- 我的公会展示 -->
          <div v-if="myGuildType == 1">
            <div class="myGuild_item flex-spacebetween">
              <div v-if="!manageAssociation.guildName" class="myGuild_left">
                签约公会
              </div>
              <div v-else>{{ manageAssociation.guildName }}</div>
            </div>
            <div class="myGuild_item flex-spacebetween">
              <div v-if="!manageAssociation.guildName" class="myGuild_left">
                联系方式
              </div>
              <div v-else>{{ manageAssociation.mobile }}</div>
            </div>
            <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">加入身份</div>
              <div v-if="manageAssociation.userIdentity == '1'">创建人</div>
              <div v-else>公会大神</div>
            </div>
            <!-- <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">礼物结算比例</div>
              <div>{{ manageAssociation.giftProportion }}%</div>
            </div> -->
            <!-- <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">陪玩结算比例</div>
              <div>{{ manageAssociation.playProportion }}%</div>
            </div> -->
            <div
              class="myGuild_item flex-spacebetween"
              v-if="manageAssociation.signDateStart"
            >
              <div class="myGuild_left">签约合约期</div>
              <div>
                {{ manageAssociation.signDateStart }} 至
                {{ manageAssociation.signDateEnd }}
              </div>
            </div>
            <div
              class="myGuild_hint"
              v-if="manageAssociation.userIdentity != '1'"
            >
              提示<br />
              <div style="height: 6px"></div>
              1、您已与公会成功签约，您在合约期内履行与公会的约定。<br />
              <div style="height: 6px"></div>
              2、合约期内您无法单方面与公会解约，但可以与公会管理者协商沟通进行申请退会或支付一定的费用强制退会。<br />
              <div style="height: 6px"></div>
              3、如需与公会续约，可联系公会管理者，有公会发起续约邀请，系统消息通知仅进行续约操作，同意后即可续约成功。
            </div>
            <div
              class="myGuild_btn flex-content"
              @click="setApplyFor()"
              v-if="manageAssociation.userIdentity != '1'"
            >
              申请退会
            </div>
          </div>
          <!-- 申请退会 -->
          <div v-if="myGuildType == 2">
            <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">签约公会</div>
              <div>{{ manageAssociation.guildName }}</div>
            </div>
            <div
              class="myGuild_hint scroll_box"
              v-html="quitData.quitExplain"
            ></div>
            <div
              class="myGuild_btn flex-content"
              style="background: #363659; margin-bottom: 12px"
              @click="forceQuitChange()"
            >
              强制退会
            </div>
            <div class="myGuild_btn flex-content" @click="quitWarnShowChange()">
              申请退会
            </div>
          </div>
          <div v-if="myGuildType == 3">
            <div class="myGuild_text">
              <div class="font-14">
                我要强制退出{{ manageAssociation.guildName }}
              </div>
              <div class="font-12" style="color: #ff2a3e; margin-top: 4px">
                【强制退会】，需支付近30天收入(陪玩订单及礼物的总收入)的150%，手续费为退会费的10%。若退会费不足500则按照500缴纳。支付成功则退会成功；退会费全额结算给公会，手续费为平台所有。
              </div>
            </div>
            <div class="font-14" style="margin: 20px 0 10px 0">费用明细</div>
            <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">退会费</div>
              <div>￥{{ quitCost.quitFee || "0" }}</div>
            </div>
            <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">手续费</div>
              <div>￥{{ quitCost.handFee || "0" }}</div>
            </div>
            <div class="myGuild_item flex-spacebetween">
              <div class="myGuild_left">合计</div>
              <div>￥{{ quitCost.quitFee + quitCost.handFee || 0 }}</div>
            </div>
            <div class="font-14" style="margin: 20px 0 10px 0">支付方式</div>
            <div
              class="myGuild_item flex-content"
              :style="{ background: payType == 1 ? '#363659' : '#262b43' }"
              @click.stop="payType = 1"
            >
              微信支付
            </div>
            <div
              class="myGuild_item flex-content"
              :style="{ background: payType == 2 ? '#363659' : '#262b43' }"
              @click.stop="payType = 2"
            >
              支付宝支付
            </div>
            <div
              class="myGuild_btn flex-content"
              style="margin-top: 30px"
              @click="rechargeChange()"
            >
              支付并退会
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核中提醒 -->
    <div class="popUp_box box1" v-if="myGuildShowIng">
      <div class="pop_up" style="width: 510px; height: 430px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="myGuildShowIng = false"
          />
        </div>
        <div style="width: 256px; margin: 0 auto">
          <img
            src="../../static/129.png"
            style="width: 72px; height: 72px; margin: 48px 100px 26px"
            alt=""
          />
          <div class="contentJon">
            加入公会审核中，暂无法操作； 请等待公会审核通过后再进行认证；
          </div>
        </div>
      </div>
    </div>
    <!-- 退会提醒弹窗 -->
    <div class="popUp_box box1" v-if="quitWarnShow">
      <div class="pop_up" style="width: 490px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="quitWarnShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="joinGuilds_box flex-dir">
          <img src="../../static/129.png" alt="" />
          <div style="color: #ff2a3e; margin-top: 20px">
            确认退出 {{ associationData.guildName }} 公会吗？
          </div>
          <div
            class="flex-dir"
            v-if="quitData && quitData.isFullFortyFiveDays == 1"
          >
            <div style="color: #8e92b1; margin-top: 11px">
              您已入会满45天，申请退会需公会管理者审核，
            </div>
            <div style="color: #8e92b1">建议先与公会管理者进行友好协商。</div>
          </div>
          <div class="flex" style="margin-top: 24px">
            <div
              class="joinGuilds_btn flex-content"
              style="width: 176px; margin-right: 20px"
              @click="quitWarnShow = false"
            >
              再想想
            </div>
            <div
              class="joinGuilds_btn flex-content"
              style="width: 176px; background: #363659"
              @click="quitAssociation()"
            >
              确认退出
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 流水弹窗 -->
    <div class="popUp_box box1" v-if="runningAccountShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">{{ runningWater.skillName }}-流水</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="runningAccountShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="runningAccount_box">
          <!-- 汇总 -->
          <div class="flex-spacebetween">
            <div
              class="runnung_item"
              style="background: rgba(65, 119, 221, 0.1)"
            >
              <div class="font-14" style="color: #333">接单总量</div>
              <div style="font-weight: 400; font-size: 24px; color: #333">
                {{ runningWater.orderSumNum }}
              </div>
            </div>
            <div
              class="runnung_item"
              style="background: rgba(255, 167, 0, 0.1)"
            >
              <div class="font-14" style="color: #333">评分</div>
              <div
                style="
                  font-weight: 400;
                  font-size: 24px;
                  color: rgba(255, 167, 0, 1);
                "
              >
                {{ runningWater.scoreAvg }}
              </div>
            </div>
            <div
              class="runnung_item"
              style="margin-right: 0; background: rgba(255, 114, 104, 0.1)"
            >
              <div class="font-14" style="color: #333">总收入</div>
              <div
                style="
                  font-weight: 400;
                  font-size: 24px;
                  color: rgba(250, 85, 74, 1);
                "
              >
                {{ runningWater.revenueSum }}
                <img src="../../image/my/13.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 汇总end -->
          <div
            class="flex-spacebetween"
            style="align-items: center; margin: 20px 0 11px 0"
          >
            <div>结算流水</div>
            <el-date-picker
              class="runnung_date"
              v-model="tabell_date"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              @change="dateScreen()"
            >
            </el-date-picker>
          </div>
          <!-- 表格 -->
          <div class="runningAccount_table">
            <!-- 头部表头 -->
            <div class="flex-aling llacingCont_tr">
              <div class="llacingCont_heder flex-content" style="width: 300px">
                时间
              </div>
              <div class="llacingCont_heder flex-content" style="width: 210px">
                流水说明
              </div>
              <div class="llacingCont_heder flex-content" style="width: 300px">
                结算龙币
              </div>
            </div>
            <!-- 下单内容 -->
            <div
              class="flex-aling llacingCont_tr"
              v-for="(item, key2) in runningWater.userSkillStreamDetailVoList"
              :key="key2"
              :style="{ background: key2 % 2 ? '#262946' : '' }"
            >
              <div
                class="llacingCont_td flex-content"
                style="width: 300px; color: #777b99"
              >
                {{ item.orderTime }}
              </div>
              <div
                class="llacingCont_td flex-content"
                style="width: 210px; color: #777b99"
              >
                {{ item.revenueType }}
              </div>
              <div
                class="llacingCont_td flex-content"
                style="width: 300px; color: #777b99"
              >
                +{{ item.revenueGolds }}
              </div>
            </div>
          </div>
          <!-- 表格end -->
          <div style="text-align: right; margin-top: 20px">
            <el-pagination background layout="prev, pager, next" :total="10">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 活动说明弹窗 -->
    <div class="popUp_box box1" v-if="activityExplainShow">
      <div class="pop_up" style="width: 850px; height: 450px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">活动说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="activityExplainShow = false"
          />
        </div>
        <!-- 头部end -->
        <div
          class="font-12"
          style="padding: 20px; color: #616683"
          v-html="systemSet.discountExplain"
        ></div>
      </div>
    </div>
    <!-- 技能编辑弹窗 -->
    <div class="popUp_box box1" v-if="skillEditShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑技能</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="skillEditShow = false"
          />
        </div>
        <!-- 头部end -->
        <!-- 内容 -->
        <div style="padding: 0 20px" class="skillPopUp">
          <div class="font-16" style="margin-top: 20px">技能认证</div>
          <div class="attestation_text">
            <div style="color: #333">认证要求</div>
            <div>
              技能资格说明
              此图认证要求如下：1、电脑清晰截图、不能手机拍摄；2、游戏昵称与分数清晰；3、如需考核应上此号进行考核
            </div>
          </div>
          <div class="font-16 flex-aling">
            <div>认证资料图</div>
            <div class="font-12 padding_r4" style="color: #6a6a94">
              (禁止上传盗图，一经发现封号处理)
            </div>
            <div class="font-12" style="margin-left: 66px">
              具体截图页面可参考下方示例图片
            </div>
          </div>
          <div class="flex">
            <el-image
              :src="this.skillDetails.certificationImg"
              alt=""
              class="img_uploading"
              v-if="this.skillDetails.certificationImg"
            >
            </el-image>
            <!-- <img :src="this.skillDetails.certificationImg" alt="" class="img_uploading" v-if="this.skillDetails.certificationImg"> -->
            <el-upload
              action="http://112.74.161.140/tjyy-app/api/v1/common/fileUploadAli"
              list-type="picture-card"
              class="upload_certification"
              :limit="1"
              :show-file-list="false"
              :on-success="certificationSuccess"
              v-else
            >
              <img src="../../static/166.png" alt="" class="img_uploading" />
            </el-upload>
            <!-- <img src="../../static/lol.png" alt="" class="img_uploading" /> -->
            <el-image
              :src="require('../../static/lol.png')"
              class="img_uploading"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
          <div class="font-16 flex-aling">
            <div>语音介绍</div>
            <div class="font-12 padding_r4" style="color: #6a6a94">
              录制一段自我介绍，5-15s即可
            </div>
          </div>
          <div class="flex-aling" style="margin-top: 10px">
            <el-upload
              class="upload_soundRecording"
              action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="soundRecordingSuccess"
              :before-upload="beforeAvatarUpload"
              accept="video/*,audio/*"
            >
              <div class="file_uploading flex-content">
                <img
                  src="../../static/167.png"
                  alt=""
                  style="margin-right: 10px"
                />
                选择文件
              </div>
            </el-upload>
            <div class="font-12" style="color: rgba(255, 42, 62, 1)">
              支持MP3格式
            </div>
          </div>
          <div class="flex-aling" style="margin: 16px 0 20px 0">
            <div class="audio_file flex-aling" v-if="skillsForm.voiceFile">
              <div class="flex">
                <img src="../../static/125.png" alt="" />
                <div>{{ this.skillsForm.voiceName }}</div>
              </div>
              <div style="color: #8e92b1">
                <!-- 1MB -->
                <img
                  src="../../static/127.png"
                  alt=""
                  @click="delVoiceChange"
                />
              </div>
            </div>
            <!-- 文件信息end -->
            <!-- <div class="font-12" style="color: #cf8234; margin-left: 12px">
            校验中…
          </div>
          <img
            src="../../static/126.png"
            alt=""
            style="margin-left: 7px"
            class="spin_longin"
          /> -->
          </div>
          <div>接单信息</div>
          <div class="form_box">
            <el-form ref="form" :model="skillsForm" label-width="90px">
              <el-form-item label="技能等级：" class="">
                <el-select
                  v-model="skillDetails.levelId"
                  placeholder="请选择技能等级"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in gradeList"
                    :key="index"
                    :label="item.levelText"
                    :value="item.labelId"
                  >
                  </el-option>
                </el-select>
                <!-- <span style="color:#FF2A3E;margin-left:40px">请填写真实姓名</span> -->
              </el-form-item>
              <el-form-item label="接单单价：" class="color_fff">
                <el-select
                  v-model="skillDetails.levelText"
                  placeholder="请选择接单单价"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in unitPriceList"
                    :key="index"
                    :label="item.priceText"
                    :value="item.priceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接单时间：" class="color_fff">
                <el-input
                  v-model="skillDetails.ordersTime"
                  placeholder="请输入接单时间"
                ></el-input>
              </el-form-item>
              <el-form-item label="接单大区：" class="color_fff">
                <el-input
                  v-model="skillDetails.ordersDistrict"
                  placeholder="请输入接单区服"
                ></el-input>
              </el-form-item>

              <el-form-item label="擅长位置：" class="color_fff">
                <el-input
                  v-model="skillDetails.goodLocation"
                  placeholder="请输入擅长位置"
                ></el-input>
              </el-form-item>
              <el-form-item label="接单说明：" class="color_fff">
                <el-input
                  type="textarea"
                  v-model="skillDetails.ordersRemark"
                  placeholder="请输入您的接单宣言"
                  resize="none"
                  maxlength="35"
                  class="user_textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="form_btn flex-content" @click="userSkills()">
                  提交审核
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 内容end -->
      </div>
    </div>
    <!-- 微信扫码充值弹窗 -->
    <div class="topUPpopup_box" v-if="payPopupShow">
      <div class="pop_up" style="height: 500px; width: 570px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">扫码支付</div>
          <img src="../../static/quit.png" alt="" @click="closeWxPopUp()" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <img :src="wxImg" alt="" style="margin-top: 48px" />
          <p style="margin-top: 28px">请使用微信扫一扫</p>
        </div>
      </div>
    </div>
    <!-- 支付宝充值弹窗 -->
    <div class="topUPpopup_box" v-if="alipayPopupShow">
      <div class="pop_up" style="height: 500px; width: 570px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">扫码支付</div>
          <img src="../../static/quit.png" alt="" @click="closeWxPopUp()" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir" style="padding-bottom: 24px">
          <!-- <img :src="wxImg" alt="" style="margin-top: 48px;" /> -->
          <iframe
            name="iframeMap"
            id="iframeMapViewComponent"
            v-bind:src="AlipayValue"
            width="350px"
            height="350px"
            frameborder="0"
            scrolling="no"
            ref="iframeDom"
          ></iframe>
          <p style="margin-top: 28px">请使用支付宝扫一扫</p>
        </div>
      </div>
    </div>
    <!-- 公会签约说明 -->
    <div class="popUp_box box1" v-if="signedTextShow">
      <div class="pop_up" style="width: 850px; height: 450px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">公会签约说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="signedTextShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="font-12 textValue" v-html="signedTNun"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      dealType: false, //是否阅读公会签约说明
      signedTextShow: false, //公会签约说明
      signedTNun: "",
      srcList: [require("../../static/lol.png")],
      skillValue: 0,
      skillEditShow: false, //技能编辑弹窗
      activityExplainShow: false, //活动说明弹窗
      quitWarnShow: false, //退会提醒弹窗
      joinGuildsShow: false, //加入公会
      myGuildShow: false, //我的公会
      myGuildShowIng: false, //审核中
      myGuildType: 1,
      userInfo: {}, //用户信息
      myAssociation: {}, //我的公会信息
      runningAccountShow: false, //流水弹窗
      tabell_date: "", //时间筛选
      certificationList: [], //个人资质
      pitchSkill: {}, //选中技能
      runningWater: {}, //技能流水数据
      dateTime: "",
      skillsForm: {
        price: "", //陪玩价格
        priceId: "", //陪玩价格Id
        id: "", //id
        userId: "", //用户Id
        labelId: "", //标签id
        levelId: "", //	标签所属游戏段位Id
        levelName: "", //段位名称
        districtId: "", //标签所属游戏区服id
        certificationImg: "", //标签所属游戏技能认证截图
        voiceFile: "", //语音介绍文件
        voiceLen: "", //语音时长（单位：秒）
        ordersTime: "", //接单时间
        ordersDistrict: "", //接单大区
        goodLocation: "", //擅长位置
        ordersRemark: "", //接单说明
      },
      systemSet: {}, //活动设置和说明和消息设置
      audio: "", // 创建音频对象
      associationData: {}, //入驻公会信息
      manageAssociation: {}, //公会管理
      associationList: [], //推荐公会列表
      inviteCodeVal: "", //搜索公会的邀请码
      quitData: {}, //退会数据
      skillDetails: {}, //技能详情
      gradeList: [], //技能等级列表
      quitCost: {}, //强制退会费用
      payType: 1, //支付方式 1微信 2支付宝
      orderNo: "", //支付单号
      wxImg: "", //微信img
      payPopupShow: false, //微信支付
      alipayPopupShow: false, //支付宝支付
      AlipayValue: "", //支付宝二维码
      alipayOrder: "", //支付宝订单号
      unitPriceList: [], //接单单价
      twoSkill: {}, //技能
      courentTime: "",
    };
  },
  mounted() {
    this.getApplyInfo()
    this.getInfo();
    this.selectUserSkill();
    this.getDiscountInfo();
    this.audio = new Audio();
  },
  methods: {
    // 技能认证
    userSkills() {
      // return console.log(this.twoSkill);
      // this.skillsForm.labelId = this.twoSkill.id;
      if (!this.skillDetails.certificationImg)
        return this.$message.info("请上传认证图片");
      if (!this.skillDetails.voiceFile)
        return this.$message.info("请上传语音认证文件");
      // if(!this.whetherRead) return this.$message.info('请阅读认证协议')
      this.$api.editUserSkill(this.skillDetails).then((res) => {
        if (res.code == 1) {
          this.skillEditShow = false;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 上传录音文件
    soundRecordingSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.skillsForm.voiceFile = response.data.url;
        this.skillsForm.voiceName = file.name;
      }
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        let playTime = audioElement.duration;
        this.skillsForm.voiceLen = parseInt(playTime);
      });
    },
    // 接单等级数据
    getOptions() {
      // this.skillsForm.price = "";
      // this.skillsForm.priceId = "";
      // this.skillsForm.levelId = "";
      // this.skillsForm.levelName = "";
      this.$api.getOptions({ labelId: this.twoSkill.labelId }).then((res) => {
        if (res.code == 1) {
          this.gradeList = res.data.levelVoList;
          this.unitPriceList = res.data.priceInfoVoList;
          // this.serverList = res.data.districtVoList;
        }
      });
    },
    // 获取公会签约说明
    signedChange() {
      this.$api.getAgreement({ configName: "guildCsp" }).then((res) => {
        if (res.code == 1) {
          this.signedTNun = res.data;
          this.signedTextShow = true;
        }
      });
    },
    // 撤销入会申请
    withdrawById() {
      this.$api.withdrawById({ id: this.associationData.id }).then((res) => {
        if (res.code == 1) {
          this.getApplyInfo();
          this.$message.info(res.msg);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 撤销退会申请
    revocation() {
      this.$api.cancalQuit().then((res) => {
        if (res.code == 1) {
          this.getApplyInfo();
          this.$message.info(res.msg);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    ischeckBtn() {
      if (this.associationData.applyStatus == 0) {
        this.myGuildShowIng = true;
      }
      if (this.associationData.applyStatus == 1) {
        this.$router.push({
          path: "/manito",
        });
      }
    },
    // 修改系统派单
    orderTypeChange(type) {
      this.$api.saveUserInfo({ sysOrderMsg: type }).then((res) => {
        if (res.code == 1) {
          // this.getDiscountInfo()
          this.$message.info(res.msg);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 关闭弹窗
    closeWxPopUp() {
      this.payPopupShow = false;
      this.alipayPopupShow = false;
      window.clearTimeout(this.stopTime);
    },
    // 强制退会支付
    rechargeChange() {
      //   1微信 2支付宝
      let data = {
        payType: "", //支付类型（0-微信，1-支付宝 2-微信扫码 3-支付宝扫码）,示例值(0)
        handFee: this.quitCost.handFee,
        quitFee: this.quitCost.quitFee,
      };
      if (this.payType == 2) {
        data.payType = 3;
        // return this.$message.info('暂未开通，请使用微信支付')
        this.$api.setForceQuit(data).then((res) => {
          if (res.code == 1) {
            this.AlipayValue = res.data.aliPayCode;
            this.alipayOrder = res.data.orderNo;
            this.alipayPopupShow = true;
            this.getAliPayPayment();
          } else {
            this.$message.info(res.msg);
          }
        });
      } else {
        data.payType = 2;
        this.$api.setForceQuit(data).then((res) => {
          if (res.code == 1) {
            this.wxImg = "data:image/png;base64," + res.data.wxPayCode;
            this.orderNo = res.data.orderNo;
            this.payPopupShow = true;
            this.getWeChatPayResult();
          } else {
            this.$message.info(res.msg);
          }
        });
      }
    },
    // 获取微信支付结果
    getWeChatPayResult() {
      this.$api.guildQueryById({ id: this.orderNo }).then((res) => {
        if (res.code == 1) {
          if (res.data.payStatus == 1) {
            this.payPopupShow = false;
            this.$message.success("支付成功");
            this.getApplyInfo();
            this.myGuildChange();
          } else {
            this.stopTime = setTimeout(() => {
              this.getWeChatPayResult();
            }, 3000);
          }
        }
      });
    },
    // 获取支付宝支付结果
    getAliPayPayment() {
      this.$api.aliPayResult({ outTradeNo: this.alipayOrder }).then((res) => {
        if (res.code == 1) {
          if (res.data == 2) {
            this.alipayPopupShow = false;
            this.$message.success("支付成功");
            this.getApplyInfo();
            this.myGuildChange();
          } else {
            this.stopTime = setTimeout(() => {
              this.getAliPayPayment();
            }, 3000);
          }
        }
      });
    },
    // 单个技能状态切换
    skillStateChange(i) {
      let obj = {
        labelId: i.labelId,
        type: i.onOffState,
        userId: i.userId,
      };
      this.$api.editOneOnOff(obj);
    },
    // 技能状态批量管理
    allSkillStateChange() {
      this.$api
        .editOnOffState({ userId: this.userInfo.id, type: this.skillValue })
        .then((res) => {
          if (res.code == 1) {
            this.selectUserSkill();
          }
        });
    },
    // 点击复制
    copyUserId(id) {
      var input = document.createElement("input"); // 创建input对象
      input.value = id; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    // 添加新资质，跳转到认证大神页面
    addNewAptitude() {
      if (
        this.userInfo.isCertification == 1 &&
        this.userInfo.userGuildstatus == 1
      ) {
        this.$router.push({
          path: "/manito",
          query: { type: 1 },
        });
      } else if (this.userInfo.isCertification == "0") {
        this.$message.info("请先实名认证并加入公会后添加");
      } else if (
        this.userInfo.isCertification == 1 &&
        this.userInfo.userGuildstatus == "0"
      ) {
        this.$message.info("请先加入公会");
      }
    },
    // 获取技能等级
    labelLevelInfoList(id) {
      this.$api.labelLevelInfoList({ labelId: id }).then((res) => {
        this.gradeList = res.data;
      });
    },
    // 获取大神技能信息
    getPlayerSkillInfo(i) {
      this.$api
        .playerSkillInfo({ skillId: i.id, targetUserId: i.userId })
        .then((res) => {
          if (res.code == 1) {
            this.skillEditShow = true;
            this.skillDetails = res.data;
            this.twoSkill = i;
            this.getOptions();
            // this.labelLevelInfoList(i.labelId);
          }
        });
    },
    // 点击强制退出
    forceQuitChange() {
      this.myGuildType = 3;
      this.$api.getForceQuitFee().then((res) => {
        if (res.code == 1) {
          this.quitCost = res.data;
        }
      });
    },
    // 退出弹窗显示
    quitWarnShowChange() {
      this.quitWarnShow = true;
      // if (
      //   (this.quitData && this.quitData.isFullFortyFiveDays == 1) ||
      //   this.quitData.isNormalQuit == 1
      // ) {
      //   this.quitWarnShow = true;
      // } else {
      //   this.$message.info("入会不满45天，无法申请");
      // }
    },
    // 退会
    quitAssociation() {
      if (this.quitData && this.quitData.isNormalQuit == 1) {
        //无条件退会
        this.$api.setNoConditionQuit().then((res) => {
          if (res.code == 1) {
            this.getApplyInfo();
            this.quitWarnShow = false;
            this.myGuildType = 1;
            this.myGuildShow = false;
          }
        });
      } else {
        //友好退会
        this.$api.setNicelyQuit().then((res) => {
          if (res.code == 1) {
            this.quitWarnShow = false;
            this.myGuildType = 1;
            this.myGuildShow = false;
          }
        });
      }
    },
    // 申请退会
    setApplyFor() {
      this.$api.getQuitOptionAndExplain().then((res) => {
        if (res.code == 1) {
          this.quitData = res.data;
        }
      });
      this.myGuildType = 2;
    },
    // 申请加入
    applyToJoin(i) {
      if (!this.dealType) return this.$message.info("请阅读公会签约说明");
      this.$api.guildUserApplyAdd({ guildId: i.id }).then((res) => {
        if (res.code == 1) {
          this.getApplyInfo();
          this.joinGuildsShow = false;
          this.$message.success(res.msg);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 查询推荐公会信息
    getQueryRecommendInfo() {
      this.$api.queryRecommendInfo().then((res) => {
        if (res.code == 1) {
          this.associationList = res.data.rows;
        }
      });
    },
    // 根据邀请码查询
    getQueryByInviteCode() {
      if (!this.inviteCodeVal) {
        this.getQueryRecommendInfo();
      } else {
        this.$api
          .queryByInviteCode({ inviteCode: this.inviteCodeVal })
          .then((res) => {
            // console.log(res);
            if (res.code == 1) {
              this.associationList = [];
              this.associationList = res.data;
            }
          });
      }
    },
    // 关闭公会弹窗
    myGuildChange() {
      this.myGuildType = 1;
      this.myGuildShow = false;
    },
    activityOpening(type) {
      if (type == 1) {
        // this.systemSet.onePriceParticipate
        this.$api
          .discountSet({
            discountType: this.systemSet.onePriceParticipate == 1 ? 0 : 11,
          })
          .then((res) => {
            if (res.code == 1) {
              this.getDiscountInfo();
            }
          });
      } else {
        // this.systemSet.buyXyParticipate
        this.$api
          .discountSet({
            discountType: this.systemSet.buyXyParticipate == 1 ? 1 : 12,
          })
          .then((res) => {
            if (res.code == 1) {
              this.getDiscountInfo();
            }
          });
      }
    },
    // 播放音频
    playMusic(i) {
      this.audio.src = i.voiceFile;
      this.audio.loop = true;
      this.audio.play(); // 播放
    },
    // 获取活动设置和说明和消息设置
    getDiscountInfo() {
      this.$api.getDiscountInfo().then((res) => {
        if (res.code == 1) {
          res.data.buyXy = res.data.buyXy.split(",");
          this.systemSet = res.data;
        }
      });
    },
    // 流水时间选择
    dateScreen() {
      this.getSkillStreamInfo();
    },
    // 查看个人资质
    selectUserSkill() {
      this.$api.selectUserSkill().then((res) => {
        if (res.code == 1) {
          this.certificationList = res.data;
          console.log(res.data,'dada')
      
          this.certificationList.forEach((item) => {
            if (item.onOffState == "1") {
              return (this.skillValue = 1);
            } else {
              return (this.skillValue = 0);
            }
          });
        }
      });
    },
    //获取技能流水
    runningAccountChange(i) {
      this.runningAccountShow = true;
      this.pitchSkill = i;
      let myDate = new Date();
      let myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      this.dateTime = myYear + "-" + myMonth;
      this.getSkillStreamInfo();
    },
    getSkillStreamInfo() {
      this.$api
        .getSkillStreamInfo({
          userSkillId: this.pitchSkill.id,
          yearMonth: this.tabell_date ? this.tabell_date : this.dateTime,
        })
        .then((res) => {
          if (res.code == 1) {
            this.runningWater = res.data;
          }
        });
    },
    // 公会列表
    guildUserList() {
      if (this.userInfo.isCertification == 1) {
        this.getQueryRecommendInfo();
        this.joinGuildsShow = true;
      } else {
        this.$message.info("请先实名认证~");
      }
    },
    getInfo() {
      this.$api.queryUser({ otherUserId: this.myId }).then((res) => {
        // console.log("总个人信息", res);
        if (res.code == 1) {
          this.userInfo = res.data;
          this.getApplyInfo();
        }
      });
    },
    // 用户申请入会记录
    getApplyInfo() {
      this.$api.getApplyInfo().then((res) => {
        if (res.code == 1) {
          this.associationData = res.data;
        }
      });
    },
    // 公会管理
    getQueryByUserId() {
      this.$api.queryByUserId({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.manageAssociation = res.data[0];
          this.myGuildShow = true;
        }
      });
    },
    // 通过userid查询我入驻的公会信息
    queryGuildByUserId() {
      // console.log("id", this.userInfo.id);
      this.$api.queryGuildByUserId({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.myAssociation = res.data;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.textValue {
  padding: 20px;
  height: 350px;
  overflow-y: scroll;
}
.dealText {
  margin: 12px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  > img {
    margin-right: 6px;
  }
}
.scroll_box {
  height: 320px;
  margin-bottom: 24px;
  overflow-y: scroll;
}
.topUPpopup_box {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -13%;
  left: -30%;
  background: rgba($color: #161722, $alpha: 0.7);
  z-index: 99;
  color: #fff;
  .pop_up {
    background: #1d223b;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
}
.skillPopUp {
  width: 850px;
  height: 700px;
  overflow-y: scroll;
  .attestation_text {
    width: 820px;
    height: 60px;
    padding: 10px;
    color: #6a6a94;
    margin: 13px 0 20px 0;
    background: #f5f5f5;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .img_uploading {
    width: 268px;
    height: 151px;
    margin: 10px 18px 20px 0;
  }
  .file_uploading {
    width: 114px;
    height: 36px;
    color: #333;
    margin-right: 10px;
    background: #f5f5f5;
    border-radius: 4px;
  }
  .form_box {
    width: 400px;
    margin-top: 20px;
  }
  .form_btn {
    width: 320px;
    height: 40px;
    color: #333;
    font-size: 14px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}

.activity_icon {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  background-color: #f99439;
  border-radius: 8px;
}
.llacingCont_heder {
  color: #000;
  padding-left: 16px;
  justify-content: flex-start;
}
.llacingCont_tr {
  .llacingCont_td {
    height: 40px;
    padding-left: 16px;
    justify-content: flex-start;
  }
}
.runnung_date /deep/.el-input__inner {
  color: #d3d4dc;
  width: 149px;
  height: 30px;
  font-size: 12px;
  padding: 10px;
  background: #262b43;
}
.runnung_date /deep/.el-date-editor.el-input {
  width: 150px;
}
.runnung_date /deep/.el-input__icon {
  position: absolute;
  top: -5px;
  left: 100px;
}
// 流水弹窗
.runningAccount_box {
  padding: 20px;
  .runnung_item {
    width: 246px;
    height: 92px;
    color: #fff;
    padding: 20px;
    margin-right: 10px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
.myGuild_item {
  width: 400px;
  height: 40px;
  color: #666;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  background: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
  .myGuild_left {
    color: #8e92b1;
  }
}
.myGuild_hint {
  color: #8e92b1;
  font-size: 12px;
  margin: 20px 0;
}
.myGuild_btn {
  width: 400px;
  height: 40px;

  background: linear-gradient(135deg, #ff2a3e 0%, #ff606f 100%);
  border-radius: 4px;
}
.myGuild_text {
  padding: 20px;
  background: #262b43;
  border-radius: 4px;
}
.joinGuilds_box {
  width: 490px;
  padding: 40px 60px;
  box-sizing: border-box;
  .joinGuilds_btn {
    width: 102px;
    height: 40px;
    color: #333;
    font-size: 14px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
  .joinGuilds_name {
    color: #8e92b1;
    margin: 13px 0 15px 0;
  }
  .joinGuilds_head {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .joinGuilds_item {
    margin-top: 19px;
    align-items: center;
  }
}
.scrollBox {
  width: 400px;
  height: 360px;
  overflow-y: scroll;
}
//弹窗
.popUp_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  transform: translate(-19%, -10%);
  // background: #161722;
  background: rgba($color: #161722, $alpha: 0.7);
  z-index: 99;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
}
.chatOfTime {
  width: 50px;
  height: 24px;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.71) 100%
  );
  border-radius: 12px 4px 6px 12px;
  position: absolute;
  left: 5%;
  transform: translateY(-90%);
  display: flex;
  align-items: center;
  margin-right: 10px;
  > p {
    font-size: 16px;
    color: #212121;
    font-weight: bold;
  }

  .chatOfTimeImg {
    width: 16px;
    height: 16px;
    margin: 0 4px;
  }
}
.playPage {
  color: #000;
  font-size: 14px;
}
.play_title {
  margin: 16px 0;
}
.play_title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 10px;
  margin-right: 4px;
  background: linear-gradient(171deg, #ffa700 0%, #ffdd00 100%);
  border-radius: 0px 2px 2px 0px;
}
.title-box {
  display: flex;
  justify-content: space-between;
}
.certification_b {
  width: 86px;
  height: 22px;
  color: #fff;
  background: #4DC777;
  border-radius: 11px;
}
.certification_type {
  width: 300px;
  height: 130px;
  color: #fff;
  padding: 10px;
  background: url(../../static/110.png) no-repeat;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 8px;

  .certification_img {
    width: 64px;
    height: 64px;
    margin-right: 12px;
  }
  .certification_rightIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.frameBar {
  width: 100%;
  height: 1px;
  background: #363659;
  margin: 10px 0;
}
.water_icon {
  width: 3px;
  height: 6px;
  margin: 0 4px;
}
//我的公会
.society_box {
  width: 950px;
  height: 84px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  .society_head {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .society_manage {
    padding: 0 10px;
    color: #333;
    background: #f5f5f5;
    border-radius: 14px;
    width: 88px;
    height: 36px;
  }
  .society_manage1 {
    padding: 0 10px;
    color: #333;
    background: rgba(255, 221, 0, 1);
    border-radius: 14px;
    width: 88px;
    height: 36px;
  }
}
.contentJon {
  width: 256px;
  height: 44px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  text-align: center;
}
//活动设置
.activity_box {
  width: 462px;
  height: 71px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
}
.upload_soundRecording /deep/.el-upload--picture-card {
  width: 150px;
  height: 30px;
  background-color: transparent;
  border: 0;
}
.upload_certification /deep/.el-upload--picture-card {
  width: 300px;
  background-color: transparent;
  border: 0;
}
/deep/.el-form-item__label {
  color: #333;
}
/deep/.el-input__inner {
  color: #333;
  background-color: #f5f5f5 !important;
  border: 0;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #7e53f0;
  background: #7e53f0;
}
/deep/.el-textarea__inner {
  border: 0;
  color: #333;
  background-color: #f5f5f5;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
/deep/.el-input__inner::placeholder {
  color: #6a6a94 !important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(255, 167, 0, 0.2);

  color: #ffa700;
}
/deep/ .el-pagination.is-background .btn-prev:disabled,
/deep/.el-pagination.is-background .btn-next:disabled {
  background-color: #f5f5f5;
}
/deep/ .el-select-dropdown__item {
  color: #000 !important;
}
</style>