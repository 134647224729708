<template>
  <!-- 个人中心 -- 我的信息 -->
  <div class="userPage">
    <!-- 我的信息stert -->
    <div class="user_box">
      <div class="user_top">
        <div class="flex">
          <!-- 上传头像 -->
          <div class="uploadHead_box">
            <div
              :class="
                userInfo.avatarStatus == 0 || userInfo.avatarStatus == 2
                  ? 'uploadHead_bg'
                  : ''
              "
            >
              <div style="color: #fff" v-if="userInfo.avatarStatus == 0">
                审核中
              </div>
              <div
                style="color: #fff"
                v-if="userInfo.avatarStatus == 2"
                @click="$message.error(userInfo.avatarReason)"
              >
                审核失败
              </div>
            </div>
            <img
              :src="
                userInfo.avatarExamineImage
                  ? userInfo.avatarExamineImage
                  : userInfo.avatar
              "
              alt=""
              class="uploadHead_img"
              v-if="userInfo.avatar"
            />
            <img
              v-else
              src="../../../src/image/room/89.png"
              alt=""
              class="uploadHead_icon"
              for="uploads"
            />
            <!-- @click="headChange()" -->
            <label class="uploadHead_icon" for="uploads"></label>

            <input
              type="file"
              id="uploads"
              style="position: absolute; clip: rect(0 0 0 0)"
              accept="image/png, image/jpeg, image/gif, image/jpg"
              @change="selectImg($event)"
            />
          </div>
          <!-- 上传头像end -->
          <div class="flex-between">
            <div class="font-16">{{ userInfo.nickname }}</div>
            <div class="flex">
              <div class="icon_box flex-content">
                <img
                  src="../../static/195.png"
                  alt=""
                  class="width_16"
                  style="margin-right: 4px"
                />
                <div>{{ userInfo.diamondCost }}</div>
              </div>
              <div
                class="topup_btn flex-content"
                @click="walletTopupShow = true"
              >
                充值<img src="../../static/62.png" alt="" class="padding_r4" />
              </div>
              <div class="icon_box flex-content">
                <img
                  src="../../static/67.png"
                  alt=""
                  class="width_16"
                  style="margin-right: 4px"
                />
                <div>{{ userInfo.totalCharisma || 0 }}</div>
              </div>
              <div class="icon_box flex-content">
                <img
                  src="../../static/196.png"
                  alt=""
                  class="width_16"
                  style="margin-right: 4px"
                />
                <div>{{ userInfo.goldNum || 0 }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="signInBox flex-content" @click="signInChange()">
            每日签到
          </div>
        </div>
      </div>
      <div class="flex" style="background: #f7f7f9">
        <!-- 基本资料 -->
        <div class="user_left">
          <div class="user_title">基本资料</div>
          <div class="userData_box">
            <div class="edit_btn flex-content" @click="editShow = true">
              <img src="../../static/115.png" alt="" />
              编辑
            </div>
            <div class="flex">
              <div class="headline">昵称</div>
              <div>{{ userInfo.nickname }}</div>
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">性别</div>
              <div v-if="userInfo.sex == 0">男</div>
              <div v-if="userInfo.sex == 1">女</div>
              <div v-if="userInfo.sex == 9">保密</div>
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">生日</div>
              <div>{{ userInfo.birthday }}</div>
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">所在城市</div>
              <div>{{ userInfo.city }}</div>
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">签名</div>
              <div>{{ userInfo.personalSignature }}</div>
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">标签</div>
              <div
                class="my_label"
                v-for="(item, index) in userInfo.tagNames"
                :key="index"
              >
                {{ item }}
              </div>
              <img
                src="../../static/115.png"
                alt=""
                @click="labelCretan(userInfo.tagNames, personList, 1)"
              />
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">喜欢的内容</div>
              <template v-for="(item, index) in userInfo.interestLabelName">
                <div class="my_label" :key="index" v-if="index < 3">
                  {{ item }}
                </div>
              </template>
              <img
                src="../../static/68.png"
                alt=""
                style="margin: 0 8px"
                v-if="
                  userInfo.interestLabelName &&
                  userInfo.interestLabelName.length > 3
                "
                @click="
                  labelCretan(userInfo.interestLabelName, interestList, 2)
                "
              />
              <img
                src="../../static/115.png"
                alt=""
                @click="
                  labelCretan(userInfo.interestLabelName, interestList, 2)
                "
              />
            </div>
            <div class="flex" style="margin-top: 21px">
              <div class="headline">喜欢的声音</div>
              <div v-for="(item, index) in userInfo.likeVoiceName" :key="index">
                <div class="my_label" :key="index" v-if="index < 3">
                  {{ item }}
                </div>
              </div>
              <img
                src="../../static/68.png"
                alt=""
                style="margin: 0 8px"
                v-if="
                  userInfo.likeVoiceName && userInfo.likeVoiceName.length > 3
                "
                @click="labelCretan(userInfo.likeVoiceName, voiceList, 3)"
              />
              <img
                src="../../static/115.png"
                alt=""
                @click="labelCretan(userInfo.likeVoiceName, voiceList, 3)"
              />
            </div>
          </div>
          <div class="user_title">账号安全</div>

          <div class="long_box">
            <div class="flex">
              <img src="../../static/73.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">绑定手机号</div>
                <div class="font-12 padding_4">
                  已绑定手机号：{{ userInfo.mobile }}
                </div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="(PhoneShow = true), getRandomImage()"
            >
              修改
            </div>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/69.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">实名认证</div>
                <div
                  class="font-12 padding_4"
                  v-if="
                    userInfo.isCertification == 0 &&
                    isAttestData.auditResults == 0
                  "
                >
                  未实名
                </div>
                <div
                  class="font-12 padding_4"
                  style="color: red"
                  v-else-if="
                    userInfo.isCertification == 1 &&
                    isAttestData.auditResults == 1
                  "
                  @click="realNameShow = true"
                >
                  重新认证
                </div>
                <div
                  class="font-12 padding_4"
                  v-else-if="
                    userInfo.isCertification == 1 &&
                    isAttestData.auditResults == 2
                  "
                >
                  已实名
                </div>
              </div>
            </div>

            <div
              class="font-12"
              style="color: #25c78a"
              @click="goApplyJoin"
              v-if="userInfo.isCertification == 0"
            >
              去认证
            </div>
            <div
              class="font-12"
              style="color: #a64ff0"
              v-if="
                userInfo.isCertification == 1 && isAttestData.auditResults == 0
              "
            >
              审核中
            </div>
            <div
              class="font-12 padding_4"
              style="color: red"
              v-if="
                userInfo.isCertification == 1 && isAttestData.auditResults == 1
              "
              @click="$message.error(certificationData.reason)"
            >
              审核失败
            </div>
            <div
              class="font-12"
              style="color: #25c78a"
              v-if="
                userInfo.isCertification == 1 && isAttestData.auditResults == 2
              "
            >
              已认证
            </div>
          </div>

          <div class="user_title">更多认证</div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/70.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">声音属性</div>
                <div class="font-12 padding_4">未认证，你的声音有人倾听</div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #25c78a"
              @click="soundPropertiesChange()"
              v-if="userInfo.isSubmitVoice != 1"
            >
              去认证
            </div>
            <div
              class="font-12"
              style="color: #25c78a"
              v-if="
                userInfo.isSubmitVoice == 1 && userInfo.voiceAuditStatus == 0
              "
            >
              审核中
            </div>
            <div
              class="font-12"
              style="color: #25c78a"
              v-if="
                userInfo.isSubmitVoice == 1 && userInfo.voiceAuditStatus == 1
              "
            >
              已认证
            </div>
            <div
              class="font-12"
              style="color: #ff2a3e"
              v-if="
                userInfo.isSubmitVoice == 1 && userInfo.voiceAuditStatus == 2
              "
              @click="soundPropertiesChange()"
            >
              <p>审核失败</p>
              <p class="textOver">原因：{{ userInfo.voiceAuditReason }}</p>
            </div>
          </div>
          <div class="user_title">更多设置</div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/71.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">个性化推荐</div>
                <div class="font-12 padding_4">
                  关闭后无法看到个性化推荐内容
                </div>
              </div>
            </div>
            <el-switch
              v-model="userInfo.recommendSwitch"
              active-color="#9167fb"
              inactive-color="#cccccc"
              :active-value="1"
              :inactive-value="0"
              @change="pushSwitchChange"
            >
            </el-switch>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/72.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">黑名单</div>
                <div class="font-12 padding_4" style="color: #333">
                  被拉黑的用户管理
                </div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="blacklistChange()"
            >
              管理
            </div>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/197.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">广场屏蔽</div>
                <div class="font-12 padding_4">被屏蔽的用户管理</div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="guangchanglistChange()"
            >
              管理
            </div>
          </div>
        </div>
        <div class="user_right" style="background: #f7f7f9">
          <!-- 照片墙 -->
          <div
            class="user_title flex-content"
            style="justify-content: flex-start; position: relative"
          >
            <div>照片墙</div>
            <div
              class="font-14"
              style="color: #ff173b; position: absolute; right: 0"
            >
              <!-- 照片墙审核中... -->
            </div>
          </div>
          <div class="photoWall_box flex">
            <div
              v-for="(item, index) in userInfo.imgs"
              :key="index"
              class="img_box"
            >
              <div
                class="img_auditStatus flex-content"
                v-if="item.auditStatus != 1"
                @click="imgAuditChange(item)"
              >
                {{ item.auditStatus == 0 ? "审核中" : "审核不通过" }}
              </div>
              <img :src="item.img" alt="" class="photoWall_img" />
              <el-image
                :src="item.img"
                class="photoWall_img"
                :preview-src-list="imgLists"
              >
              </el-image>
              <img
                src="../../static/80.png"
                alt=""
                class="del_img"
                @click="delImg(item)"
              />
            </div>
            <div style="display: inline-block">
              <el-upload
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                :limit="9"
                :show-file-list="false"
                list-type="picture-card"
                :on-success="photoWallImgSuccess"
                v-if="userInfo.imgs && userInfo.imgs.length < 9"
                style="display: flex"
                accept=".png,.jpg.jpeg"
              >
                <img
                  src="../../static/185.png"
                  alt=""
                  class="uploadPhotoWall_img"
                />
              </el-upload>
            </div>
          </div>
          <div class="closeAn" @click="writeOffShow = true">注销账号</div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/78.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">登录密码</div>
                <div class="font-12 padding_4">定期更改密码，保护账户安全</div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="(passwordShow = true), getRandomImage()"
            >
              修改
            </div>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/74.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">微信绑定</div>
                <div
                  class="font-12 padding_4"
                  v-if="userInfo.isWechatBound == 1"
                >
                  已绑定
                </div>
                <div class="font-12 padding_4" v-else>暂未绑定</div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #ff2a3e"
              v-if="userInfo.isWechatBound == 1"
              @click="solutionRemindShow = true"
            >
              解绑
            </div>
          </div>

          <div class="empty"></div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/75.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">加入公会，成为大神</div>
                <div class="font-12 padding_4">
                  遇见更多小伙伴，还能龙币变现
                </div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #25c78a"
              @click.stop="manitoChange()"
              v-if="userInfo.isPlayer == 0"
            >
              去认证
            </div>
            <div class="font-12" style="color: #25c78a" v-else>已认证</div>
          </div>
          <div class="empty"></div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/76.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">消息免打扰</div>
                <div class="font-12 padding_4">
                  开启后非好友用户将无法发送向你发送消息
                </div>
              </div>
            </div>
            <el-switch
              v-model="userInfo.noDisturb"
              active-color="#9167fb"
              inactive-color="#cccccc"
              :active-value="1"
              :inactive-value="0"
              @change="noDisturbing"
            >
            </el-switch>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/77.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">房间设置</div>
                <div class="font-12 padding_4">
                  遇见更多小伙伴，还能龙币变现
                </div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="getRoomUserSet()"
            >
              设置
            </div>
          </div>
          <div class="long_box">
            <div class="flex">
              <img src="../../static/198.png" alt="" class="computer_icon" />
              <div>
                <div class="font-14">广场设置</div>
                <div class="font-12 padding_4">允许动态被查看的范围</div>
              </div>
            </div>
            <div
              class="font-12"
              style="color: #4177dd"
              @click="isguangShow = true"
            >
              管理
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的信息end -->
    <!-- 編輯资料弹窗 -->
    <div class="popUp_box" v-if="editShow">
      <div class="pop_up" style="height: 500px; width: 800px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑资料</div>
          <img src="../../static/quit.png" alt="" @click="editShow = false" />
        </div>
        <!-- 头部end -->
        <div class="edit_data">
          <el-form ref="form" :model="userInfo" label-width="80px">
            <el-form-item label="昵称:" class="color_fff">
              <el-input v-model="userInfo.nickname" maxlength="8"></el-input>
            </el-form-item>
            <el-form-item label="性别:">
              <el-radio-group v-model="userInfo.sex">
                <el-radio
                  :label="0"
                  :disabled="userInfo.isSetSex == 1 ? true : false"
                  >男</el-radio
                >
                <el-radio
                  :label="1"
                  :disabled="userInfo.isSetSex == 1 ? true : false"
                  >女</el-radio
                >
              </el-radio-group>
              <span style="color: #ff2a3e; margin-left: 40px"
                >仅允许修改一次</span
              >
            </el-form-item>
            <el-form-item label="生日:">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="userInfo.birthday"
                style="width: 100%"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="所在城市:">
              <div class="cascader_box">
                <div
                  class="selector_cascader"
                  @click="cascaderShow = !cascaderShow"
                >
                  {{ this.userInfo.city }}
                </div>
                <div class="cascaderItemBox flex-aling" v-if="cascaderShow">
                  <ul class="father_box">
                    <li
                      class="father_item"
                      v-for="(father, key0) in cityOptions"
                      :key="key0"
                      :style="{
                        background:
                          fatherkey == key0 ? 'rgba(255, 167, 0, 0.20)' : '',
                      }"
                      @click.stop="fatherChange(father, key0)"
                    >
                      {{ father.provinceName }}
                      <img
                        v-if="fatherkey == key0"
                        src="../../static/62.png"
                        alt=""
                        class="cascader_icon"
                      />
                    </li>
                  </ul>
                  <ul class="father_box" v-if="cityObj">
                    <li
                      class="father_item text-cut"
                      v-for="(cityItem, key1) in cityObj"
                      :key="key1"
                      :style="{
                        background:
                          fatherkey == key1 ? 'rgba(255, 167, 0, 0.20)' : '',
                      }"
                      @click.stop="cityChange(cityItem)"
                    >
                      {{ cityItem.cityName }}
                      <img
                        v-if="fatherkey == key1"
                        src="../../static/62.png"
                        alt=""
                        class="cascader_icon"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="签名:">
              <el-input
                type="textarea"
                v-model="userInfo.personalSignature"
                placeholder="请选择个人签名"
                resize="none"
                maxlength="25"
                class="user_textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="editData()" class="form_btn">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 广场允许查看 -->

    <div class="popUp_box" v-if="isguangShow">
      <div class="pop_up" style="height: 300px; width: 800px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">广场设置</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="isguangShow = false"
          />
        </div>
        <div
          style="
            color: #000;
            margin-top: 24px;
            margin-left: 20px;
            font-size: 14px;
          "
        >
          允许动态被查看的范围
        </div>
        <!-- 头部end -->
        <div style="margin-top: 24px; margin-left: 20px">
          <div
            class="guangContent"
            v-for="item in dayArr"
            :key="item.id"
            style="margin-bottom: 8px"
          >
            <img
              v-if="item.isflage"
              style="width: 14px; height: 14px; margin-right: 6px"
              src="../../static/other23.png"
              alt=""
            />
            <img
              @click="goBtnDay(item)"
              v-else
              style="width: 14px; height: 14px; margin-right: 6px"
              src="../../static/other22.png"
              alt=""
            />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <div style="width: 176px; margin: 0 auto">
          <div
            style="
              color: #000;
              width: 176px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: rgba(255, 221, 0, 1);
            "
            @click="updateForumArea"
          >
            确认
          </div>
        </div>
      </div>
    </div>

    <!-- 修改登录密码 -->
    <div class="popUp_box" v-if="passwordShow">
      <div class="pop_up" style="height: 500px; width: 550px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">修改登录密码</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="passwordShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="password_data">
          <el-form ref="form" :model="passwordForm" label-width="100px">
            <el-form-item label="当前手机号码:" style="color: #333">
              <div style="color: #333; background: #f5f5f5; padding-left: 15px">
                {{ userInfo.mobile }}
              </div>
            </el-form-item>
            <el-form-item label="图形验证码:">
              <!-- <el-input
                v-model="passwordForm.graphCode"
                style="width: 148px"
              ></el-input> -->
              <div class="flex-aling">
                <el-input
                  v-model="imgCodeValue"
                  maxlength="4"
                  style="width: 148px"
                  placeholder="图形验证码"
                ></el-input>
                <img
                  :src="codeImg"
                  v-if="codeImg"
                  @click="getRandomImage"
                  alt=""
                  style="margin-left: 16px"
                />
              </div>
            </el-form-item>
            <el-form-item label="验证码:">
              <div class="flex">
                <el-input
                  v-model="passwordForm.code"
                  style="width: 148px"
                  maxlength="6"
                  placeholder="填写验证码"
                ></el-input>
                <!-- <span style="margin-left: 28px">获取验证码</span> -->
                <!-- 获取验证码 -->
                <verificationCode
                  :event="'forget'"
                  :mobile="userInfo.mobile"
                ></verificationCode>
              </div>
            </el-form-item>
            <el-form-item label="设置密码:">
              <el-input
                v-model="passwordForm.newPwd"
                show-password
                maxlength="18"
                placeholder="请填写密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码:">
              <el-input
                v-model="passwordForm.confirmNewPwd"
                show-password
                maxlength="18"
                placeholder="请确认密码"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              @click="changePassword()"
              class="form_btn"
              style="width: 382px; margin-top: 41px"
              >提交</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
    <!-- 实名认证弹窗 -->
    <div class="popUp_box" v-if="0">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">实名认证</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="realNameShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="font-14" style="margin: 20px 59px 40px 59px">
          认证信息将用于大神入驻、收益提现等，与账号唯一绑定。实名制认证信息校验通过后不可修改，请填写帐号本人信息。
        </div>
        <div class="realName_data">
          <el-form ref="form" :model="certification" label-width="120px">
            <el-form-item label="真实姓名:">
              <el-input
                placeholder="请输入您的真实姓名"
                maxlength="10"
                v-model="certification.userName"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号码:">
              <el-input
                placeholder="请输入您的身份证号码"
                maxlength="18"
                v-model="certification.idCard"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号:">
              <el-input
                placeholder="请输入您的银行卡号"
                maxlength="19"
                v-model="certification.bankCardNumber"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行名称:">
              <el-input
                placeholder="请输入银行卡开户行名称"
                maxlength="10"
                v-model="certification.openingBank"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行预留手机号:">
              <el-input
                placeholder="请输入银行预留手机号"
                maxlength="11"
                v-model="certification.userBankMobile"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证照片:">
              <div class="flex" style="margin-bottom: 16px">
                <!-- 正面 myq-->

                <el-upload
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  list-type="picture-card"
                  :on-success="FrontImgSuccess"
                  accept=".png,.jpg.jpeg"
                >
                  <img
                    :src="certification.idCardFront"
                    v-if="certification.idCardFront"
                    alt=""
                    class="upload_img"
                  />
                  <img
                    src="../../static/116.png"
                    alt=""
                    class="upload_img"
                    v-else
                  />
                </el-upload>

                <!-- 正面end -->
                <!-- 反面start  myq-->

                <el-upload
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  list-type="picture-card"
                  :show-file-list="false"
                  :on-success="BackImgSuccess"
                  accept=".png,.jpg.jpeg"
                >
                  <img
                    :src="certification.idCardBack"
                    v-if="certification.idCardBack"
                    alt=""
                    class="upload_img"
                  />
                  <img
                    src="../../static/117.png"
                    alt=""
                    class="upload_img"
                    v-else
                  />
                </el-upload>

                <!-- 反面end -->
              </div>
              <!-- 盖章 myq -->

              <!-- <el-upload
                action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                list-type="picture-card"
                :on-success="PhotoImgSuccess"
                :show-file-list="false"
                accept=".png,.jpg.jpeg"
              >
                <img
                  :src="certification.holdingIdPhoto"
                  v-if="certification.holdingIdPhoto"
                  alt=""
                  class="upload_img"
                />
                <img
                  src="../../static/118.png"
                  alt=""
                  class="upload_img"
                  v-else
                />
              </el-upload> -->

              <!-- 盖章end -->
            </el-form-item>
            <el-form-item style="margin-bottom: 0">
              <div class="flex-aling font-12">
                <img
                  src="../../static/107.png"
                  alt=""
                  class="radio_icon"
                  v-if="autonymType"
                  @click="autonymType = !autonymType"
                />
                <img
                  src="../../static/106.png"
                  alt=""
                  class="radio_icon"
                  v-else
                  @click="autonymType = !autonymType"
                />
                <span class="padding_r4" style="color: #8e92b1"
                  >我已仔细阅读并同意</span
                >
                <span
                  style="color: #f68229; cursor: pointer"
                  @click.stop="realNameRemindChange()"
                  >《狗书实名认证服务协议》</span
                >
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="userAttestation()"
                class="form_btn"
                >同意协议并认证</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 《狗书实名认证服务协议》 myq-->
    <div class="popUp_box" v-if="realNameRemindShows">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">狗书实名认证服务协议</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="realNameRemindShows = false"
          />
        </div>
        <!-- 头部end -->
        <div class="protocolsText" v-html="protocolsHtml"></div>
      </div>
    </div>
    <!-- 声音属性弹窗 -->
    <!-- 实名认证提醒弹窗 -->
    <div class="popUp_box" v-if="realNameRemindShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">实名认证</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="realNameRemindShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="flex-dir" style="width: 522px; height: 300px">
          <img src="../../static/128.png" alt="" style="margin-top: 40px" />
          <div
            class="flex-content"
            style="color: #77d769; margin: 20px 0 10px 0"
          >
            已提交
          </div>
          <div class="flex-content font-12" style="color: #8e92b1">
            实名认证已提交，等待平台审核
          </div>
          <div
            class="prompt_btn flex-content"
            @click="realNameRemindShow = false"
          >
            完成
          </div>
        </div>
      </div>
    </div>
    <!-- 声音属性弹窗 -->
    <div class="popUp_box" v-if="soundPropertiesShow">
      <div
        class="pop_up"
        :style="
          soundPropertiesType == 1
            ? 'height: 600px; width: 700px'
            : 'width:522px;height:346px'
        "
      >
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">声音属性</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="soundPropertiesShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="soundProperties flex-dir" v-if="soundPropertiesType == 1">
          <div>上传录音文件，后台将对您的录音进行审核，判断所属音色</div>
          <!-- 文字选择 -->
          <div class="wordage_select flex-aling">
            <img
              src="../../static/122.png"
              alt=""
              class="wordage_icon"
              style="margin-right: 40px"
              @click="previousPage(1)"
            />
            <div class="wordage_box flex-content">
              <div
                class="wordage_text flex-dir"
                :class="{
                  text_animation: pageAnimation,
                  text_animation2: pageAnimation2,
                }"
              >
                <p class="font-18" style="margin-bottom: 25px">
                  富有感情地说出来
                </p>
                <p class="flex-dir font-14" v-if="voiceText[textNum]">
                  {{ voiceText[textNum].text }}
                </p>
              </div>
            </div>
            <img
              src="../../static/123.png"
              alt=""
              class="wordage_icon"
              style="margin-left: 40px"
              @click="previousPage(2)"
            />
          </div>
          <!-- 音频上传 -->
          <div class="audio_upload flex-aling">
            <div class="audio_file flex-aling" v-if="voiceFile.voiceUrl">
              <div class="flex">
                <img src="../../static/125.png" alt="" />
                <div>{{ this.voiceFile.voiceName }}</div>
              </div>
              <div style="color: #8e92b1">
                <!-- 1MB  -->
                <img
                  src="../../static/127.png"
                  alt=""
                  @click="delVoiceChange"
                />
              </div>
            </div>
            <!-- 文件信息end -->
            <!-- <div class="font-12" style="color: rgba(207, 130, 52, 1); margin-left: 12px">
              校验中…
            </div>
            <img
              src="../../static/126.png"
              alt=""
              style="margin-left: 7px"
              class="spin_longin"
            /> -->
            <!-- 选择文件 myq-->
            <el-upload
              class="upload_soundRecording"
              action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="soundRecordingSuccess"
              accept="video/*,audio/*"
              :before-upload="beforeAvatarUpload"
            >
              <div class="file_select flex-content font-14">选择文件</div>
            </el-upload>
            <div class="font-12" style="color: rgba(33, 33, 33, 1)">
              支持MP3格式
            </div>
          </div>
          <!-- 标签选择 -->
          <div style="align-self: self-start">
            您可选择自己的音色协助后台判定
          </div>
          <div class="label_boxs">
            <div class="label flex_wrap">
              <div
                class="label_unselected flex-content"
                v-for="item in voiceList"
                :key="item.id"
                @click="changelabelItem(item.id)"
                :style="
                  pitchOnItem == item.id
                    ? 'background:rgba(254, 224, 127, 1);color:#333'
                    : ''
                "
              >
                <!-- :class="{ label_pitch: pitchOnList.includes(item.id) }" -->
                {{ item.text }}
              </div>
            </div>
          </div>
          <!-- 提交 -->
          <div class="audio_submit flex-content" @click="propertiesOfMyVoice()">
            提交审核
          </div>
        </div>
        <!-- 音色审核已提交 -->
        <div
          class="flex-dir soundProperties_submitted"
          v-if="soundPropertiesType == 2"
        >
          <img src="../../static/128.png" alt="" />
          <div class="font-14" style="color: #77d769; margin-top: 20px">
            音色审核已提交
          </div>
          <div class="font-12" style="color: #8e92b1; margin-top: 11px">
            请耐心等待平台审核结果
          </div>
          <div
            class="roger flex-content"
            @click="
              soundPropertiesShow = false;
              getInfo();
              getPersonLabel();
              getShare();
              getUserRealNameData();
              getGuildList();
            "
          >
            知道了
          </div>
        </div>
      </div>
    </div>
    <!-- 微信-解绑提醒 -->
    <div class="popUp_box" v-if="solutionRemindShow">
      <div class="pop_up" style="height: 360px; width: 500px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="solutionRemindShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="solutionRemind_box flex-dir">
          <img src="../../static/129.png" alt="" />
          <div class="font-14" style="color: #ff2a3e; margin-top: 20px">
            确定解除微信绑定吗？
          </div>
          <div class="flex">
            <div
              class="think_gain flex-content"
              @click="solutionRemindShow = false"
            >
              再想想
            </div>
            <div
              class="confirm_unbundling flex-content"
              @click="cancelBindOpenId()"
            >
              确认解绑
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 黑名单 -->
    <div class="popUp_box" v-if="blacklistShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">黑名单</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="blacklistShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="solutionRemind_box flex-dir">
          <div class="font-12" style="color: #333">
            被拉黑的用户无法关注你或向你发消息；若你是大神，被拉黑用户无法给您下单
          </div>
          <el-input
            placeholder="搜索昵称"
            v-model="petName"
            class="blacklist_input"
            @input="searchInput()"
          ></el-input>
          <div class="llacingCont_table">
            <!-- 头部表头 -->
            <div class="flex-aling llacingCont_tr" style="">
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                序号
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                用户信息
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                用户ID
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="
                  width: 120px;
                  background: rgba(255, 221, 0, 1);
                  color: #000;
                "
              >
                操作
              </div>
            </div>
            <!-- 下单内容 -->
            <div
              class="flex-aling llacingCont_tr"
              v-for="(item, index) in blackList"
              :key="index"
              :class="index % 2 != 0 ? 'llacingCont_trTow' : ''"
            >
              <div class="llacingCont_td flex-content">{{ index + 1 }}</div>
              <div class="llacingCont_td flex-content">
                <img :src="item.avatar" alt="" class="blacklist_head" />
                <span>{{ item.nickname }}</span>
              </div>
              <div class="llacingCont_td flex-content" style="color: #333">
                {{ item.userNo }}
              </div>
              <div
                class="llacingCont_td flex-content"
                style="width: 120px; color: #333"
              >
                <span @click="removeBlack(item)">移出</span>
              </div>
            </div>
          </div>
          <div class="noData" v-if="blackList && blackList.length == 0">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <!-- 广场黑名单 -->
    <div class="popUp_box" v-if="blacklistShowGuang">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">论坛黑名单</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="blacklistShowGuang = false"
          />
        </div>
        <!-- 头部end -->
        <div class="solutionRemind_box flex-dir">
          <div class="font-12" style="color: #333">
            被拉黑的用户无法关注你或向你发消息；若你是大神，被拉黑用户无法给您下单
          </div>
          <el-input
            placeholder="搜索昵称"
            v-model="petName1"
            class="blacklist_input"
            @input="searchInput()"
          ></el-input>
          <div class="llacingCont_table">
            <!-- 头部表头 -->
            <div class="flex-aling llacingCont_tr" style="">
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                序号
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                用户信息
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="background: rgba(255, 221, 0, 1); color: #000"
              >
                用户ID
              </div>
              <div
                class="llacingCont_heder flex-content"
                style="
                  width: 120px;
                  background: rgba(255, 221, 0, 1);
                  color: #000;
                "
              >
                操作
              </div>
            </div>
            <!-- 下单内容 -->
            <div
              class="flex-aling llacingCont_tr"
              v-for="(item, index) in blackList"
              :key="index"
              :class="index % 2 != 0 ? 'llacingCont_trTow' : ''"
            >
              <div class="llacingCont_td flex-content">{{ index + 1 }}</div>
              <div class="llacingCont_td flex-content">
                <img :src="item.avatar" alt="" class="blacklist_head" />
                <span>{{ item.nickname }}</span>
              </div>
              <div class="llacingCont_td flex-content" style="color: #333">
                {{ item.id }}
              </div>
              <div
                class="llacingCont_td flex-content"
                style="width: 120px; color: #333"
              >
                <span @click="forumLimitDelete(item)">移出</span>
              </div>
            </div>
          </div>
          <div class="noData" v-if="blackList && blackList.length == 0">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <!-- 个人标签修改弹窗 -->
    <div class="popUp_box" v-if="editProfileShow">
      <div class="pop_up" style="width: 520px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑资料</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="editProfileShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="label_box">
          <div class="flex">
            <div class="font-16" style="margin-right: 18px; color: #333">
              请选择标签，将展示于个人主页
            </div>
            <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
          </div>
          <!-- 标签 -->
          <div class="label flex_wrap">
            <div
              class="label_item flex-content"
              v-for="item in personList"
              :key="item.id"
              @click="changelabel(item.id)"
              :class="{ label_path: pitchOnList.includes(item.id) }"
              style="padding-top: 0"
            >
              {{ item.text }}
            </div>
          </div>
          <!-- 标签end -->

          <div class="flex step_box">
            <div
              class="labe_nextStep flex-content"
              @click="saveUserLabe('persona')"
            >
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 兴趣标签 -->
    <div class="popUp_box" v-if="editProfileShow1">
      <div class="pop_up" style="width: 520px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑资料</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="editProfileShow1 = false"
          />
        </div>
        <!-- 头部end -->
        <div class="label_box">
          <div class="flex">
            <div class="font-16" style="margin-right: 18px; color: #333">
              请选择兴趣标签，将展示于个人主页
            </div>
            <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
          </div>
          <!-- 标签 -->
          <div
            class="label flex_wrap"
            style="margin: 20px 0; padding-top: 20px"
          >
            <div
              class="label_item flex-dir"
              style="border: 0; height: 65px"
              v-for="item in interestList"
              :key="item.id"
              @click="changelabel(item.id)"
              :class="{ label_path: pitchOnList.includes(item.id) }"
            >
              <img :src="item.logo" alt="" style="width: 48px; height: 48px" />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <!-- 标签end -->
          <div class="flex step_box">
            <div
              class="labe_nextStep flex-content"
              @click="saveUserLabe('interest')"
            >
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 声音标签选择 -->
    <div class="popUp_box" v-if="editProfileShow2">
      <div class="pop_up" style="width: 520px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">编辑资料</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="editProfileShow2 = false"
          />
        </div>
        <!-- 头部end -->
        <div class="label_box" style="padding-bottom: 100px">
          <div class="flex">
            <div class="font-16" style="margin-right: 18px; color: #333">
              请选择喜欢的声音，将展示于个人主页
            </div>
            <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
          </div>
          <!-- 标签 -->
          <div class="label flex_wrap">
            <div
              class="label_item flex-content"
              v-for="item in voiceList"
              :key="item.id"
              @click="changelabel(item.id)"
              :class="{ label_path: pitchOnList.includes(item.id) }"
              style="padding-top: 0"
            >
              {{ item.text }}
            </div>
          </div>
          <!-- 标签end -->

          <div class="flex step_box">
            <div
              class="labe_nextStep flex-content"
              @click="saveUserLabe('voice')"
            >
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 每日打卡弹窗 -->
    <div class="popUp_box box1" v-if="signInShow">
      <div class="signIn_box">
        <img src="../../image/my/2.png" alt="" class="sigIn_topIcon" />
        <div class="flex-spacebetween signIn_titleBox">
          <div style="color: #d79a58">
            每日签到
            <img
              src="../../static/41.png"
              alt=""
              class="sigIn_quit"
              @click="getSignInExplain()"
            />
          </div>
          <img
            src="../../static/59.png"
            alt=""
            class="sigIn_quit"
            @click="signInShow = false"
          />
        </div>
        <div class="signIn_text">
          已签到
          <span style="color: #fd4732"> {{ registered }} </span> 天，继续加油
        </div>
        <!-- 日期 -->
        <div class="calendar_box flex-dir">
          <div class="calendar_title">
            <div
              v-for="(item, index) in weekList"
              :key="index"
              class="calendar_titleItem"
            >
              {{ item }}
            </div>
          </div>
          <div class="halving_line"></div>
          <div class="calendar_content">
            <div
              class="dataItem flex-content"
              v-for="(item, key1) in calendarList"
              :key="key1"
            >
              <div v-if="item.whether == 1" class="dataItem_pitch flex-content">
                {{ item.num }}
              </div>
              <div v-else>{{ item.num }}</div>
            </div>
          </div>
          <!-- 点击签到 -->
          <div class="calendar_btn flex-content" v-if="signWhether">
            <img
              src="../../image/my/3.png"
              alt=""
              style="margin-right: 11px"
            />已签到
          </div>
          <div
            class="calendar_btn flex-content"
            v-else
            @click.stop="addsignIn()"
          >
            <img src="../../image/my/3.png" alt="" style="margin-right: 11px" />
            签到
          </div>
        </div>
      </div>
    </div>
    <!-- 签到说明 -->
    <div class="popUp_box" v-if="signInTextShow">
      <div class="pop_up" style="width: 520px; height: 600px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">签到说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="signInTextShow = false"
          />
        </div>
        <!-- 头部end -->
        <div style="padding: 20px">
          <div
            v-html="signInText"
            style="height: 450px; overflow-y: scroll; color: #333"
          ></div>
        </div>
      </div>
    </div>
    <!-- 注销账号弹窗 -->
    <div class="popUp_box" v-if="writeOffShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">注销账号</div>
          <img src="../../static/quit.png" alt="" @click="writeOffShowChange" />
        </div>
        <!-- 头部end -->
        <div class="writeOff_box">
          <!-- 步骤一 -->
          <div v-if="writeOffType == 1">
            <div style="margin-top: 30px">
              填写并提交注销申请，工作人员会协助你完成注销。
            </div>
            <div style="color: #6a6a94; margin-top: 30px">
              你主动注销后，我们将停止为你提供产品/服务，根据适用法律的要求删除你的个人信息，或使其匿名化处理
            </div>
            <div class="writeOff_btn flex-content" @click="writeOffType = 2">
              填写注销申请
            </div>
          </div>
          <!-- 步骤二 -->
          <div v-if="writeOffType == 2">
            <div style="margin-top: 20px">账号注销</div>
            <div style="color: #ff2a3e; margin-top: 7px">
              永久注销且无法恢复，请谨慎操作
            </div>
            <div style="margin-top: 20px">请选择注销类型</div>
            <div
              class="flex-aling writeOff_cause"
              v-for="(item, index) in writeOffList"
              :key="index"
              @click="choiceCause(index + 1)"
            >
              <img
                src="../../static/107.png"
                alt=""
                v-if="writeCause == index + 1"
              />
              <img src="../../static/106.png" alt="" v-else />
              <span>{{ item }}</span>
            </div>
            <div style="color: #ff2a3e; margin-top: 7px">请填写注销原因</div>
            <el-input
              v-model="writeCauseinput"
              placeholder="请输入内容"
            ></el-input>
            <div class="writeOff_btn flex-content" @click="writeOffType = 3">
              下一步
            </div>
          </div>
          <!-- 步骤三 -->
          <div v-if="writeOffType == 3">
            <div style="margin-top: 20px">账号注销</div>
            <div style="color: #ff2a3e; margin-top: 7px">
              永久注销且无法恢复，请谨慎操作
            </div>
            <div style="margin-top: 20px">账号注销后将放弃以下权益</div>
            <div style="color: #8e92b1; margin-top: 10px">
              1、账号信息、第三方授权信息将被清空且无法恢复。<br />
              2、账号相关信息将会一并被删除且无法恢复。<br />
              3、注销后手机号码可重新注册，所生成账号为全新的账号，不包含注销账号的任何信息。<br />
              4、元宝、龙币、魅力值未使用完毕，注销则自动清零。<br />
            </div>
            <div style="color: #ff2a3e; margin-top: 16px">
              点击下方按钮，即表示你已阅读并了解以上风险
            </div>
            <div class="writeOff_btn2 flex-content" @click="writeOffType = 4">
              我已清除风险，确定继续
            </div>
            <div
              class="writeOff_btn2 btn3 flex-content"
              @click="writeOffShow = false"
            >
              返回
            </div>
          </div>
          <!-- 提醒 -->
          <div v-if="writeOffType == 4" class="flex-dir">
            <img src="../../static/129.png" alt="" class="writeOff_icon" />
            <div>
              当前龙币未使用完毕，请再次确认你已了解注销风险，并要继续执行该操作。
            </div>
            <div class="writeOff_btn2 flex-content" @click="cancelApply()">
              确认注销
            </div>
            <div class="writeOff_btn2 btn3 flex-content">返回</div>
          </div>
          <!-- 申请中 -->
          <div v-if="writeOffType == 5" class="flex-dir">
            <img src="../../static/128.png" alt="" class="writeOff_icon" />
            <div style="color: #77d769; margin-bottom: 11px">
              注销申请已提交
            </div>
            <div style="color: #8e92b1">
              我们工作人员将会对您的账号进行审核，审核通过后，
            </div>
            <div style="color: #8e92b1; margin-bottom: 105px">
              账号将会被注销，请知悉
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 房间设置 -->
    <div class="popUp_box" v-if="roomSetShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">房间设置</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="roomSetShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="roomSet_box flex">
          <div class="setList_box">
            <div class="setList_item">
              <div>
                <div>屏蔽所有特效</div>
                <div class="setList_12">开启后默认全部打开以下6项功能开关</div>
              </div>
              <el-switch
                v-model="roomSetData.allSpecialEffects"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet('all')"
              >
              </el-switch>
            </div>
            <div class="setList_item item2">
              <div>
                <div>屏蔽礼物特效</div>
                <div class="setList_12">开启后不播放礼物赠送特效</div>
              </div>
              <el-switch
                v-model="roomSetData.allGift"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
            <div class="setList_item item2">
              <div>
                <div>屏蔽礼物横幅</div>
                <div class="setList_12">
                  开启后不播放赠送礼物和抽中礼物的横幅
                </div>
              </div>
              <el-switch
                v-model="roomSetData.allGfitBanner"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
            <div class="setList_item item2">
              <div>
                <div>屏蔽入场特效</div>
                <div class="setList_12">开启后不播放进场的特效</div>
              </div>
              <el-switch
                v-model="roomSetData.allIn"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
            
            <div class="setList_item item2">
              <div>
                <div>屏蔽房间特效</div>
                <div class="setList_12">开启后使用默认静态背景取代动态背景</div>
              </div>
              <el-switch
                v-model="roomSetData.allBackImg"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
            <div class="setList_item item2">
              <div>
                <div>屏蔽全屏弹窗</div>
                <div class="setList_12">开启后不播放全屏广播</div>
              </div>
              <el-switch
                v-model="roomSetData.allBulletChat"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
            <div class="setList_item item2">
              <div>
                <div>屏蔽特效声音</div>
                <div class="setList_12">开启后不播放特效声音</div>
              </div>
              <el-switch
                v-model="roomSetData.allSpecialVoice"
                :active-value="1"
                :inactive-value="2"
                @change="setRoomUserSet()"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 图片裁剪 -->
    <div class="popUp_box" v-if="headCropImgShow">
      <div class="pop_up">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">图片裁剪</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="headCropImgShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="label_box">
          <div class="cropImg_box">
            <cropper-image
              :Name="cropperName"
              @uploadImgSuccess="handleUploadSuccess"
              ref="child"
              :img="headImg"
              @avatarClose="updateAvatar"
            >
            </cropper-image>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改手机号 -->
    <div class="popUp_box" v-if="PhoneShow">
      <div class="pop_up" style="height: 500px; width: 570px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">更换绑定手机号</div>
          <img src="../../static/quit.png" alt="" @click="PhoneShow = false" />
        </div>
        <!-- 头部end -->
        <div class="password_data">
          <div class="flex" style="margin-bottom: 30px">
            <div class="schedule_item flex-dir">
              <div class="schedule_icon flex-content">1</div>
              <div>短信验证</div>
            </div>
            <img
              src="../../image/my/9.png"
              alt=""
              class="when_icon"
              v-if="mobileType != 1"
            />
            <img src="../../image/my/10.png" alt="" class="when_icon" v-else />
            <div
              class="schedule_item flex-dir"
              :style="{ color: mobileType == 1 ? '#fff' : '' }"
            >
              <div
                class="schedule_icon flex-content"
                :style="{ background: mobileType == 1 ? '#363659' : '' }"
              >
                2
              </div>
              <div style="color: #333">更换新手机号</div>
            </div>
            <img
              src="../../image/my/9.png"
              alt=""
              class="when_icon"
              v-if="mobileType == 3"
            />
            <img src="../../image/my/10.png" alt="" class="when_icon" v-else />
            <div
              class="schedule_item flex-dir"
              :style="{ color: mobileType != 3 ? '#fff' : '' }"
            >
              <div
                class="schedule_icon flex-content"
                style="background-color: #363659"
                :style="{ background: mobileType != 3 ? '#363659' : '' }"
              >
                3
              </div>
              <div style="color: #333">完成</div>
            </div>
          </div>
          <div v-if="mobileType == 1">
            <el-form ref="form" :model="passwordForm" label-width="100px">
              <el-form-item label="当前手机号码:">
                <div style="color: #333">{{ userInfo.mobile }}</div>
              </el-form-item>
              <el-form-item label="图形验证码:">
                <div class="flex-aling">
                  <el-input
                    v-model="imgCodeValue"
                    maxlength="4"
                    style="width: 148px"
                  ></el-input>
                  <img
                    :src="codeImg"
                    v-if="codeImg"
                    alt=""
                    style="margin-left: 16px"
                  />
                </div>
              </el-form-item>
              <el-form-item label="验证码:">
                <div class="flex">
                  <el-input
                    v-model="passwordForm.code"
                    maxlength="6"
                    style="width: 148px"
                  ></el-input>
                  <!-- <span style="margin-left: 28px">获取验证码</span> -->
                  <verificationCode
                    :event="'changePhone'"
                    :mobile="userInfo.mobile"
                  ></verificationCode>
                </div>
              </el-form-item>
              <el-button
                type="primary"
                @click="mobileChange()"
                class="form_btn"
                style="width: 382px; margin-top: 41px; color: #333"
                >下一步</el-button
              >
            </el-form>
          </div>
          <div v-if="mobileType == 2">
            <el-form ref="form" :model="passwordForm" label-width="100px">
              <el-form-item label="新手机号码:">
                <el-input v-model="newMobile" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="验证码:">
                <div class="flex">
                  <el-input v-model="newCode" style="width: 148px"></el-input>
                  <!-- 获取验证码 -->
                  <verificationCode
                    :event="'changePhone'"
                    :mobile="newMobile"
                  ></verificationCode>
                </div>
              </el-form-item>
              <el-button
                type="primary"
                @click="setChangeMobile()"
                class="form_btn"
                style="width: 382px; margin-top: 41px; color: #333"
                >提交</el-button
              >
            </el-form>
          </div>
          <div v-if="mobileType == 3">
            <div class="flex-dir">
              <img
                src="../../static/128.png"
                alt=""
                style="margin-bottom: 20px"
              />
              <div class="font-14" style="color: #77d769">换帮成功</div>
            </div>
            <el-button
              type="primary"
              @click="accomplishMobile"
              class="form_btn"
              style="width: 382px; margin-top: 41px"
              >完成</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <walletTopup
      v-if="walletTopupShow"
      @walletQuitChange="walletTopupShow = false"
      style="position: absolute; left: -30%; top: -20%"
    ></walletTopup>
    <div class="popUp_box" v-if="enterShow">
      <div class="pop_up" style="height: 600px; width: 880px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">
            {{ enterNum == 0 ? "加入陪玩" : "公会列表" }}
          </div>
          <img src="../../static/quit.png" alt="" @click="enterShow = false" />
        </div>
        <!-- 头部end -->
        <div class="enter_applyFor flex-dir" v-if="enterNum == 0">
          <img src="../../static/121.png" alt="" />
          <div class="enter_applyForBtn flex-content" @click="enterNum++">
            申请入驻
          </div>
        </div>
        <div v-if="enterNum != 0" class="">
          <div class="gonghuiListTop1">
            <div class="gonghuiListTop">
              <el-input
                class="inputBtn"
                suffix-icon="el-icon-search"
                placeholder="请输入邀请码"
                v-model="gonghuiName"
              ></el-input>
            </div>
            <div style="color: #4177dd; margin-top: 10px">加入公会：—</div>
          </div>
        </div>
        <div
          v-if="enterNum != 0"
          style="
            margin-left: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #212121;
          "
        >
          推荐公会
        </div>
        <div class="gonghuiContent" style="padding: 20px" v-if="enterNum != 0">
          <div
            class="gonghuiContentFlex"
            v-for="(item, index) in guildListArr"
            :key="index"
          >
            <img
              style="
                width: 64px;
                height: 64px;
                border-radius: 50%;
                margin-right: 12px;
              "
              :src="item.guildLogo"
              alt=""
            />
            <div style="width: 300px">
              <div style="color: #333">{{ item.guildName }}</div>
              <div class="gonghuiTitleFlex">
                <div style="color: rgba(77, 85, 150, 1)">
                  邀请码：{{ item.inviteCode }}
                </div>
                <img
                  @click="copyCode(item.inviteCode)"
                  style="width: 14px; height: 14px; margin-left: 6px"
                  src="../../static/223.png"
                  alt=""
                />
              </div>
              <div class="gonghuiTitleFlex">
                <div style="color: #333">
                  {{ item.userNickName }}：{{ item.userNo }}
                </div>
                <img
                  style="width: 14px; height: 14px; margin-left: 6px"
                  src="../../static/224.png"
                  alt=""
                />
              </div>
            </div>
            <div class="apllyBtn" @click="goApplyBtn(item.id)">申请加入</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import walletTopup from "./walletTopup.vue"; //充值弹窗
// import areaData from "@/common/areadata/areadata.js";
import cityData from "@/common/areadata/city.json";
import CropperImage from "@/components/headImage/CropperImage.vue";
import verificationCode from "../../components/verificationCode/index.vue"; //获取验证码
import enter from "../association/enter.vue";

export default {
  name: "",
  components: { CropperImage, cityData, walletTopup, verificationCode, enter },
  props: {
    userId: {
      // type: Number ,//类型属性
      default: "", //默认属性
    },
  },
  data() {
    return {
      gonghuiName: "",
      enterShow: false,
      enterNum: 0,
      walletTopupShow: false, //充值弹窗
      userInfo: {
        imgs: [],
      }, //用户信息
      petName: "", //黑名单搜索内容
      petName1: "",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      passwordForm: {
        newPwd: "", //	用户新密码
        confirmNewPwd: "", //	确认新密码
        type: "2", //(1：旧密码验证 2：手机号验证)
      },
      newMobile: "", //新手机号
      newCode: "", //新手机号验证码
      editShow: false, //编辑弹窗显示，
      isguangShow: false,
      guangchangShow: false, //广场
      passwordShow: false, //修改登录密码显示
      realNameShow: false, //实名认证弹窗显示
      realNameRemindShows: false, //认证协议
      realNameRemindShow: false, //实名认证成功提醒
      soundPropertiesShow: false, //声音属性
      soundPropertiesType: 1, //声音
      solutionRemindShow: false, //解绑提现
      blacklistShow: false, //黑名单
      blacklistShowGuang: false,
      PhoneShow: false, //手机号
      labelList: ["可爱", "御姐", "霸道", "热情", "敏感", "开朗"],
      editProfileShow: false, //个人标签
      editProfileShow1: false, //兴趣标签
      editProfileShow2: false, //声音标签
      signInShow: false, //每日签到
      signInTextShow: false, //签到说明
      signInText: "", //签到说明内容
      roomSetShow: false, //房间设置
      writeOffShow: false, //注销账号弹窗
      writeOffType: 1, //注销
      writeOffList: ["多账号，释放手机号", "不想使用了", "其他"],
      input: "",
      personList: [], //个人标签列表
      voiceList: [], //声音标签列表
      interestList: [], //兴趣标签
      pitchOnList: [], //选中
      headCropImgShow: false, //图片裁剪
      headImg: "", //选择头像
      cropperName: "cropperName",
      cropperModel: false,
      cityOptions: [], //地区列表
      shareImg: "", //分享二维码
      certification: {
        //实名认证数据
        userName: "", //姓名
        bankCardNumber: "", //银行卡号
        openingBank: "", //开户行
        idCard: "", //身份证号
        idCardFront: "", //身份证正面
        idCardBack: "", //身份证反面
        holdingIdPhoto: "", //手持身份证
        userBankMobile: "", //银行卡预留手机号
      },
      autonymType: false, //实名认证协议
      pushSwitchType: false, //个性化推荐
      mobileType: "1", //修改手机号状态
      writeCause: 1, //注销原因
      writeCauseinput: "", //其他注销原因
      roomSetData: {}, //房间设置
      loadType: true, //加载中
      calendarList: [], //日历时间
      weekList: ["日", "一", "二", "三", "四", "五", "六"],
      weekday: "", //周几
      signWhether: false, //当天是否签到
      registered: 0, //已签到天数
      blackList: [], //黑名单列表
      cascaderShow: false, //级联选择器
      fatherkey: "", //选中父级
      cityObj: {},
      voiceText: [], //录音文本
      pageAnimation: false, //录音文本动画
      pageAnimation2: false, //录音文本动画
      textNum: 0,
      voiceFile: {
        voiceUrl: "",
      }, //聊天文件
      imgLists: [], //预览照片墙
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, //myq
      pitchOnItem: "", //声音属性
      codeImg: "", //图像验证码
      imgCodeValue: "", //图形验证码内容
      certificationData: {}, //用户实名认证数据
      isAttestData: {
        auditResults: "", //审核结果  0-未处理 1-拒绝 2-通过
        reason: "", //失败原因
      }, //实名认证状态
      protocolsHtml: "", //实名认证协议内容
      guildListArr: [],
      forumAreaId: "",
      dayArr: [],
    };
  },
  mounted() {
    this.cityOptions = cityData; //地区
    this.getInfo();
    this.getPersonLabel();
    this.getShare();
    this.getUserRealNameData();
    this.getGuildList();
    this.forumAreaList();
  },
  methods: {
    goBtnDay(item) {
      this.dayArr.forEach((item1) => {
        if (item.id == item1.id) {
          item1.isflage = true;
        } else {
          item1.isflage = false;
        }
      });
      this.$forceUpdate();
    },
    // guildList公会列表
    getGuildList() {
      this.$api.queryRecommendInfo({ pageNum: 1, pageSize: 15 }).then((res) => {
        this.guildListArr = res.data.rows;
        console.log(this.guildListArr, "this.guildListArrthis.guildListArr");
      });
    },
    groupList(){
      this.$api.groupListGroup().then(res=>{
        if (res.code == 1) {
          console.log(res,'resre')
        }
      })
    },
    // 微信解绑
    cancelBindOpenId() {
      this.$api.cancelBindOpenId({ platform: 1 }).then((res) => {
        if (res.code == 1) {
          this.getInfo();
          this.solutionRemindShow = false;
        }
      });
    },
    //获取实名认证协议
    realNameRemindChange() {
      this.$api.getAgreement({ configName: "realService" }).then((res) => {
        if (res.code == 1) {
          this.protocolsHtml = res.data;
          this.realNameRemindShows = true;
        }
      });
    },
    //签到说明
    getSignInExplain() {
      this.$api.getAgreement({ configName: "signRule" }).then((res) => {
        if (res.code == 1) {
          this.signInText = res.data;
          this.signInTextShow = true;
        }
      });
    },
    // 获取实名认证状态
    getIsAttest() {
      this.$api.isAttest({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.isAttestData = res.data;
        }
      });
    },
    // 获取用户实名认证信息
    getUserRealNameData() {
      this.$api.getUserRealNameData().then((res) => {
        if (res.code == 1) {
          this.certificationData = res.data;
        }
      });
    },
    // 图片审核状态
    imgAuditChange(i) {
      if (i.auditStatus == 2) {
        let text = "审核失败原因：" + i.reason;
        this.$message.error(text);
      }
    },
    // 验证图像验证码
    mobileChange() {
      if (!this.imgCodeValue) return this.$message.info("请输入图形验证码");
      if (!this.passwordForm.code) return this.$message.info("请输入验证码");
      this.$api.checkImage({ code: this.imgCodeValue }).then((res) => {
        if (res.code == 1) {
          this.mobileType = 2;
        } else {
          // this.getRandomImage()
          this.$message.info(res.msg);
        }
      });
    },
    // 获取图形验证码
    getRandomImage() {
      this.$api.randomImage().then((res) => {
        // console.log('图像验证码',res);
        this.codeImg = res.data;
      });
    },
    // 修改手机号
    setChangeMobile() {
      let data = {
        captcha: this.newCode, //验证码
        event: "changePhone", //事件,示例值(changePhone)
        mobile: this.newMobile, //手机号
      };
      this.$api.changeMobiledata(data).then((res) => {
        if (res.code == 1) {
          this.mobileType = 3;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    changelabelItem(i) {
      this.pitchOnItem = i;
    },
    // 认证大神
    manitoChange() {
      if (this.userInfo.isCertification == 0) {
        this.$message.info("请先进行实名认证后加入公会");
      } else {
        this.enterShow = true;
        this.enterNum = 0;
      }

      // this.$api.queryGuildByUserId({ userId: this.userInfo.id }).then((res) => {
      //   if (res.data && res.data.id) {
      //     this.$router.push("/manito");
      //   } else
      //     // this.$message.info("请先入驻公会");
      //   }
      // });
    },
    // 上传我的声音
    propertiesOfMyVoice() {
      let data = {
        likeVoiceIds: this.pitchOnItem, //声音属性
        voiceAddress: this.voiceFile.voiceUrl, //声音地址
        voiceLen: this.voiceFile.voiceLen, //声音时长
      };
      data.voiceLen = Math.trunc(data.voiceLen);
      this.$api.propertiesOfMyVoice(data).then((res) => {
        if (res.code == 1) {
          this.soundPropertiesType = 2;
        }
      });
    },
    // 删除语音文件
    delVoiceChange() {
      this.voiceFile.voiceUrl = "";
      this.voiceFile.voiceName = "";
      this.voiceFile.voiceLen = "";
    },
    // 上传录音文件
    soundRecordingSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.voiceFile.voiceUrl = response.data.url;
        this.voiceFile.voiceName = file.name;
      }
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        let playTime = audioElement.duration;
        this.voiceFile.voiceLen = playTime;
      });
    },
    // 录音文件页面切换
    previousPage(type) {
      if (type == 1) {
        if (this.textNum == 0) return this.$message.info("第一页了~");
        this.textNum--;
        this.pageAnimation = true;
        setTimeout(() => {
          this.pageAnimation = false;
        }, 1500);
      } else {
        if (this.textNum == this.voiceText.length)
          return this.$message.info("最后一页了~");
        this.textNum++;
        this.pageAnimation2 = true;
        setTimeout(() => {
          this.pageAnimation2 = false;
        }, 1500);
      }
    },
    // 声音属性认证
    soundPropertiesChange() {
      this.soundPropertiesShow = true;
      this.$api.getDictItem({ code: "words_book" }).then((res) => {
        if (res.code == 1) {
          this.voiceText = res.data;
        }
      });
    },
    // 选择器父级选择
    fatherChange(i, index) {
      this.fatherkey = index;
      this.cityObj = i.city;
    },
    //确认选择
    cityChange(i) {
      this.cascaderShow = false;
      this.userInfo.city = i.cityName;
    },
    // 黑名单搜索
    searchInput() {
      this.userBlackList();
    },
    // 用户取消拉黑
    removeBlack(i) {
      this.$api.removeBlack({ blackUserId: i.userId }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.userBlackList();
        }
      });
    },
    // 黑名单移出
    forumLimitDelete(item) {
      this.$api.forumLimitDelete1({ id: item.id }).then((res) => {
        if (res.code == 1) {
          this.userBlackListShowGuang();
          this.$message.info("移出成功");
        }
      });
    },
    // 黑名单列表显示
    blacklistChange() {
      this.blacklistShow = true;
      this.userBlackList();
    },
    guangchanglistChange() {
      this.blacklistShowGuang = true;
      this.userBlackListShowGuang();
    },
    // 黑名单列表
    userBlackList() {
      this.$api.userBlackList({ nickname: this.petName }).then((res) => {
        if (res.code == 1) {
          this.blackList = res.data;
        }
      });
    },
    // 黑名单广场列表
    userBlackListShowGuang() {
      this.$api.forumLimitList({ nickname: this.petName1 }).then((res) => {
        if (res.code == 1) {
          this.blackList = res.data.rows;
        }
      });
    },
    // 签到
    addsignIn() {
      this.$api.signIn().then((res) => {
        if (res.code == 1) {
          this.signInChange();
        }
      });
    },
    // 签到弹窗显示
    signInChange() {
      this.signInShow = true;
      this.$api.currentMonth().then((res) => {
        // console.log('签到回显',res);
        if (res.code == 1) {
          this.registered = res.data.signList.length;
          this.calendarList = [];
          let list = res.data.signDays.split(",");
          list.map((item) => {
            let itemList = item.split(":");
            let obj = {
              num: itemList[0],
              whether: itemList[1],
            };
            this.calendarList.push(obj);
          });
          //当前月当天日期对象：
          var date = new Date();
          //当前月第一天日期对象：
          date = new Date(date.setDate(1));
          //当前月第一天星期几:
          var weekday = date.getDay();
          let newData = new Date();
          let getDate = newData.getDate();
          // 当天是否签到
          this.signWhether = false;
          if (
            this.calendarList[getDate - 1].num == getDate &&
            this.calendarList[getDate - 1].whether == 1
          ) {
            this.signWhether = true;
          }

          this.weekday = weekday;
          for (let index = 0; index < this.weekday; index++) {
            this.calendarList.unshift({});
          }
        }
      });
    },
    noDisturbing() {
      this.$api.saveUserInfo({ notDisturb: this.userInfo.noDisturb });
    },
    setRoomUserSet(type) {
      // if(type == "")
      this.$api.setRoomUserSet(this.roomSetData).then((res) => {
        // console.log("房间设置", res);
      });
    },
    // 设置动态天数
    updateForumArea() {
      this.dayArr.forEach((item) => {
        if (item.isflage) {
          this.$api.updateForumArea({ forumAreaId: item.area }).then((res) => {
            if (res.code == 1) {
              this.isguangShow = false;
              this.$message.info("设置成功");
            }
          });
        }
      });
    },
    forumAreaList() {
      this.$api.forumAreaList({ pageNum: 1, pageSize: 15 }).then((res) => {
        res.data.forEach((item) => {
          item.isflage = false;
        });
        this.dayArr = res.data;
      });
    },
    // 获取用户房间设置
    getRoomUserSet() {
      this.roomSetShow = true;
      this.$api.getRoomUserSet().then((res) => {
        // console.log("获取用户房间设置", res);
        if (res.code == 1) {
          this.roomSetData = res.data;
          // if (this.roomSetData.allSpecialEffects == 2) {
          //   for(var i )
          // }
        }
      });
    },
    writeOffShowChange() {
      this.writeOffType = 1;
      this.writeOffShow = false;
    },
    // 注销申请
    cancelApply() {
      // 1-多账号，释放手机号 2-不想用了 3-其他
      this.$api
        .cancelApply({
          cancelType: this.writeCause,
          reason: this.writeCauseinput,
        })
        .then((res) => {
          if (res.code == 1) {
            this.writeOffType = 5;
          }
        });
    },
    // 注销原因选择
    choiceCause(i) {
      this.writeCause = i;
    },
    // 手机号绑定完成
    accomplishMobile() {
      this.mobileType = 1;
      this.PhoneShow = false;
    },
    // 个性化推荐切换
    pushSwitchChange() {
      this.$api.saveUserInfo({ style: this.userInfo.recommendSwitch });
    },
    // 头像上传成功
    updateAvatar() {
      // console.log('头像上传成功');
      setTimeout(() => {
        this.getInfo();
        this.$emit("userUpdate");
      }, 300);
      this.headCropImgShow = false;
    },
    // 照片墙删除
    delImg(i) {
      this.$api.userImgDelete({ id: i.id }).then((res) => {
        if (res.code == 1) {
          this.getInfo();
        }
      });
    },

    //照片墙上传
    photoWallImgSuccess(response, file, fileList) {
      // this.userInfo.imgs.push(response.data.url);
      if (!this.loadType) return this.$message.info("上传中");
      this.loadType = false;
      this.$api
        .userImgAdd({
          imgPath: response.data.url,
        })
        .then((res) => {
          if (res.code == 1) {
            this.getInfo();
          } else {
            this.$message.info(res.msg);
          }
          this.loadType = true;
        });
    },
    // 实名认证
    userAttestation() {
      let reg = /^[\u0391-\uFFE5]+$/;
      let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.certification.userName))
        return this.$message.info(
          !this.certification.userName ? "请输入姓名" : "姓名输入有误"
        );
      // if (!this.certification.userName)
      //   return this.$message.info("请输入真实姓名");
      if (!this.certification.idCard) return this.$message.info("请输入身份证");
      if (!this.certification.bankCardNumber)
        return this.$message.info("请输入银行卡号");
      if (!this.certification.openingBank)
        return this.$message.info("请输入开户行名称");
      if (!this.certification.userBankMobile)
        return this.$message.info("请输入银行卡预留手机号");
      if (!phoneReg.test(this.certification.userBankMobile))
        return this.$message.info("手机号码格式错误");
      if (!this.certification.idCardFront)
        return this.$message.info("请上传身份证正面");
      if (!this.certification.idCardBack)
        return this.$message.info("请上传身份证反面");
      // if (!this.certification.holdingIdPhoto)
      //   return this.$message.info("请上传手持身份证");
      if (!this.autonymType) return this.$message.info("请阅读服务协议");
      this.$api.userRealNameAuthentication(this.certification).then((res) => {
        if (res.data.codeType == "1") {
          this.realNameShow = false;
          this.realNameRemindShow = true;
          this.$message.success(res.data.msg);
          this.getInfo();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 身份证正面文件上传成功
    FrontImgSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.certification.idCardFront = response.data.url;
      }
    },
    // 身份证反面文件上传成功
    BackImgSuccess(response, file, fileList) {
      if (response.code == 1) {
        this.certification.idCardBack = response.data.url;
      }
    },
    // 手持身份证文件上传成功
    PhotoImgSuccess(response, file, fileList) {
      // console.log("文件上传response", response);
      if (response.code == 1) {
        this.certification.holdingIdPhoto = response.data.url;
      }
    },
    handleRemove(file, fileList) {},
    handleChange(value, e) {
      // this.userInfo.city = value[1]
      setTimeout(() => {
        this.$set(this.userInfo, "city", value[1]);
      }, 300);
    },
    // 获取用户分享二维码
    getShare() {
      this.$api.getShare().then((res) => {
        if (res.code == 1) {
          this.shareImg = res.data.qrCode;
        }
      });
    },
    // 查询用户注销申请状态
    getCancelApplyStatus() {
      this.$api.getCancelApplyStatus().then((res) => {});
    },
    // 头像选择
    //封面设置
    uploadPicture(name) {
      this.cropperName = name;
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess(data) {
      switch (data.name) {
        case "flagImg":
          this.formValidate.mainImage = "http://ydfblog.cn/dfs/" + data.url;
          break;
      }
      this.cropperModel = false;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.headImg = data;
      };
      setTimeout(() => {
        this.headCropImgShow = true;
      }, 1000);
      //转化为base64
      reader.readAsDataURL(file);
    },
    // 个人信息
    getInfo() {
      this.$api.queryUser({ otherUserId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
          this.imgLists = [];
          this.userInfo.imgs.forEach((item) => {
            this.imgLists.push(item.img);
          });
          this.getIsAttest();
          // if (this.userInfo.imgs) {
          //   this.userInfo.imgs = this.userInfo.imgs.split(",");
          // } else {
          //   this.userInfo.imgs = [];
          // }
        }
      });
    },
    // 修改密码
    changePassword() {
      let passwordText = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,12}$/;
      if (!this.imgCodeValue) return this.$message("请输入图形验证码");
      if (!this.passwordForm.newPwd) return this.$message("请输入新密码");
      if (!this.passwordForm.confirmNewPwd)
        return this.$message("请确认新密码");
      if (this.passwordForm.newPwd != this.passwordForm.confirmNewPwd)
        return this.$message("请确认密码是否一致");
      if (!passwordText.test(this.passwordForm.newPwd))
        return this.$message.info("请输入密码(6~12位字母+数字)");

      let codeType = false; // 验证码是否通过
      let codeMessage = "";
      this.$api.checkImage({ code: this.imgCodeValue }).then((res) => {
        if (res.code == 1) {
          codeType = false;
        } else {
          codeType = true;
          this.getRandomImage();
          this.$message.info(res.msg);
        }
      });
      if (codeType) return this.$message.info(codeMessage); //图像验证码错误
      this.$api.changePwd(this.passwordForm).then((res) => {
        if (res.code == 1) {
          this.passwordShow = false;
          this.confirmNewPwd = "";
          this.newPwd = "";
          this.mobile = "";
          this.code = "";
          localStorage.removeItem("user-token");
          localStorage.removeItem("rcToken");
          this.$router.push("/login");
          this.$message.success('修改成功');
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 编辑个人基础资料
    editData() {
      let nameArr = this.userInfo.nickname.split("");
      if (nameArr.length < 2 || nameArr.length > 10)
        return this.$message.info("请输入2~10位的名称");
      this.$api
        .saveUserInfo({
          nickname: this.userInfo.nickname, //用户昵称
          birthday: this.userInfo.birthday, //生日（yyyy-MM-dd）
          sex: this.userInfo.sex, //	性别（1-男 2-女）
          personalSignature: this.userInfo.personalSignature,
          city: this.userInfo.city,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.editShow = false;
            this.getInfo();
          } else {
            if (res.msg == "个人签名存在敏感词") {
              this.userInfo.personalSignature = "";
            }
            if (res.msg == "昵称存在敏感词") {
              this.userInfo.nickname = "";
            }
            this.$message.info(res.msg);
          }
        });
    },
    copyCode(copyContent) {
      // 点击复制
      var input = document.createElement("input"); // 创建input对象
      input.value = copyContent; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    goApplyBtn(item) {
      this.$confirm("是否确认加入公会?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log(item, "itemitem");
          this.$api.guildUserApplyAdd({ guildId: item.id }).then((res) => {
            if (res.code == 1) {
              this.enterShow = false;
              this.enterNum = 0;
              this.$message({
                type: "success",
                message: "申请加入公会成功",
              });
            } else {
              this.$message({
                message: "" + res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    // 保存用户信息
    saveUserLabe(type) {
      let persona = "";
      let interest = "";
      let likeVoice = "";
      if (type == "persona") {
        this.editProfileShow = false;
        persona = this.pitchOnList.toString();
      } else if (type == "voice") {
        this.editProfileShow2 = false;
        likeVoice = this.pitchOnList.toString();
      } else if (type == "interest") {
        this.editProfileShow1 = false;
        interest = this.pitchOnList.toString();
      }
      this.$api
        .saveUserInfo({
          personalTagIds: persona, //个人标签
          interestLabelIds: interest, //感兴趣标签ids
          likeVoiceIds: likeVoice, //喜欢的声音ids
        })
        .then((res) => {
          if (res.code == 1) {
            this.pitchOnList = [];
            this.getInfo();
          }
        });
    },
    goApplyJoin() {
      this.$message("请移至app进行实名认证操作");
    },
    // 标签选择·
    changelabel(i) {
      if (this.pitchOnList.includes(i)) {
        //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
        //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
        this.pitchOnList = this.pitchOnList.filter(function (ele) {
          return ele != i;
        });
      } else {
        this.pitchOnList.push(i);
        //console.log(this.pitchOnList, this.personList);
      }
      if (this.pitchOnList.length > 6) {
        this.pitchOnList.shift();
      }
      //  console.log(this.pitchOnList);
    },
    //标签页已选择的初始化---myq
    labelCretan(data, datas, item) {
      if (item == 1) {
        datas.map((item) => {
          if (data.includes(item.text)) {
            this.changelabel(item.id);
          }
        });
        this.editProfileShow = true;
      }
      if (item == 2) {
        datas.map((item) => {
          if (data.includes(item.name)) {
            this.changelabel(item.id);
          }
        });
        this.editProfileShow1 = true;
      }
      if (item == 3) {
        datas.map((item) => {
          if (data.includes(item.text)) {
            this.changelabel(item.id);
          }
        });
        this.editProfileShow2 = true;
      }
    },

    // 获取标签列表
    getPersonLabel() {
      this.$api.getUserPersonalTag().then((res) => {
        // console.log("个人标签列表", res);
        if (res.code == 1) {
          this.personList = res.data;
        }
      });
      this.$api.getUserVoiceTag().then((res) => {
        // console.log("声音标签列表", res);
        if (res.code == 1) {
          this.voiceList = res.data;
        }
      });
      this.$api.getInterestTags().then((res) => {
        // console.log("兴趣标签列表", res);
        if (res.code == 1) {
          this.interestList = res.data;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.enter_applyFor {
  padding: 30px 20px;

  .enter_applyForBtn {
    width: 176px;
    height: 40px;
    margin-top: 22px;
    color: #333;
    background: #ffdd00;
    border-radius: 8px 8px 8px 8px;
  }
}
.gonghuiListTop1 {
  width: 400px;

  margin: 20px auto;
}
.gonghuiTitleFlex {
  display: flex;
  align-items: center;
}
.gonghuiContentFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 8px 16px 12px 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.gonghuiContent {
  width: 462px;
  height: 370px;
  background: #f7f7f9;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 0 auto;
  overflow: auto;
}
.gonghuiListTop {
  display: flex;
  align-items: center;
}
.inputBtn {
  width: 290px;
  height: 36px;
  background: #f2f2f2;
  border-radius: 18px 18px 18px 18px;
  opacity: 1;
  padding-left: 16px;
}
.apllyBtn {
  width: 88px;
  height: 36px;
  background: #ffdd00;
  border-radius: 18px 18px 18px 18px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #212121;
  line-height: 36px;
  text-align: center;
  margin-left: 4px;
}
.protocolsText {
  width: 522px;
  height: 300px;
  overflow-y: scroll;
  margin: 12px;
}
//选择器
.cascader_box {
  position: relative;
  .selector_cascader {
    height: 40px;
    padding: 0 0 0 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    color: #000;
  }
  .cascader_icon {
    width: 6px;
    height: 10px;
    margin: 0 10px;
  }
  .cascaderItemBox {
    position: absolute;
    top: 50px;
    height: 190px;
    background-color: #f5f5f5;
    z-index: 1;
    border-radius: 4px;
    color: #000;
    .father_box {
      height: 190px;
      overflow-y: scroll;
      .father_item {
        height: 25px;
        min-width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;
      }
    }
  }
}

//黑名单
.llacingCont_table {
  width: 680px;
  .blacklist_head {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .llacingCont_td {
    height: 50px;
  }
  .llacingCont_trTow {
  }
}
.noData {
  width: 680px;
  height: 90px;
  display: flex;
  color: #757474;
  align-items: center;
  justify-content: center;
  border: 1px solid #363659;
}

//日历
.calendar_box {
  width: 100%;
  color: #333333;
  margin-top: 70px;
  .calendar_title {
    width: 380px;
    display: flex;
    font-size: 19px;
    font-weight: 500;
    justify-content: flex-start;
  }
  .halving_line {
    width: 340px;
    height: 0px;
    margin-top: 17px;
    border: 1px dashed #5997fc;
  }
  .calendar_titleItem {
    width: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .calendar_content {
    width: 380px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .dataItem {
      width: 54px;
      height: 32px;
      display: flex;
      font-weight: 500;
      align-content: center;
      justify-content: center;
      margin-top: 15px;
    }
    .dataItem_pitch {
      width: 32px;
      height: 32px;
      color: #5997fc;
      border: 1px solid #5997fc;
      border-radius: 50%;
    }
  }
  .calendar_btn {
    width: 370px;
    height: 53px;
    font-size: 21px;
    color: #ff2639;
    margin-top: 35px;
    background: #ffdb45;
    border-radius: 44px;
  }
}

.prompt_btn {
  width: 176px;
  height: 40px;
  font-size: 14px;
  margin: auto;
  margin-top: 41px;
  background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 4px;
}
// 旋转
.spin_longin {
  animation: spin 2s infinite;
}
@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}
//图片裁剪
.cropImg_box {
  width: 600px;
  height: 380px;
}
// 房间设置
.roomSet_box {
  width: 756px;
  height: 524px;
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  .setList_box {
    width: 344px;
    height: 434px;
    padding: 10px;
    font-size: 14px;
    background: #f5f5f5;
    box-sizing: border-box;
    border-radius: 4px;
    .setList_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .setList_12 {
        color: #8e92b1;
        font-size: 12px;
        margin-top: 4px;
      }
    }
    .item2 {
      padding: 10px 0 10px 10px;
      border-top: 1px solid #eee;
    }
  }
}
.when_icon {
  width: 16px;
  height: 10px;
  margin: auto 45px;
}
// 注销账号弹窗
.writeOff_box {
  width: 522px;
  padding: 0 20px;
  font-size: 14px;
  box-sizing: border-box;
  .writeOff_btn {
    width: 176px;
    height: 40px;
    margin: 109px auto 41px auto;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
    color: #000;
  }
  .writeOff_btn2 {
    width: 234px;
    height: 40px;
    margin: 0 auto;
    margin-top: 63px;
    background: #ff2a3e;
    border-radius: 4px;
  }
  .btn3 {
    margin-top: 17px;
    margin-bottom: 40px;
    background: #363659;
    border-radius: 4px;
  }
  .writeOff_cause {
    color: #8e92b1;
    margin: 16px 0;
    > img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
  .writeOff_icon {
    width: 72px;
    height: 72px;
    margin: 54px auto 30px auto;
  }
}
//每日打开
.signIn_box {
  width: 456px;
  height: 573px;
  background: url(../../image/my/1.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .sigIn_topIcon {
    width: 251px;
    height: 157px;
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translateX(-50%);
  }
  .signIn_titleBox {
    padding: 20px;
  }
  .sigIn_quit {
    width: 13px;
    height: 13px;
  }
  .signIn_text {
    font-size: 21px;
    font-weight: bold;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
}
//个人标签
.label_box {
  padding: 20px 50px 50px 50px;
  height: 400px;
  min-height: 200px;
  overflow-y: scroll;
  .label {
    width: 410px;
    margin-top: 20px;
    .label_item {
      width: 88px;
      height: 40px;
      font-size: 14px;
      color: #333;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      background: #f5f5f5;
      padding-top: 10px;
    }
    .label_path {
      color: #333;
      background-color: rgba(255, 221, 0, 1);
    }
    .label_imgItem {
      width: 88px;
      height: 90px;
      font-size: 12px;
      color: #8e92b1;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      > img {
        width: 40px;
        height: 40px;
        margin-bottom: 9px;
      }
    }
  }
  .labe_nextStep {
    width: 382px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
    color: #000;
  }
  .step_box {
    margin-top: 20px;
  }
  .suspendStyle {
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
  }
}
// 标签
.my_label {
  padding: 2px 8px;
  margin-right: 10px;
  color: #ffa700;
  font-size: 12px;
  background: rgba($color: #ffa700, $alpha: 0.1);
  border-radius: 4px;
}
//用户资料
.user_box {
  height: 670px;
  overflow-y: scroll;
  color: rgba(54, 54, 89, 1);
  font-size: 14px;
  box-sizing: border-box;
  .edit_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    > img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .user_top {
    width: 100%;
    height: 106px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    .uploadHead_box {
      width: 88px;
      height: 88px;
      margin-right: 16px;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      .uploadHead_bg {
        width: 88px;
        height: 88px;
        background: rgba($color: #000000, $alpha: 0.7);
        position: absolute;
        z-index: 99;
        text-align: center;
        line-height: 88px;
      }
      .uploadHead_img {
        width: 88px;
        height: 88px;
        position: absolute;
        object-fit: fill;
      }
      .uploadHead_icon {
        width: 26px;
        height: 26px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 99;
        background: url(../../static/65.png) no-repeat;
      }
    }
    .signInBox {
      width: 88px;
      height: 28px;
      margin: 43px 30px 0 0;
      background: linear-gradient(114deg, #ffa700 0%, #ffdd00 100%);
      border-radius: 14px;
    }
    .icon_box {
      padding: 2px 8px;
      margin-right: 8px;
      background: #f7f7f9;
      border-radius: 11px;
    }
    .topup_btn {
      width: 48px;
      height: 20px;
      font-size: 12px;
      color: #fff;
      margin-right: 10px;
      background: linear-gradient(90deg, #ff2a3e 0%, #ff7268 100%);
      border-radius: 10px 10px 10px 0px;
    }
  }
  .user_left {
    margin-right: 10px;
  }
  .user_title {
    margin: 16px 0 9px 0;
  }
  .user_title:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 10px;
    margin-right: 4px;
    background: linear-gradient(180deg, #ffa700 0%, #ffdd00 100%);
    border-radius: 0px 2px 2px 0px;
  }
  .headline {
    width: 80px;
    height: 20px;
    color: #8e92b1;
    text-align: right;
    margin-right: 10px;
  }
  .closeAn {
    color: rgba(255, 42, 62, 1);
    margin: 16px 0 9px 0;
    text-align: end;
  }
  .empty {
    height: 19px;
    margin: 16px 0 9px 0;
  }
  .userData_box {
    width: 470px;
    height: 338px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 10px;
    position: relative;
    box-sizing: border-box;
  }
  .long_box {
    width: 470px;
    height: 71px;
    padding: 0 20px 0 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-sizing: border-box;
    .computer_icon {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
  }
  .photoWall_box {
    width: 470px;
    height: 338px;
    // padding: 0 10px;
    flex-wrap: wrap;
    background: #ffffff;
    border-radius: 8px;
    padding-bottom: 85px;
    box-sizing: border-box;
    overflow-y: auto;
    .photoWall_img {
      width: 150px;
      height: 150px;
    }
    .uploadPhotoWall_img {
      width: 150px;
      height: 150px;
      margin-left: 5px;
      margin-top: 10px;
      border-radius: 8px;
    }
    .del_img {
      position: absolute;
      top: 15px;
      right: 10px;
    }
    .img_box {
      width: 150px;
      height: 150px;
      position: relative;
      margin-left: 5px;
      margin-top: 10px;
      border-radius: 8px;
      overflow: hidden;
    }
    .img_auditStatus {
      width: 150px;
      height: 150px;
      color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba($color: #000000, $alpha: 0.7);
    }
  }
}
//弹窗
.popUp_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-19%, -10%);
  background: rgba($color: #161722, $alpha: 0.7);
  z-index: 99;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
}
.box1 {
  background: rgba($color: #161722, $alpha: 0.7);
}
//编辑弹窗
.edit_data {
  width: 400px;
  margin: 0 200px;
  color: #fff;
  margin-top: 20px;
}
//登录密码弹窗
.password_data {
  margin: 31px 100px 69px 100px;
}
.schedule_item {
  color: #77d769;
  font-size: 12px;
  .schedule_icon {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-bottom: 6px;
    background-color: #77d769;
    border-radius: 50%;
  }
}

//实名认证弹窗
.realName_data {
  width: 400px;
  margin: 0 auto;
}
.upload_img {
  width: 139px;
  height: 90px;
  margin-right: 15px;
  border-radius: 8px;
}
/deep/.el-upload--picture-card {
  height: 90px;
  background-color: transparent;
  border: 0;
}
//声音认证弹窗
.soundProperties {
  font-size: 16px;
  padding: 40px 60px;
  .wordage_select {
    margin: 30px 0 40px 0;
  }
  .wordage_box {
    width: 422px;
    height: 146px;
    background: url(../../static/124.png);
    color: #000000;
    .wordage_text {
      width: 364px;
      height: 168px;
      padding: 0 23px 0 32px;
      background: linear-gradient(360deg, #ffa700 0%, #ffdd00 100%);
      border-radius: 16px;
      box-sizing: border-box;
    }
    .text_animation {
      animation: rightVanish 1s infinite;
      animation-iteration-count: 1;
    }
    .text_animation2 {
      animation: leftVanish 1s infinite;
      animation-iteration-count: 1;
    }
  }
  @keyframes rightVanish {
    0% {
    }
    100% {
      opacity: 0;
      transform: translateX(25%);
    }
  }
  @keyframes leftVanish {
    0% {
    }
    100% {
      opacity: 0;
      transform: translateX(-25%);
    }
  }
  .wordage_icon {
    width: 29px;
    height: 29px;
  }
  .audio_upload {
    margin-bottom: 40px;
    .audio_file {
      width: 193px;
      height: 36px;
      padding: 8px 10px;
      font-size: 14px;
      justify-content: space-between;
      background: #262b43;
      border-radius: 4px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .file_select {
      width: 114px;
      height: 36px;
      margin: 0 10px 0 20px;
      background: rgba(255, 221, 0, 1);
      border-radius: 4px;
      color: #000000;
    }
  }
  .label_boxs {
    width: 600px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 10px;
    margin-bottom: 8px;
    overflow-y: scroll;
    .label_unselected {
      padding: 8px 23px;
      color: #8e92b1;
      font-size: 14px;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      border: 1px solid #262b43;
    }
    .label_pitch {
      padding: 8px 23px;
      color: #fff;
      font-size: 14px;
      margin: 0 10px 10px 0;
      background: #363659;
      border-radius: 4px;
    }
  }
  .audio_submit {
    width: 320px;
    height: 40px;
    color: #333;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.soundProperties_submitted {
  margin: 40px 173px;
  .roger {
    width: 176px;
    height: 40px;
    color: #333;
    font-size: 14px;
    margin-top: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
//微信解绑提醒
.solutionRemind_box {
  padding: 54px 75px 41px 75px;
  .confirm_unbundling {
    width: 176px;
    height: 40px;
    margin-top: 55px;

    background: rgba(255, 167, 0, 0.2);
    border-radius: 4px;

    color: #ffa700;
  }
  .think_gain {
    width: 176px;
    height: 40px;
    margin-top: 55px;
    margin-right: 20px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.user_textarea /deep/.el-textarea__inner {
  color: #333;
}
.form_btn {
  width: 100%;
  border: 0;
  background: rgba(255, 221, 0, 1);
  color: #000;
}
.radio_icon {
  width: 14px;
  height: 14px;
}
.textOver {
  width: 200px;
  display: -webkit-box; /* Safari */
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2; /* 限制最大行数为2行 */
  -webkit-line-clamp: 2; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari */
}
.guangContent {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #212121;
}
/deep/.el-form-item__label {
  color: #333;
}
/deep/.el-input__inner {
  color: #333;
  background-color: #f5f5f5 !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(255, 221, 0, 1) !important;
  background: rgba(255, 221, 0, 1) !important;
}
/deep/.el-textarea__inner {
  border: 0;
  background-color: #f5f5f5;
  color: #000;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
/deep/.el-input__inner::placeholder {
  color: #333 !important;
}
//标题背景颜色
/deep/.el-table th {
  background-color: rgba($color: #363659, $alpha: 1) !important;
  color: #333;
}
//内容背景颜色
/deep/.el-table tr {
  background-color: rgba($color: #1d223b, $alpha: 1) !important;
}
.upload_soundRecording /deep/.el-upload--picture-card {
  width: 150px;
  height: 30px;
  background-color: transparent;
  border: 0;
}
</style>
