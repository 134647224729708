<template>
  <div>
    <!-- 个人中心--我的钱包 -->
    <div class="top_box">
      <div class="top_left">
        <div>
          <div>可用元宝<img src="../../static/195.png" alt="" style="margin-left:4px" /></div>
          <div class="font-28 text-cut" style="width: 120px;">{{ mydiamond.diamondCost || 0}}</div>
        </div>
        <div>
          <div>冻结元宝<img src="../../static/195.png" alt="" style="margin-left:4px" /></div>
          <div class="font-28 text-cut" style="width: 120px;">{{ mydiamond.frozenCost || 0}}</div>
        </div>
        <div>
          <div>全部元宝<img src="../../static/195.png" alt="" style="margin-left:4px" /></div>
          <div class="font-28 text-cut" style="width: 120px;">{{ mydiamond.totalCost || 0 }}</div>
        </div>
        <div class="topUpBtn flex-content" @click="walletTopupShow = true">
          充值 <img src="../../static/62.png" alt="" class="padding_r8" />
        </div>
      </div>
      <div class="top_right flex-aling" style="justify-content: space-between">
        <div>
          <div>可用龙币<img src="../../static/196.png" alt="" style="margin-left:4px"/></div>
          <div class="font-28 text-cut" style="width: 75px;">{{ myCoin.number || 0}}</div>
        </div>
        <!-- <div>
          <div>冻结龙币<img src="../../static/81.png" alt="" /></div>
          <div class="font-18 text-cut" style="width: 75px;">{{ myCoin.freezeNum || 0 }}</div>
        </div>
        <div>
          <div>总龙币<img src="../../static/81.png" alt="" /></div>
          <div class="font-18 text-cut" style="width: 75px;">{{ myCoin.numberCount || 0 }}</div>
        </div> -->
        <div class="explain">
          说明<img
            src="../../static/41.png"
            alt=""
            @click="getAgreementData();explainShow = !explainShow"
          />
        </div>
        <div class="flex-content" style="position: relative;">
          <div style="color: #CF8234" v-if="isGiveGold == 1" class="presented_btn" @click.stop="presentedShow = true;getGiveGoldsProp()">赠送龙币</div>
          <div
            class="withdraw flex-content"
            @click="withdrawShow = true;getWithdrawData()"
            v-if="userInfo.isPlayer == 1"
          >
            提现
          </div>
          <div
            class="exchange_btn flex-content"
            @click="forDiamondShowChange()"
          >
            兑换元宝
          </div>
        </div>
      </div>
    </div>
    <!-- 钱包列表 -->
    <div class="bottom_box">
      <div class="flex-aling" style="justify-content: space-between">
        <div class="flex">
          <div
            class="tab_item"
            :class="tabType == 0 ? 'tab_pitch' : ''"
            @click="tabTypeChange(0)"
          >
            元宝明细
          </div>
          <div
            class="tab_item"
            :class="tabType == 1 ? 'tab_pitch' : ''"
            @click="tabTypeChange(1)"
          >
            龙币明细
          </div>
        </div>
        <div class="flex-content">
          <el-date-picker
            v-model="paging.date"
            type="date"
            style="color: #6666"
            class="timeInput"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="timePicker"
          ></el-date-picker>
          <div
            class="flex-content"
            :class="paging.type ? 'screen_btn1' : 'screen_btn'"
            @click="listTypeChange()"
          >
            全部
          </div>
          <div
            class="screen_btn1 flex-content"
            :class="paging.type == 1 ? 'screen_btn' : ''"
            @click="listTypeChange(1)"
          >
            收入
          </div>
          <div
            class="screen_btn1 flex-content"
            :class="paging.type == 2 ? 'screen_btn' : ''"
            @click="listTypeChange(2)"
          >
            支出
          </div>
        </div>
      </div>
      <!-- 元宝表格 -->
      <el-table
        :data="diamondList"
        border
        style="width: 100%"
        v-if="tabType == 0"
      >
        <el-table-column prop="createTime" label="时间" width="180">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" width="220">
          <template slot-scope="scope">
            <p>{{ scope.row.orderNo ? scope.row.orderNo : scope.row.id }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="billType" label="类型">
          <template slot-scope="scope">
            <p :style="scope.row.billType == '1'?'color:rgba(250, 85, 74, 1)':''">{{ scope.row.billType == "1" ? "收入 " : "支出" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="discounts" label="优惠"> </el-table-column>
        <el-table-column prop="payMoney" label="元宝">
          <template slot-scope="scope">
            <p :style="scope.row.billType == '1'?'color:rgba(250, 85, 74, 1)':''">{{ scope.row.sign + scope.row.payMoney }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="afterCost" label="元宝余额"> </el-table-column>
        <el-table-column prop="type" label="描述"
          ><template slot-scope="scope">
            <p>{{ diamondTypeList[scope.row.type] }}</p>
          </template>
        </el-table-column>
      </el-table>
      <!-- 龙币表格 -->
      <el-table :data="coinList" border style="width: 100%" v-if="tabType == 1">
        <el-table-column prop="createTime" label="时间" width="180">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" width="180">
          <template slot-scope="scope">
            <p>{{ scope.row.orderNo ? scope.row.orderNo : scope.row.id }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="billType" label="类型">
          <template slot-scope="scope">
            <p>{{ scope.row.billType == "1" ? "收入 " : "支出" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="changeGold" label="龙币"> </el-table-column>
        <el-table-column prop="afterGold" label="龙币余额"> </el-table-column>
        <el-table-column prop="type" label="描述">
          <template slot-scope="scope">
            <p>{{ coinTypeList[scope.row.type] }}</p>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="paging.total"
        class="paging"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <walletTopup
      v-if="walletTopupShow"
      @walletQuitChange="walletTopupShow = false"
      style="position: absolute; left: -25%; top: -20%"
    ></walletTopup>
    <!-- 赠送龙币弹窗 -->
    <div class="popup_box" v-if="presentedShow">
      <div class="withdrawal_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">赠送龙币</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="presentedShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="remind_text flex-content">
          {{
            `赠送转化比例：100龙币=￥${atRatio}，赠送龙币数必须是100的倍数`
          }}
        </div>
        <!-- 内容 -->
        <div class="content_box">
          <div class="text_titel">赠送用户ID</div>
          <div style="margin-bottom:20px;position: relative;">
              <img src="@/image/room/77.png" alt="" style="position: absolute;z-index: 1;right: 10px;top: 10px;" @click.stop="selectUserChange()">
              <el-input
                class="remind"
                v-model="userIDInput"
                placeholder="请输入赠送ID"
                @input="getUserByUserNo()"
              ></el-input>
          </div>
          <div v-if="searchData && searchData.nickname">
            赠送用户：{{  searchData.nickname }}
          </div>
          <div class="text_titel">赠送龙币数</div>
          <el-input
            class="remind"
            v-model="goldNum"
            :placeholder="'当前可赠送龙币' + myCoin.number"
            @input="goldInput()"
          ></el-input>
          <div
            class="font-12"
            style="color: #ff2a3e; margin: 10px 0"
            v-if="myCoin.number < 100"
          >
            龙币不足100，无法赠送
          </div>
          <div
            style="background: #6a6a94; width: 320px; height: 1px"
            v-if="myCoin.number < 100"
          ></div>
          <div class="text_titel">对方获赠元宝数</div>
          <div class="flex" style="align-items: end">
            <b style="font-size:24px;margin-right:4px">{{diamondsNum}}</b>
            <img src="../../static/61.png" alt="" />
          </div>
          <div class="font-12" style="color: #8e92b1;margin-top:10px">
            赠送龙币给指定用户，对方可获得对应比例元宝
          </div>
          <!-- <div class="withdraw_btn flex-content" @click="presentedgold()"> -->
            <div class="withdraw_btn flex-content" @click="goldAffirmShow = true">
            确认赠送
          </div>
        </div>
      </div>
    </div>
    <!-- 选择赠送用户 -->
    <div class="popUp_box" v-if="selectUserShow" style="top: -10%;left: -30%;z-index:9">
      <div class="pop_up" style="width: 440px;height: 480px;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">选择用户</div>
          <img src="@/static/quit.png" alt="" @click="selectUserShow = false" />
        </div>
        <!-- 头部end -->
        <div style="padding:40px 60px">
          <div class="flex">
              <el-select v-model="roleType" placeholder="请选择" class="roleSlect" style="height:39px" @change="getFollowOrFansList()">
              <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              type="text"
              placeholder="请输入用户ID"
              v-model="userNoData"
              @input="getFollowOrFansList()"
              class="userIdinput_type"
            ></el-input>
            
          </div>
        
          <!-- 列表 -->
          <div style="margin-top:30px" class="user_box">
            <div class="flex-spacebetween user_item" v-for="(item,index) in userList" :key="index" @click="pitchUserChange(item)">
              <div class="flex">
                <img :src="item.avatar" alt="" class="head_style">
                <div class="">
                  <div class="flex-aling">
                    <div>{{item.nickname}}</div>
                    <div class="icon_style flex-content" v-if="roleType == 0">粉丝</div>
                  </div>
                  <div style="color:#6A6A94;font-size:10px">ID：{{item.userNo}}</div>
                  </div>
                </div>
              <div>选择</div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 龙币赠送提醒弹窗 -->
    <div class="popup_box" v-if="goldAffirmShow">
      <div class="prompt_box" style="height: 293px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="goldAffirmShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <div class="font-14" style="margin-top: 40px;color:#333">是否确认赠送</div>
          <div class="padding_10 font-12" style="color: #8e92b1">
          操作不可逆
          </div>
        </div>
        <div class="flex-content" style="margin-top: 30px">
          <div
            class="cash_btn flex-content"
            style="margin-right: 20px; background: #fff;border:1px solid rgba(255, 221, 0, 1)"
            @click="goldAffirmShow = false"
          >
            我再想想
          </div>
          <div class="cash_btn flex-content" @click="presentedgold()">
            立即赠送
          </div>
        </div>
      </div>
    </div>
    <!-- 龙币提现弹窗 -->
    <div class="popup_box" v-if="withdrawShow">
      <div class="withdrawal_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">龙币提现</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="withdrawShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="remind_text flex-content">
          {{
            `100龙币=￥${
              100 * withdrawRate.serviceChargeRatio
            }，兑换龙币数必须是100的倍数`
          }}
        </div>
        <div class="remind_text2">
          提现规则<img
            src="../../static/41.png"
            alt=""
            @click="remindPopupShow = !remindPopupShow;getwithdrawText()"
          />
          <div class="remind_popup" v-if="remindPopupShow" v-html="withdrawText">
          </div>
        </div>
        <!-- 内容 -->
        <div class="content_box">
          <div class="text_titel">提现龙币数</div>
          <el-input
            class="remind"
            v-model="withdrawCoin"
            :placeholder="`当前可兑换龙币${myCoin.number ? myCoin.number:0}`"
            @input="withdrawCoinInput()"
            maxlength="6"
          ></el-input>
          <div
            class="font-12"
            style="color: #ff2a3e; margin: 10px 0"
            v-if="myCoin.number < 100"
          >
            龙币不足100，无法兑换
          </div>
          <div
            style="background: #6a6a94; width: 320px; height: 1px"
            v-if="myCoin.number < 100"
          ></div>
          <div class="text_titel">提现账户</div>
          <!-- <div class="single_item flex-spacebetween">
            <div class="flex-aling">
              <img
                src="../../image/my/12.png"
                alt=""
                style="margin-right: 5px"
              />
              支付宝
            </div>
            <img
              src="../../static/107.png"
              alt=""
              style="width: 14px; height: 14px"
              v-if="withdrawalWay == 1"
            />
            <img
              src="../../static/106.png"
              alt=""
              style="width: 14px; height: 14px"
              v-else
              @click="withdrawalWay = 1"
            />
          </div> -->
          <!-- <div class="single_item flex-spacebetween">
            <div class="flex-aling">
              <img
                src="../../image/my/11.png"
                alt=""
                style="margin-right: 5px"
              />
              微信
            </div>
            <img
              src="../../static/107.png"
              alt=""
              style="width: 14px; height: 14px"
              v-if="withdrawalWay == 2"
            />
            <img
              src="../../static/106.png"
              alt=""
              style="width: 14px; height: 14px"
              @click="withdrawalWay = 2"
              v-else
            />
          </div> -->
          <div class="single_item flex-spacebetween">
            <div class="flex-aling">
              <img
                src="../../static/184.png"
                alt=""
                style="margin-right: 5px"
              />
              <span>银行卡 </span>
              <span style="margin-left:6px">{{withdrawRate.bankCardNo}}</span>
            </div>
            <img
              src="../../static/107.png"
              alt=""
              style="width: 14px; height: 14px"
            />
            <!-- v-if="withdrawalWay == 3" -->
            <!-- <img
              src="../../static/106.png"
              alt=""
              style="width: 14px; height: 14px"
              v-else
              @click="withdrawalWay = 3"
            /> -->
          </div>
          <div class="font-12" style="color: #8e92b1">
            提示：银行卡账户为实名认证填写银行卡信息，如需变更，请联系平台客服
          </div>
          <div class="flex-aling"  style="margin-top:24px;justify-content: center;">
            <img
              src="../../static/107.png"
              alt=""
              v-if="whetherRead"
              style="width: 14px; height: 14px"
              @click="whetherRead = !whetherRead"
            />
            <img
              src="../../static/106.png"
              alt=""
              v-else
              style="width: 14px; height: 14px"
              @click="whetherRead = !whetherRead"
              />
            <span style="color:#212121;font-size:12px;margin:0 2px">已阅读并同意</span>
            <span style="margin-left:4px;font-size:12px;color:#8458f4" @click.stop="flexibleChange()">《灵活就业合作伙伴协议》</span>
          </div>
          <div class="withdraw_btn flex-content" @click="ImmediateWithdrawal()" style="margin-top:8px">
            立即提现
          </div>
          <div
            class="font-12 flex-content"
            style="color: #8e92b1"
            @click="getQueryWithdraws()"
          >
            提现记录
          </div>
        </div>
      </div>
    </div>
    <!-- 龙币兑换元宝弹窗 -->
    <div class="popup_box" v-if="forDiamondShow">
      <div class="withdrawal_box" style="height: 400px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">兑换元宝</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="forDiamondShow = false;cashCoin='';cashDiamond=0"
          />
        </div>
        <!-- 头部end -->
        <div class="remind_text flex-content">
          {{ `100龙币=${ratio}元宝，兑换龙币数必须是100的倍数` }}
        </div>

        <!-- 内容 -->
        <div class="content_box">
          <div class="flex-aling" style="justify-content: space-between">
            <div class="text_titel">兑换龙币数</div>
            <div class="all_btn" @click="cashCoin = myCoin.number;cashChange()">
              兑换全部
            </div>
          </div>
          <el-input
            class="remind"
            v-model="cashCoin"
            @input="cashChange()"
            :placeholder="`当前可兑换龙币${myCoin.number || 0}`"
          ></el-input>
          <div
            class="font-12"
            style="color: #ff2a3e; margin: 10px 0"
            v-if="myCoin.number < 100"
          >
            龙币不足100，无法兑换
          </div>
          <div
            style="background: #6a6a94; width: 320px; height: 1px"
            v-if="myCoin.number < 100"
          ></div>
          <div class="text_titel">可兑换元宝数</div>
          <div class="flex" style="align-items: flex-end">
            <div style="font-size: 24px; font-weight: bold;color:#333">
              {{ cashDiamond || 0 }}
            </div>
            <img
              src="../../static/user3.png"
              alt=""
              style="width: 16px; height: 16px; margin: 0 0 5px 5px"
            />
          </div>
          <div class="font-12" style="color: #8e92b1">
            元宝可用于购买陪玩服务、礼物、道具等，不可提现
          </div>
          <div class="withdraw_btn flex-content" @click="cashClick()">
            兑换元宝
          </div>
        </div>
      </div>
    </div>
    <!-- 提醒弹窗 -->
    <div class="popup_box" v-if="promptShow">
      <div class="prompt_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">龙币提现</div>
          <img src="../../static/quit.png" alt="" @click="promptShow = false" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <img src="../../static/128.png" alt="" style="margin-top: 40px" />
          <div
            class="flex-content"
            style="color: #77d769; margin: 20px 0 10px 0"
          >
            已提交
          </div>
          <div class="flex-content font-12" style="color: #8e92b1">
            提现申请已提交，等待平台审核通过打款
          </div>
          <div class="prompt_btn flex-content" @click="promptShow = false">
            完成
          </div>
        </div>
        <!-- 内容 -->
      </div>
    </div>
    <!-- 兑换提醒弹窗 -->
    <div class="popup_box" v-if="cashpromptShow">
      <div class="prompt_box" style="height: 293px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="cashpromptShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <div class="font-14" style="margin-top: 40px">是否确认兑换元宝</div>
          <div class="padding_10 font-12" style="color: #8e92b1">
            一经兑换 操作不可逆
          </div>
          <div class="flex" style="align-items: flex-end">
            <div style="font-size: 24px; font-weight: bold; color: #ffdba3">
              {{ cashDiamond }}
            </div>
            <img
              src="../../static/user3.png"
              alt=""
              style="width: 16px; height: 16px; margin: 0 0 5px 5px"
            />
          </div>
        </div>
        <div class="flex-content" style="margin-top: 30px">
          <div
            class="cash_btn flex-content"
            style="margin-right: 20px; background: #fff"
            @click="cashpromptShow = false"
          >
            我再想想
          </div>
          <div class="cash_btn flex-content" @click="immediateCash()">
            立即兑换
          </div>
        </div>
      </div>
    </div>
    <!-- 提现记录弹窗 -->
    <div class="popup_box" v-if="withdrawalRecordShow">
      <div class="prompt_box" style="height: 293px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">龙币提现</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="withdrawalRecordShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="llacingCont_table">
          <!-- 头部表头 -->
          <div class="flex-aling llacingCont_tr">
            <div
              v-for="(hederItem, key0) in placingheader"
              :key="key0"
              class="llacingCont_heder flex-content"
            >
              {{ hederItem }}
            </div>
          </div>
          <!-- 下单内容 -->
          <!-- <div class="flex-aling llacingCont_tr">
            <div class="llacingCont_td flex-content">剑来</div>
            <div class="llacingCont_td flex-content">英雄联盟</div>
            <div class="llacingCont_td flex-content">200</div>
            <div class="llacingCont_td flex-content">20,000</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 钱包说明弹窗 -->
    <div class="popup_box" v-if="explainShow">
      <div class="prompt_box" style="height: auto;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="explainShow = false"
          />
        </div>
        <!-- 头部end -->
        <div v-html="explainText" class="textBox">
        </div>
      </div>
    </div>
    <!-- 《灵活就业合作伙伴协议》弹窗 -->
    <div class="popup_box" v-if="flexibleShow">
      <div class="prompt_box" style="height: auto;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">灵活就业合作伙伴协议</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="flexibleShow = false"
          />
        </div>
        <!-- 头部end -->
        <div v-html="flexibleText" class="textBox">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import walletTopup from "./walletTopup.vue"; //充值弹窗

export default {
  name: "",
  components: { walletTopup },
  data() {
    return {
      whetherRead:false,//是否阅读协议
      flexibleShow:false,//灵活就业协议弹窗
      flexibleText:'',//灵活就业协议
      value1: "",
      tabType: 0,
      withdrawCoin: "",
      withdrawalWay: 1, //提现方式
      presentedShow:false,//龙币赠送
      withdrawalRecordShow: false, //提现记录
      explainShow:false,//说明弹窗
      placingheader: ["时间", "订单号", "状态", "龙币", "描述"],
      tableData: [
        {
          time: "2022-06-14 17:57", //时间
          orderNumber: "202206251909121234", //订单号
          type: "收入", //类型
          discounts: "0", //优惠
          diamond: "+800", //元宝
          diamondBalance: "900", //元宝余额
          describe: "陪玩服务退款", //描述
        },
      ],
      diamondList: [], //元宝列表
      coinList: [], //龙币列表
      diamondTypeList: {
        0: "拒绝接单退款",
        1: "支付宝充值",
        2: "微信充值",
        3: "他人赠送龙币转元宝",
        4: "龙币兑换元宝",
        5: "赠送礼物",
        6: "购买道具",
        7: "购买订单服务",
        8: "世界喇叭",
        9: "购买盲盒",
        // 10: "购买盲盒",
        11: "点唱",
        12: "赠送道具",
        13: "其他活动（系统赠送）",
        14: "赠送（打赏）盲盒",
      }, //元宝消费类型
      coinTypeList: {
        0: "魅力值兑换龙币",
        1: "订单收入",
        // 2: "",
        3: "赠送龙币",
        4: "龙币兑换元宝",
        5: "龙币提现",
      }, //龙币消费类型
      walletTopupShow: false, //充值弹窗
      withdrawShow: false, //提现弹窗
      mydiamond: {}, //我的元宝
      myCoin: {}, //我的龙币
      paging: {
        total: 0,
        pageNum: 1,
        pageSize: 8,
        date: "",
        type: "", //类别，1收入，2支出,空值查询全部
      },
      remindPopupShow: false, //提现规则
      promptShow: false, //提醒弹窗
      forDiamondShow: false, //兑换元宝弹窗
      cashCoin: "", //龙币输入数量
      cashDiamond: 0, //兑换元宝数量
      cashpromptShow: false, //兑换提醒
      ratio: 0, //龙币兑换元宝比例
      userInfo: {}, //用户信息
      withdrawRate: {}, //提现比例
      recordLsit: [], //提现记录
      isGiveGold: 0, //1：可赠送龙币，0.不可=赠送
      atRatio:0,//赠送龙币转化元宝比例
      selectUserShow:false,//选择用户弹窗
      userList:[],//赠送用户列表
      roleType:1,//列表类型 1关注 0粉丝
      searchData:{},//搜索出的用户
      goldNum:'',//输入龙币数
      goldAffirmShow:false,//龙币赠送提醒弹窗
      diamondsNum:0,//赠送元宝数
      listOptions:[
        {
          value:1,
          label:'关注'
        },{
          value:0,
          label:'粉丝'
        }
      ],
      userIDInput:'',//用户id
      userNoData:'',//用户列表搜索
      explainText:'',//说明内容
      withdrawText:'',//提现说明
    };
  },
  mounted() {
    this.queryAccount();
    this.queryByMyGold();
    this.queryByMyDiamond();
    this.userDiamondAccountBillList();
    this.goldPageList();
    this.getInfo();
    this.getWithdrawData();
  },
  methods: {
    // 灵活就业协议
    flexibleChange(){
      this.$api.getAgreement({configName:'agreementMessage'}).then(res=>{
        if(res.code == 1){
          this.flexibleText = res.data
          this.flexibleShow = true
        }
      })
    },
    // 提现说明
    getwithdrawText(){
      this.$api.getAgreement({configName:'withdrawRule'}).then(res=>{
        if(res.code == 1){
          this.withdrawText = res.data
        }
      })
    },
    // 获取钱包说明
    getAgreementData(){
      this.$api.getAgreement({configName:'walletRule'}).then(res=>{
        if(res.code == 1){
          this.explainText = res.data
        }
      })
    },
    goldInput(){
      let proportion = Number(this.atRatio) /100
      if(this.goldNum > 99){
        this.diamondsNum = this.goldNum * proportion
      }else{
        this.diamondsNum = 0
      }
    },
    // 赠送用户龙币
    presentedgold(){
      // goldNums	赠送龙币数
      //targetUserId	目标用户id
      let data = {
        goldNums:this.goldNum,
        targetUserId:this.userIDInput
      }
      if(this.goldNum < 100) return this.$message.info('最少赠送100龙币')
      if(!this.userIDInput) return this.$message.info('请输入用户ID')
      if (!this.isInteger(this.goldNum / 100)) return this.$message.info("赠送龙币数必须是100的倍数");
      this.$api.giveGolds(data).then(res=>{
        if(res.code == 1){
          this.presentedShow = false 
          this.goldAffirmShow = false
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 根据编号查询用户
    getUserByUserNo(){
        let timer = null
        if(timer){
          clearTimeout(timer)
        }
        timer = setTimeout(()=>{
          this.$api.getUserByUserNo({userNo:this.userIDInput}).then(res=>{
          if(res.code == 1){
            this.searchData = res.data
            }
          })
        }, 1000)
          
    },
    // 选择用户
    pitchUserChange(i){
      this.pitchData  = i 
      this.userIDInput = i.userNo
      this.getUserByUserNo()
      this.selectUserShow = false
    },
    // 选择用户弹窗
    selectUserChange(){
      this.getFollowOrFansList()
      this.selectUserShow = true
    },
    // 获取粉丝列表&&关注列表
    getFollowOrFansList(){
      let data = {
        pageNum:1,
        pageSize:'999',
        type:this.roleType,//	类型0-粉丝 1-关注
        userNo:this.userNoData,//	关键字
      }
      this.$api.followOrFansList(data).then(res=>{
        // console.log('列表',res);
        if(res.code == 1){
          this.userList = res.data.rows
        }
      })
    },
    // 获取赠送龙币转化元宝比例
    getGiveGoldsProp(){
      this.$api.giveGoldsProp().then(res=>{
        if(res.code == 1){
          this.atRatio = res.data
        }
      })
    },
    // 是否可以赠送龙币
    getIsRoomwhitelist() {
      this.$api.isRoomwhitelist({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.isGiveGold = res.data.isGiveGold;
        }
      });
    },
    // 提现记录
    getQueryWithdraws() {
      this.$api.queryWithdraws().then((res) => {
        if (res.code == 1) {
          this.recordLsit = res.data;
          this.withdrawalRecordShow = true;
        }
      });
    },
    // 获取提现比例与规则
    getWithdrawData() {
      this.$api.withdrawData().then((res) => {
        if (res.code == 1) {
          this.withdrawRate = res.data;
        }
      });
    },
    // 获取用户信息
    getInfo() {
      this.$api.queryUser({ otherUserId: this.myId }).then((res) => {
        // console.log("总个人信息", res);
        if (res.code == 1) {
          this.userInfo = res.data;
          this.getIsRoomwhitelist();
        }
      });
    },
    // 显示龙币兑换元宝
    forDiamondShowChange() {
      this.$api.goldsToDiamondsProp().then((res) => {
        if (res.code == 1) {
          this.ratio = res.data;
          this.forDiamondShow = true;
        }
      });
    },
    // 立即提现
    ImmediateWithdrawal() {
      if (!this.withdrawCoin) return this.$message.info("请输入提现数量");
      if (!this.isInteger(this.withdrawCoin / 100))
        return this.$message.info("提现数必须是100的倍数");
      // if (this.withdrawRate.bankIsBind == 0)
      //   return this.$message.info("请先绑定银行卡");
      if(!this.whetherRead) return this.$message.info('请先阅读协议')
      this.$api
        .withdrawApply({
          // type: this.withdrawalWay,
          type: "3", //暂时只支持银行卡
          golds: this.withdrawCoin,
        })
        .then((res) => {
          if (res.code == 1) {
            this.withdrawShow = false;
            this.promptShow = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    withdrawCoinInput() {
      if (this.withdrawCoin > this.myCoin.number) {
        this.withdrawCoin = this.myCoin.number;
      }
    },
    // 立即兑换元宝
    immediateCash() {
      //diamonds 元宝数量	   golds 龙币数量
      this.$api
        .diamondsChange({ diamonds: this.cashDiamond, golds: this.cashCoin })
        .then((res) => {
          if (res.code == 1) {
            this.cashpromptShow = false;
            this.forDiamondShow = false;
            this.cashCoin = ''
            this.cashDiamond = 0
            this.queryByMyDiamond();
            this.queryByMyGold();
            this.$message.success(res.msg);
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // 是否为整数
    isInteger(obj) {
      return obj % 1 === 0;
    },
    // 显示二次确认
    cashClick() {
      if (!this.cashCoin) return this.$message.info("请输入兑换数量");
      if (!this.isInteger(this.cashCoin / 100))
        return this.$message.info("兑换龙币数必须是100的倍数");
      this.cashpromptShow = true;
    },
    // 龙币输入
    cashChange() {
      let ratioNum = this.ratio / 100;
      if (this.cashCoin < this.myCoin.number) {
        this.cashDiamond = this.cashCoin * ratioNum;
      } else {
        this.cashCoin = this.myCoin.number;
        this.cashDiamond = this.cashCoin * ratioNum;
        return;
      }
    },
    // 时间选择
    timePicker(e) {
      // console.log(this.paging, e);
      this.getList();
    },
    // 列表状态切换
    listTypeChange(type) {
      if (type == 1) {
        this.paging.type = 1;
      } else if (type == 2) {
        this.paging.type = 2;
      } else {
        this.paging.type = "";
      }
      this.getList();
    },
    // 获取列表
    getList() {
      if (this.tabType == 0) {
        this.userDiamondAccountBillList();
      } else {
        this.goldPageList();
      }
    },
    //tab切换
    tabTypeChange(type) {
      this.tabType = type;
      this.paging.pageNum = 1;
      this.getList();
    },
    // 分页切换
    handleCurrentChange(val) {
      this.paging.pageNum = val;
      this.getList();
    },
    // 我的钱包-元宝
    queryByMyDiamond() {
      this.$api.queryByMyDiamond().then((res) => {
        if (res.code == 1) {
          this.mydiamond = res.data;
        }
      });
    },
    //我的钱包-龙币
    queryByMyGold() {
      this.$api.queryByMyGold().then((res) => {
        // console.log("我的钱包-龙币", res);
        if (res.code == 1) {
          this.myCoin = res.data;
        }
      });
    },
    //龙币账户记录表-分页列表查询
    goldPageList() {
      this.$api.goldPageList(this.paging).then((res) => {
        // console.log("龙币分页列表查询", res);
        if (res.code == 1) {
          this.coinList = res.data.rows;
          this.paging.total = res.data.total;
        }
      });
    },
    // 元宝账户记录表-分页列表查询
    userDiamondAccountBillList() {
      this.$api.userDiamondAccountBillList(this.paging).then((res) => {
        // console.log("元宝分页查询", res);
        if (res.code == 1) {
          this.diamondList = res.data.rows;
          this.paging.total = res.data.total;
        }
      });
    },
    walletQuitChange() {
      this.walletTopupShow = !this.walletTopupShow;
    },
    // 钱包详情
    queryAccount() {
      this.$api.queryAccount().then((res) => {
        // console.log("钱包详情", res);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.textBox{
  width: 100%;
  height: 400px;
  padding: 12px;
  box-sizing: border-box;
  overflow-y: scroll;
  color:#000
}
.explain{
  position: absolute;
  right: 4%;
  top: 9.5%;
  font-size: 12px;
  display: flex;
  align-items: center;
}
// 弹窗背景
.popup_box {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: -15%;
  left: -25%;
  z-index: 2;
}
.userIdinput_type{
  width: 200px;
}
.user_box{
  width: 320px;
  height: 300px;
  padding-right: 6px;
  overflow-y: scroll;
  box-sizing: border-box;
  .user_item{
    align-items: center;
    height: 55px;
    border-bottom: 1px solid #2f3050;
  }
}
.head_style{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
.icon_style{
  width: 32px;
  height: 16px;
  font-size: 8px;
  background: #FF3400;
  border-radius: 10px;
  margin-left: 5px;
}
.roleSlect{
  width: 106px;
  border-radius: 4px;
  margin: 0 21px 0 8px;
  border: 1px solid #363659;
}
.presented_btn{
  position: absolute;
  top: 40px;
  right: 25px;
}
.prompt_box {
  width: 522px;
  height: 346px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prompt_btn {
  width: 176px;
  height: 40px;
  font-size: 14px;
  margin: auto;
  margin-top: 41px;
  background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 4px;
}
.cash_btn {
  width: 176px;
  height: 40px;
  color: #333;
  font-size: 14px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
}
// 提现弹窗
.withdrawal_box {
  width: 440px;
  height: 560px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .remind_text {
    width: 440px;
    height: 26px;
    color: #212121;
    font-size: 12px;
    background: #E6E6E6;
  }
  .remind_text2 {
    margin: 10px;
    color: #666666;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .remind_popup {
      position: absolute;
      top: 30px;
      left: 120px;
      width: 325px;
      padding: 10px;
      color: #CF8234;
      background: #fff0d5;
      border-radius: 4px;
      z-index: 99;
      box-sizing: border-box;
    }
    .remind_popup::after {
      content: "";
      position: absolute;
      top: -10px;
      right: 25px;
      border-bottom: 10px solid #fff0d5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .content_box {
    margin: 0 60px;
  }
  .all_btn {
    color: #4177DD;
    font-size: 12px;
  }
  .text_titel {
    color: #212121;
    margin: 10px 0;
    font-size: 14px;
  }
  .single_item {
    width: 320px;
    height: 36px;
    align-items: center;
    font-size: 14px;
    padding: 0 10px;
    margin: 10px 0;
    color: #212121;
    background: #F5F5F5;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .withdraw_btn {
    width: 320px;
    height: 40px;
    color: #212121;
    font-size: 14px;
    margin: 37px 0 20px 0;
    background: #FFDD00;
    border-radius: 4px;
  }
}

.top_box {
  display: flex;
  color: #363659;
  font-size: 14px;
  .top_left {
    width: 681px;
    height: 96px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    margin-right: 10px;
    border-radius: 8px;
    box-sizing: border-box;
    .topUpBtn {
      width: 95px;
      height: 36px;
      color: #fff;
      font-size: 16px;
      background: linear-gradient(90deg, #FF2A3E 0%, #FF7268 100%);
      border-radius: 20px;
    }
  }

  .top_right {
    width: 281px;
    height: 96px;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    .withdraw {
      width: 95px;
      height: 36px;
      color: #fff;
      margin-left: 20px;
      background: linear-gradient(100deg, #2083F7 0%, #80C9FF 100%);
      border-radius: 18px;
    }
    .exchange_btn {
      width: 104px;
      height: 36px;
      color: #fff;
      margin-left: 10px;
      background: linear-gradient(111deg, #F49F00 0%, #F7D519 100%);
      border-radius: 18px;
    }
  }
}
.bottom_box {
  width: 950px;
  height: 550px;
  padding: 10px 16px;
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;

  .paging {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .screen_btn1 {
    width: 56px;
    height: 28px;
    background: #fff;
  }
  .screen_btn {
    width: 56px;
    height: 28px;
    color: #333;
    background: #ffdd00;
    border-radius: 4px;
  }
}
.pop_up{
  background: #fff;
  color: #000;
}
.tab_item {
  color: #000000;
  font-size: 14px;
  margin-right: 40px;
  padding-bottom: 13px;
}
.tab_pitch {
  color:#212121;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #FFA700;
}
.remind /deep/.el-input__inner {
  color: #333;
  background-color: #F5F5F5 !important;
}
.remind /deep/.el-input__inner::placeholder {
  color: #333 !important;
}
/deep/.el-input__inner::placeholder {
  color: #333 !important;
}
.el-table::before {
  height: 0;
}

</style>
