<template>
  <div class="storePage">
    <!-- 个人中心——道具商城 -->
    <div class="store_top flex-aling">
      <div class="flex-aling" v-if="myStoreShow">
        <div
          class="store_tab"
          :class="tabType == key0 ? 'store_pitchOn' : ''"
          v-for="(tabItem, key0) in myTabList"
          :key="key0"
          @click="myTabSwitchover(key0)"
        >
          {{ tabItem }}
        </div>
      </div>
      <div class="flex-aling" v-else>
        <div
          class="store_tab"
          :class="tabType == key0 ? 'store_pitchOn' : ''"
          v-for="(tabItem, key0) in tabList"
          :key="key0"
          @click="tabSwitchover(key0)"
        >
          {{ tabItem }}
        </div>
      </div>
      
      <div class="cutBtn flex-content" v-if="myStoreShow" @click="cutShow()">
        道具商城
      </div>
      <div class="cutBtn flex-content" v-else @click="cutShow()">我的道具</div>
    </div>
    <!-- 热门推荐 -->
    <div class="recommend_box" v-if="tabType == 0 && !myStoreShow">
      <div class="text_title">热门推荐</div>
      <div class="stageProperty_box" v-if="hotPropsList.length>0">
        <!-- 道具 -->
        <div
          class="stageProperty_item"
          v-for="(item, index) in hotPropsList"
          :key="index"
          @click="setDetailsShow(item)"
        >
        <!-- <svgaImg
        style="padding:20px"
        :value="item.svga"
        :cid="item.propId"
        :widthNum="'100%'"
        :heightNum="'120px'"
        v-if="item.type == 0"
      ></svgaImg> -->
    <img  :src="item.logo" alt=""  />
          <div class="flex-dir">
            <p class="font-14 padding_4">{{ item.name }}</p>
            <!-- type道具类型（0：麦位框，1：进场秀，2：聊天气泡，3：勋章） -->
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 0">
              麦位框
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 1">
              进场秀
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 2">
              聊天气泡
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 3">
              勋章
            </p>
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
      <!-- 热门推荐end -->
      <!-- 最新上架 -->
      <div class="text_title">最新上架</div>
      <div class="stageProperty_box" v-if="newPropsList.length>0">
        <!-- 道具 -->
        <div
          class="stageProperty_item"
          v-for="(item, index) in newPropsList"
          :key="index"
          @click="setDetailsShow(item)"
        >
        <!-- <svgaImg
            style="padding:20px"
            :value="item.svga"
            :cid="item.propId"
            :widthNum="'100%'"
            :heightNum="'120px'"
            v-if="item.type == 0"
          ></svgaImg> -->
        <img  :src="item.logo" alt=""  />
          <div class="flex-dir">
            <p class="font-14 padding_4">{{ item.name }}</p>
            <!-- type道具类型（0：麦位框，1：进场秀，2：聊天气泡，3：勋章） -->
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 0">
              麦位框
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 1">
              进场秀
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 2">
              聊天气泡
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 3">
              勋章
            </p>
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
      <!-- 最新上架end -->
    </div>
    <!--  -->
    <div class="recommend_box" v-else>
      <div class="foxbase_box flex" v-if="!myStoreShow">
        <div
          
          class="foxbaseTab flex-content"
          :class="foxbaseType == index ? 'foxbase_puth' : ''"
          v-for="(item, index) in foxbaseList"
          :key="index"
          @click="foxbaseClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="stageProperty_box" style="margin-top: 16px" v-if="propsList.length>0">
        <!-- 道具 -->
        <div
          class="stageProperty_item"
          v-for="(item, index) in propsList"
          :key="index"
          @click="setDetailsShow(item)"
        >
            <!-- <svgaImg
            style="padding:20px"
            :value="item.svga"
            :cid="item.propId"
            :widthNum="'100%'"
            :heightNum="'120px'"
            v-if="item.type == 0"
          ></svgaImg> -->
        <img  :src="item.logo" alt="" />
          <div class="flex-dir">
            <p class="font-14 padding_4">{{ item.name }}</p>
            <!-- type道具类型（0：麦位框，1：进场秀，2：聊天气泡，3：勋章） -->
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 0">
              麦位框
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 1">
              进场秀
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 2">
              聊天气泡
            </p>
            <p class="font-12" style="color: #8e92b1" v-if="item.type == 3">
              勋章
            </p>
            <div v-if="myStoreShow && item.isDressUp == 1" style="color:#d9a567;margin:6px auto;font-size:8px">已装扮</div>
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
    </div>
    <!-- 道具详情弹窗 -->
    <div class="popup_box" v-if="detailsShow">
      <div class="itemDetails">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">道具详情</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="colseBtn "
          />
        </div>
        <!-- 头部end -->
        <div class="details_content flex-dir">
          
          <img :src="propDetails.logo" alt="" v-if="propDetails.type == 2 || propDetails.type == 3" class="img_style"/>
          <div v-else-if="propDetails.type == 0" class="flex-content">
            <!-- <img :src="userData.avatar" alt="" class="img_head"> -->
            <svgaImg
            :value="propDetails.svga"
            :cid="propDetails.propId"
            :widthNum="'100%'"
            :heightNum="'120px'"
            ref="giftSvga"
          ></svgaImg>
          </div>
          <div v-else-if="propDetails.type == 1" class="flex-content">
            <svgaImg
            :value="propDetails.svga"
            :cid="propDetails.propId"
            :widthNum="'200px'"
            :heightNum="'200px'"
            ref="giftSvga"
          ></svgaImg>
          </div>
          <p class="font-14 padding_4">{{ propDetails.name }}</p>
          <p class="font-12" style="color: rgba(207, 130, 52, 1)">
            {{ foxbaseList[propDetails.secondType] }}
          </p>
          <!-- 我的道具详情 -->
          <div class="flex-dir" style="width: 100%" v-if="myStoreShow">
            <div class="flex-dir" v-if="propDetails.isDressUp == 1">
              <div class="font-10" style="color: #5f627c; margin-top: 21px">
                有效期至 {{ propDetails.toDate }}
              </div>
              <div class="dressupBtn flex-content" @click="cancelDressChange(propDetails.userPropId)">取消装扮</div>
            </div>
            <div class="flex-dir" v-else>
              <!-- isTimeLimit	是否限时道具（0-否 1-是） -->
              <div class="flex-dir" v-if="propDetails.isTimeLimit == 1">
                <div
                  class="dressupBtn flex-content"
                  style="background: #ffdd00;color:#333"
                  @click="dressProps()"
                >
                  使用且装扮
                </div>
              </div>
              <div v-else class="flex-dir">
                <div class="font-10" style="color: #5f627c; margin-top: 21px">
                  有效期至 {{ propDetails.toDate }}
                </div>
                <div
                  class="dressupBtn flex-content"
                  style="background: #ffdd00;color:#333"
                  @click="dressProps()"
                >
                  立即装扮
                </div>
              </div>
            </div>
          </div>
          <!-- 我的道具详情end -->
          <!-- 赠送道具 -->
          <div class="flex-dir" style="width: 100%" v-else-if="presentedShow">
            <div class="flex-aling" style="color:#727692;margin-top:10px">
              是否花费 <span style="color:#e4c393;margin:0 4px" v-if="propPitch.price">{{propPitch.price}}<img src="../../static/61.png" alt="" class="days_icon" /></span>
              购买道具 <span style="color:#dcbb30;margin:0 4px">{{`${propDetails.name}x${propPitch.propsTime}天`}}</span>
            </div>
            <div style="margin-top:8px;color:#727692">
              {{`赠给${presentedData.nickname}?`}}
            </div>
            <!-- 购买或增送 -->
            <div
              class="flex"
              style="width: 100%;margin-top: 20px;justify-content: space-around;"
            >
              <div class="give_btn flex-content" style="background:#f5f5f5;color:#333" @click="presentedReturn()">
                返回
              </div>
              <div class="purchase_btn flex-dir" @click="buyProps(1)">
                <div>确认赠送</div>
                </div>
              </div>
          </div>
          <div v-else-if="propDetails.isNotSale == 1">
            <div class="notForSale">活动/专属，非卖品</div>
          </div>
          <!-- 道具购买 -->
          <div class="flex-dir" style="width: 100%" v-else>
            <!-- 天数选择 -->
            <div class="flex days_box" v-if="propDetails.propsSecondList.length > 0 && propDetails.type != 1">
              <div
                class="days_item flex-dir"
                v-for="item in propDetails.propsSecondList"
                :key="item.id"
                :class="propPitch.id == item.id ? 'days_pitch' : ''"
                @click="daysChanged(item)"
              >
                <p class="font-10">
                  <b class="font-18">{{ item.propsTime }}</b
                  >{{ propsTimeList[item.propsTimeUnit] }}
                </p>
                <div class="flex-content" v-if="item.price">
                  {{ item.price }}
                  <img src="../../static/61.png" alt="" class="days_icon" />
                </div>
              </div>
            </div>
            <!-- 天数选择 -->
            <!-- 购买或增送 -->
            <div
              class="flex"
              style="width: 100%;margin-top: 20px;justify-content: space-around;"
            >
              <div class="give_btn flex-content" @click="setGiveAwayShow()">
                赠送
              </div>
              <div class="purchase_btn flex-dir" @click="buyProps(0)">
                <div>立即购买</div>
                <div v-if="propPitch.price">
                  {{ propPitch.price }}
                  <img src="../../static/61.png" alt="" class="days_icon" />
                </div>
              </div>
            </div>
            <!-- 余额 -->
            <div class="flex-content balance">
              <p>可用余额</p>
              <p class="padding_r4">{{ balanceNum }}</p>
              <img src="../../static/61.png" alt="" class="days_icon" />
              <div class="topup" @click="walletTopupShow = true" >去充值></div>
            </div>
          </div>
          <!-- 道具购买end -->
        </div>
      </div>
    </div>
    <!-- 增送弹窗 -->
    <div class="popup_box" v-if="giveAwayShow">
      <div class="giveAwayPopup">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">赠送道具</div>
          <img src="../../static/quit.png" alt="" @click="setGiveAwayShow()" />
        </div>
        <!-- 头部end -->
        <div class="giveAway_content">
          <div class="flex" style="margin-bottom:20px;">
              <el-select v-model="roleType" placeholder="请选择"  class="roleSlect" style="height:39px" @change="getFollowOrFansList()">
              <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              type="text"
              placeholder="请输入用户ID"
              v-model="userNoData"
              @input="getFollowOrFansList()"
              class="userIdinput_type"
            ></el-input>
          </div>
          <div class="user_beyond">
            <div v-for="(item,index) in userList" :key="index" style="padding:10px 0">
              <div class="giveAway_item"  >
                <div class="flex">
                  <img :src="item.avatar" alt="" class="giveAway_head" />
                  <div>
                    <div class="font-14 flex" style="color: #333">
                      {{ item.nickname }}
                      <p class="giveAway_icon flex-content"  v-if="roleType == 0">粉丝</p>
                    </div>
                    <p class="font-10" style="color: #6a6a94">ID：{{item.userNo}}</p>
                  </div>
                </div>
                <div class="font-14" style="color: rgba(255, 114, 104, 1)" @click="presentedChange(item)">赠送</div>
              </div>
            </div>
          </div>
          
          
        </div>
        
      </div>
    </div>
    <walletTopup
      v-if="walletTopupShow"
      @walletQuitChange="walletTopupShow = false"
      style="position: absolute;left: -30%;top: -20%;"
    ></walletTopup>
    <!-- 道具说明 -->
  </div>
</template>
<script>
import walletTopup from "./walletTopup.vue"; //充值弹窗
import svgaImg from "../chatRoom/components/svgaImg.vue"; //svga文件解析

export default {
  name: "",
  components: {walletTopup,svgaImg},
  data() {
    return {
      walletTopupShow:false,//充值弹窗
      tabList: ["推荐", "麦位框", "进场秀", "语聊气泡", "勋章"],
      // secondType:道具二级类型（0-元宝兑换  1-财富等级  2-房间vip  3-专属定制  4-活动奖励  5-签到奖励 6-邀新奖励）
      foxbaseList: [
        "元宝兑换",
        "财富等级",
        "房间vip",
        "专属定制",
        "活动奖励",
        "签到奖励",
        "邀新奖励",
      ],
      foxbaseType: 0, //选择二级
      myTabList: ["麦位框", "进场秀", "语聊气泡", "勋章"],
      tabType: 0,
      detailsShow: false,
      giveAwayShow: false,
      hotPropsList: [], //推荐道具
      newPropsList: [], //最新上架
      myStoreShow: false, //是否展示我的道具
      propsList: [], //道具列表
      propDetails: {}, //道具详情
      balanceNum: 0, //元宝余额
      priceList: [], //价格信息
      propsTimeList: ["小时", "天", "周", "月"],
      propPitch: {},
      propNum: 1, //道具使用数量
      userNoData:'',//搜索内容
      roleType:'',//列表类型0-粉丝 1-关注
      listOptions:[
        {
          value:1,
          label:'我的关注'
        },{
          value:0,
          label:'我的粉丝'
        }
      ],
      userList:[],//用户列表
      presentedShow:false,//是否赠送道具
      presentedData:{},//赠送人
      userData:{},//用户信息
      propText:'',//道具说明
      propTextShow:false,//说明弹窗
    };
  },
  mounted() {
    this.getPropsList();
    this.getUserInfo()
  },
  methods: {
    // 我的道具-取消装扮
    cancelDressChange(userPropsId){
      // userPropsId 用户道具id
      this.$api.cancelDressProps({userPropsId}).then(res=>{
        if(res.code == 1){
          this.userPropsList();
          this.detailsShow = false
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 获取道具说明
    explainChange(){
      this.$api.getAgreement({configName:'minePropsExplain'}).then(res=>{
        console.log(res);
        if(res.code == 1){
          this.propText = res.data
          this.propTextShow = true
        //   this.$alert(res.data, '道具说明', {
        //   dangerouslyUseHTMLString: true,
        //   confirmButtonText: '确认'
        //    });
        }
      })
    },
    //获取用户信息
    getUserInfo() {
      this.$api.queryUser().then((res) => {
        if (res.code == 1) {
          this.userData = res.data;
        }
      });
    },
    // 退出赠送
    presentedReturn(){
      this.presentedShow = false
      this.presentedData = {}
    },
    presentedChange(i){
      this.presentedShow = true
      this.presentedData = i
      this.giveAwayShow = false
    },
    // 获取粉丝列表&&关注列表
    getFollowOrFansList(){
      let data = {
        pageNum:1,
        pageSize:'999',
        type:this.roleType,//	类型0-粉丝 1-关注
        userNo:this.userNoData,//	关键字
      }
      this.$api.followOrFansList(data).then(res=>{
        if(res.code == 1){
          this.userList = res.data.rows
        }
      })
    },
    // 装扮道具
    dressProps() {
      let data = {
        timeUnit: this.propDetails.timeUnit, //时间单位（0-小时 1-天 2-周 3-月）
        times: this.propNum, //	使用时间
        propId: this.propDetails.propId, //	用户道具id
        userPropsId: this.propDetails.userPropId, //	用户道具id
      };
      this.$api.dressProps(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("装扮成功");
          this.detailsShow = false;
          this.userPropsList();
        }else{
          this.$message.info(res.msg)
        }
      });
    },
    // 道具加减
    numberCalculate(type) {
      if (type == 1) {
        if (this.propNum == 1) {
          return this.$message.info("最少使用一次哦");
        } else {
          this.propNum--;
        }
      } else {
        if (this.propNum == this.propDetails.timeLimit) {
          return;
        } else {
          this.propNum++;
        }
      }
    },
    // 道具下单
    buyProps(type) {
      let data = {
        isSendOther: type, //是否赠送他人（0否 1是）
        propsId: this.propDetails.propId, //	道具id
        // sendUserId: "", //	赠送用户id
      };
      if(type == 1){
        data.sendUserId = this.presentedData.id
      }
      this.$api.buyProps(data).then((res) => {
        if(res.code == 1){
          this.detailsShow = false
          this.$message.success('购买成功')
        }else{
          this.$message.error(res.msg)
        }
      });
    },
    // 价格选择
    daysChanged(i) {
      this.propPitch = i;
    },
    foxbaseClick(index) {
      this.foxbaseType = index;
      this.foxbaseChange();
    },
    // 二级切换
    foxbaseChange(type) {
      let data = {
        pageNum: 1,
        pageSize: 99,
        secondType: this.foxbaseType, //二级id
        typeId: this.tabType, //type:道具类型（0：麦位框，1：进场秀，2：聊天气泡，3：勋章）
      };
      if (!this.myStoreShow) {
        data.typeId = data.typeId - 1;
      }
      this.$api.propsInfoList(data).then((res) => {
        // console.log("道具商城分页查询", res);
        if (res.code == 1) {
          this.propsList = res.data.rows;
        }
      });
    },
    // 道具商城切换
    cutShow() {
      this.myStoreShow = !this.myStoreShow;
      if (this.myStoreShow) {
        this.tabType = 0;
        this.userPropsList();
      } else {
        this.tabType = 0;
        this.getPropsList();
      }
    },
    // 我的道具切换
    myTabSwitchover(key) {
      this.tabType = key;
      this.userPropsList();
    },
    // tab栏切换
    tabSwitchover(key) {
      this.tabType = key;
      if (key == 0) {
        this.getPropsList();
      } else {
        this.foxbaseChange(1);
      }
    },
    // 我的道具
    userPropsList() {
      let data = {
        pageNum: 1,
        pageSize: 99,
        typeId: this.tabType, //type:道具类型（0：麦位框，1：进场秀，2：聊天气泡，3：勋章）
      };
      this.$api.userPropsList(data).then((res) => {
        // console.log("我的道具列表", res);
        if (res.code == 1) {
          this.propsList = res.data.rows;
        }
      });
    },
    // 道具商城--热门推荐/最新上架
    getPropsList() {
      this.$api.hotProps().then((res) => {
        // console.log("道具热门推荐", res);
        if (res.code == 1) {
          this.hotPropsList = res.data;
        }
      });
      this.$api.newProps().then((res) => {
        // console.log("最新上架", res);
        if (res.code == 1) {
          this.newPropsList = res.data;
        }
      });
    },
    // 道具详情弹窗
    setDetailsShow(item) {
      if (!this.myStoreShow) {
        // 道具商城-道具价格信息
        this.$api
          .propPriceInfo({ pageNum: 1, pageSize: 999, propName: item.name })
          .then((res) => {
            if (res.code == 1) {
              this.priceList = res.data;
              this.propPitch = this.propDetails.propsSecondList[0];
            }
          });
        // 元宝余额
        this.$api.userDiamondAccountBalance().then((res) => {
          if (res.code == 1) {
            this.balanceNum = res.data;
          }
        });
      }
      this.detailsShow = !this.detailsShow;
      this.propDetails = item;
    },
    colseBtn(){
      this.detailsShow=false
      this.$refs.giftSvga.player.stopAnimation();
    },
    // detailsShow = false;this.$refs.giftSvga.player.stopAnimation();
    // 增送弹窗
    setGiveAwayShow() {
      this.giveAwayShow = !this.giveAwayShow;
      this.getFollowOrFansList()
    },
  },
};
</script>
<style  scoped lang='scss'>
.notForSale{
  width: 300px;
  height: 45px;
  font-size: 14px;
  color:rgba(134, 144, 155, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 30px;

  
}
.propStyle{
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}
.img_style{
  width: 160px;
  border-radius: 8px;
}
.explain_btn{
  color: #999;
  font-size: 12px;
  position: absolute;
  right: 13%;
}
.img_head{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
}
.user_beyond{
  height: 240px;
  overflow-y: scroll;
  padding-right: 10px;
}
.roleSlect{
  width: 170px;
  border-radius: 4px;
  margin: 0 21px 0 8px;
}
.userId_input /deep/.el-input__inner,.roleSlect /deep/.el-input__inner{
  height: 40px;
  
}
.userId_input{
  width: 103px;
  border-radius: 4px;
  margin: 0 21px 0 8px;
  border: 1px solid #363659;
}
.dressupBtn {
  width: 320px;
  height: 40px;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 40px;
  background: #ffdd00;
  color:#333;
  border-radius: 4px;
}
// 二级
.foxbase_box {
  margin-top: 12px;
  .foxbaseTab {
    height: 26px;
    color: #1d223b;
    font-size: 12px;
    padding: 0 10px;
    margin-right: 10px;
    background: #ececec;
    border-radius: 14px;
  }
  .foxbase_puth {
    color: #212121;
    background: #ffa900;
  }
}

.cutBtn {
  width: 80px;
  height: 28px;
  color: #212121;
  font-size: 12px;
  position: absolute;
  right: 5%;
  background: #ffdd00;
  border-radius: 14px;
}
.storePage {
  height: 90%;
  color: #333333;
  font-size: 14px;
  padding: 12px 10px;
  background: #fff;
}
.recommend_box{
  height: 100%;
  color: #333333;
  font-size: 14px;
  background: #fff;
  overflow-y: scroll;
}
.popup_box {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.store_top {
  .store_tab {
    padding-bottom: 13px;
    margin-right: 40px;
  }
  .store_pitchOn {
    color: #212121;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid #FFA700;
  }
}
.text_title {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-weight: bold;
  color: #1d223b;
  margin: 20px 0 18px 0;
}
// 热门推荐
.stageProperty_box {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.stageProperty_item {
  width: 160px;
  height: 222px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px 8px 8px 8px;
  > img {
    width: 150px;
    height: 150px;
  }
}
//详情弹窗
.itemDetails {
  width: 440px;
  padding-bottom: 40px;
  color: #333;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.details_content {
  padding: 25px;
}
.days_box {
  width: 100%;
  margin-top: 40px;
  justify-content: space-around;
  .days_item {
    width: 117px;
    height: 56px;
    border-radius: 4px;
    color: #8e92b1;
    border: 1px solid #8e92b1;
  }
  .days_pitch {
    width: 117px;
    height: 56px;
    color: #333 ;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.days_icon {
  width: 13px;
  height: 13px;
}
.give_btn {
  width: 159px;
  height: 48px;
  background: rgba(255, 114, 104, 1);
  border-radius: 4px;
  color: #fff;
}
.purchase_btn {
  width: 209px;
  height: 48px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
}
.balance {
  width: 100%;
  margin-top: 20px;
}
.topup {
  color: rgba(65, 119, 221, 1);
  font-size: 14px;
  margin-left: 20px;
}
//赠送弹窗
.giveAwayPopup {
  width: 440px;
  height: 385px;
  color: #333;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .giveAway_content {
    padding: 25px 60px;
  }
  .giveAway_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .giveAway_head {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .giveAway_icon {
    width: 32px;
    height: 18px;
    font-size: 10px;
    color: #fff;
    margin-left: 10px;
    background: #ff3400;
    border-radius: 10px;
  }
}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
/deep/ .el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .btn-next:disabled{
  background-color: #34384f;
}
/deep/.el-input__inner {
  color:rgba(153, 153, 153, 1);
  border:0 !important;
  background-color: #f5f5f5 !important;
  
border-radius: 18px 18px 18px 18px;
}
/deep/.el-input__inner::placeholder{
  color:rgba(153, 153, 153, 1) !important;
}
</style>