<template>
  <div>
    <!-- 榜单 -->
    <div class="monthly_focus">
      <div class="monthly_top flex-aling">
        <div class="monthly_table">
          <div
            :class="rankingType == 1 ? 'monthly_tableItem' : ''"
            @click="rankingTypeChange(1)"
          >
            财富榜
          </div>
          <div
            :class="rankingType == 3 ? 'monthly_tableItem' : ''"
            @click="rankingTypeChange(3)"
          >
            魅力榜
          </div>
        </div>
      </div>
      <!-- 日榜与周榜 -->
      <div class="daily_list">
        <div
          class="flex-content"
          :class="rankingTwoType == 1 ? 'daily_pitchOn' : ''"
          style="flex: 1"
          @click="rankingTwoTypeChange(1)"
        >
          日榜
        </div>
        <div
          class="flex-content"
          :class="rankingTwoType == 2 ? 'daily_pitchOn' : ''"
          style="flex: 1"
          @click="rankingTwoTypeChange(2)"
        >
          周榜
        </div>
      </div>
      <!-- 榜单前三 -->
      <div class="monthlyFocus_three">
        <!-- 二 -->
        <div class="monthlyFocus_item flex-dir" style="margin-top: 20px">
          <div class="monthlyFocus_head flex-content" v-if="rankingList[1]">
            <img
              :src="rankingList[1].userAvatar"
              style="object-fit: cover"
              alt=""
            />
            <img
              src="../../../static/56.png"
              alt=""
              class="monthlyFocus_icon"
            />
          </div>
          <div class="monthlyFocus_name line1" v-if="rankingList[1]">
            {{ rankingList[1].userName }}
          </div>
          <div class="font-12" style="color: #fff" v-if="rankingList[1]">
            {{ rankingList[1].fortuneNumStr }}
          </div>
        </div>
        <!-- 二 -->
        <!-- 一 -->
        <div class="monthlyFocus_item flex-dir" v-if="rankingList[0]">
          <div class="monthlyFocus_head flex-content">
            <img
              :src="rankingList[0].userAvatar"
              style="object-fit: cover"
              alt=""
            />
            <img
              src="../../../static/55.png"
              alt=""
              class="monthlyFocus_icon"
            />
          </div>
          <div class="monthlyFocus_name line1">
            {{ rankingList[0].userName }}
          </div>
          <div class="font-12" style="color: #fff">
            {{ rankingList[0].fortuneNumStr }}
          </div>
        </div>
        <!-- 一 -->
        <!-- 三 -->
        <div class="monthlyFocus_item flex-dir" style="margin-top: 20px">
          <div class="monthlyFocus_head flex-content" v-if="rankingList[2]">
            <img
              :src="rankingList[2].userAvatar"
              style="object-fit: cover"
              alt=""
            />
            <img
              src="../../../static/57.png"
              alt=""
              class="monthlyFocus_icon"
            />
          </div>
          <div class="monthlyFocus_name line1" v-if="rankingList[2]">
            {{ rankingList[2].userName }}
          </div>
          <div class="font-12" style="color: #fff" v-if="rankingList[2]">
            {{ rankingList[2].fortuneNumStr }}
          </div>
        </div>
        <!-- 三 -->
      </div>
      <div
        v-if="rankingList.length == 0"
        class="flex-content"
        style="height: 110px; color: #6d6d88"
      >
        暂无数据
      </div>
      <div class="billboard" v-if="billboardType">
        <div
          class="billboard_item"
          v-for="(item, index) in rankingList"
          :key="index"
        >
          <div class="billboard_item" style="width: 100%" v-if="index > 2">
            <div class="flex-aling">
              <div style="width: 10px">{{ index + 1 }}</div>
              <img :src="item.userAvatar" alt="" class="billboard_head" />
              <div class="line1">{{ item.userName }}</div>
            </div>
            <div class="billboard_num">{{ item.fortuneNumStr }}</div>
          </div>
        </div>
      </div>
      <div
        class="separated_img"
        :class="billboardType ? 'separated_imgAgainst' : ''"
        @click="billboardType = !billboardType"
      ></div>
      <!-- 聊天 -->
      <div class="chat_box" :class="billboardType ? 'chat_max' : ''">
        <!-- 聊天内容 -->
        <div class="chat_content" :class="billboardType ? 'chat_max' : ''">
          <div v-for="(item, index) in chatContentData" :key="index">
            <div
              class="chat_item flex-aling"
              v-if="item.data.enter == 5 && item.data.text"
              style="overflow: hidden; overflow-wrap: anywhere"
            >
              <div class="chat_itemName">房间公告：</div>
              {{ item.data.text }}
            </div>
            <!-- 进入房间 -->
            <div
              class="chat_item flex-aling"
              v-if="item.data.enter == 1 || item.data.cmd == 'enter_room'"
            >
              欢迎
              <span
                class="chat_itemName flex-aling"
                @click.stop="checkUserName(item)"
              >
                <img :src="item.data.logo" alt="" style="width: 32px" />
                {{ item.data.userName }}
              </span>
              进入房间
            </div>
            <!-- 赠送礼物 -->
            <div
              class="chat_item flex-aling"
              v-if="item.data.enter == 2 || item.data.cmd == 'send_gift'"
            >
              <span
                class="chat_itemName flex-aling"
                @click.stop="checkUserName(item)"
              >
                <!-- <img src="../../../static/user1.png" alt="" />
                <img src="../../../static/user2.png" alt="" /> -->
                {{ item.data.userName }}
              </span>
              <span>赠送给</span>
              <!-- class="chat_itemName flex-aling"
              v-for="(opnName, key1) in item.toUserName"
              :key="key1" -->
              <span @click.stop="checkUserName(item)"></span>
              {{ item.data.toUserName }}
              <!-- <span v-for="(item1,index1) in item.data.toUserName" :key="index1">
                {{ item1 }}
              </span> -->
              <img
                :src="item.data.giftLogo"
                alt=""
                style="width: 32px; height: 32px"
              />
              <span>x{{ item.data.giftNumber }}</span>
            </div>
            <!-- 文字消息 -->
            <!-- <div class="chat_item" v-if="item.enter == 3" :style="{background:item.bubble? `url(${item.bubble})`:''}"> -->
            <div
              class="chat_item flex-aling"
              v-if="item.data.enter == 3"
              :class="item.data.bubble ? 'chat_pitch' : ''"
              :style="{
                borderImageSource: item.data.bubble
                  ? `url(${item.data.bubble})`
                  : '',
              }"
            >
              <div
                class="chat_itemName"
                style="line-height: 18px; overflow: hidden"
              >
                <img
                  :src="item.data.logo"
                  alt=""
                  style="width: 32px; height: 32px; vertical-align: middle"
                />
                <span @click.stop="checkUserName(item)">
                  {{ item.data.userName }}:</span
                >
                <span
                  class="wrap"
                  style="color: #ffffff; word-break: break-all"
                  >{{ item.data.content }}</span
                >
              </div>
            </div>
            <div class="chat_item flex" v-if="item.data.enter == 4">
              <div class="chat_itemName">系统消息：</div>
              <div class="chat_itemName">{{ item.data.userName }}</div>
              <span v-if="item.data.operate == 1">被设置为</span>
              <span v-if="item.data.operate == 2">被移除</span>
              <span v-if="item.data.type == 1">超级管理员</span>
              <span v-else-if="item.data.type == 2">管理员</span>
              <span v-else-if="item.data.type == 3">被踢出房间</span>
              <span v-else-if="item.data.type == 4">被禁言</span>
              <span v-else-if="item.data.type == 5">已解除禁言</span>
            </div>
          </div>
        </div>
        <!-- 输入聊天内容end -->
        <div class="chat_send">
          <div class="chat_inputInp">
            <el-input
              type="text"
              placeholder="您想说的话…"
              v-model="chatInput"
              maxlength="200"
              class="chat_input"
              v-if="chatType == 1"
              @keyup.enter.native="postMessage()"
            ></el-input>
            <el-input
              type="text"
              :placeholder="`全频广播${broadcastPrice}/条`"
              v-model="allChatInput"
              class="chat_input"
              v-if="chatType == 2"
              maxlength="200"
              @keyup.enter.native="postMessage()"
            ></el-input>
            <img
            src="../../../static/user15.png"
            alt=""
            class="chat_radioSwithch"
          />
          </div>
          <img
            src="../../../static/139.png"
            alt=""
            class="chat_radio"
            @click="chatSwitch()"
            v-if="chatType == 2"
          />
          <img
            src="../../../static/145.png"
            alt=""
            class="chat_radio"
            @click="chatSwitch()"
            v-else
          />
          <div class="chat_btn flex-content" @click="postMessage()">发送</div>
        </div>
      </div>
    </div>
    <!-- 右侧广播 -->
    <div class="broadcast_right" v-if="vollbildShow && roomSetData">
      <div class="broadcast_rightItem flex-aling">
        <div class="fullBand_text flex text-cut">
          <p>{{ wholeData.sendUserName }}</p>
          <p>{{ wholeData.content }}</p>
        </div>
        <img src="../../../image/room/80.png" alt="" style="margin: 0 10px" />
        <div class="fullBand_ben" @click="goPage(wholeData)">去围观</div>
      </div>
    </div>
    <div class="popUp_box" v-if="broadcastHintShow">
      <div class="broadcastHint">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">全语聊房广播</div>
          <img
            src="../../../static/quit.png"
            alt=""
            @click="broadcastHintShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="hintContent flex-dir">
          <p style="color: #000; margin-top: 92px">
            {{ `您当前财富等级：${broadcastData.currLevelName}` }}
          </p>
          <p style="margin-top: 14px; color: rgba(65, 119, 221, 1)">
            {{ `${broadcastData.levelName}以上 财富等级专特权` }}
          </p>
          <div class="examineClassBtn flex-dir" @click="wealthThatShowChange()">
            了解财富等级特权
          </div>
        </div>
      </div>
    </div>
    <wealthPopover
      v-if="wealthThatShow"
      @setWealthShow="wealthThatShow = false"
    ></wealthPopover>
  </div>
</template>

<script>
import wealthPopover from "../../personalCenterAll/wealthPopover.vue"; //财富说明

export default {
  name: "",
  props: {
    forbidSpeach: "",
  },
  components: { wealthPopover },
  data() {
    return {
      rankingType: 1, //排行榜类型 1-财富榜 2-VIP 3-魅力榜 4-今日签收 5-在线）
      rankingTwoType: 1, //	1-日榜 2-月榜
      billboardType: false, //排行榜是否显示
      chatInput: "", //聊天内容
      allChatInput: "", //广播内容
      roomId: "", //房间id
      chatType: 1, //聊天功能 1 正常聊天 2 全频广播
      userInfo: {},
      chatContentData: [
        // enter: 0, // 1 进入房间
      ], //聊天内容
      rankingList: [], //厅排行榜
      vollbildShow: false, //全频广播
      broadcastPrice: 0, //广播价格
      broadcastData: {}, //广播财富等级权限
      broadcastHintShow: false, //等级提示弹窗
      wealthThatShow: false, //财富等级
      wholeData: {
        roomId: "",
      }, //全频消息
      roomSetData: {}, //用户设置数据
      flage: true,
      roomSetDataBullet: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    /**
     * 房间通知回调
     *
     * @param name    名称
     * @param content 通知内容
     */
    // 个人信息
    // console.log(this.userInfo,'sssss')
    // // TIM.login({});
    // console.log(TIM,'this.TencentCloudChat')
    // console.log(this.TencentCloudChat,'sssssss')
    // // this.chat = this.TencentCloudChat.create({ SDKAppID: this.$SDKAppID });
    //  console.log(message,'messagemessage')
    // // 发送消息
    // this.$RCVoiceRoomLib.on("RoomNotificationReceived", (info) => {
    //   //业务代码
    //   // console.log("房间通知回调", info);
    //   let thoe = this;
    //   switch (info.name) {
    //     case "clear_message": //清除聊天记录
    //       this.chatContentData = [];
    //       break;
    //     case "RCAudienceJoinRoom": //有人进入房间
    //       this.$api.userMainInfo({ otherUserId: info.content }).then((res) => {
    //         if (res.code == 1) {
    //           let obj = {
    //             enter: 1,
    //             userName: res.data.nickname,
    //             logo: res.data.wealthLevelLogo,
    //           };
    //           this.chatContentData.push(obj);
    //         }
    //       });
    //       this.scrollToBottom();
    //       break;
    //     default:
    //       break;
    //   }
    // });
    // this.$RongIMLib.registerMessageType(
    //       "SEND_ALL_MESSAGE",
    //       true,
    //       true,
    //       [],
    //       false
    //     );
    /**
     * 收取消息回调
     *
     * @param message 收到的消息
     */
    // this.$RCVoiceRoomLib.on("MessageReceived", (m) => {
    //   //业务代码
    //   // console.log("右侧收到的消息", m);
    //   switch (m.messageType) {
    //     case "RC:Chatroom:Barrage": //文本信息
    //       let textObj = m.content;
    //       textObj.enter = 3;
    //       this.chatContentData.push(textObj);
    //       this.scrollToBottom();
    //       break;
    //     case "RCMic:chatRoomGift": //礼物增送
    //       let obj = m.content;
    //       obj.enter = 2;
    //       // console.log( '接收礼物',m.content);
    //       // obj.toUserName = obj.toUserName.split(",");
    //       this.chatContentData.push(obj);
    //       setTimeout(() => {
    //         this.roomRanking();
    //       }, 300);
    //       this.scrollToBottom();
    //       break;
    //     case "SEND_ALL_MESSAGE": //全服消息
    //       if (m.content.msgType == 0) {
    //         this.wholeData = m.content;
    //         this.vollbildShow = true;
    //         setTimeout(() => {
    //           this.vollbildShow = false;
    //         }, 4000);
    //       }
    //       this.scrollToBottom();
    //       break;
    //     case "RC:Chatroom:Admin": //设置管理消息
    //       let adminObj = m.content;
    //       adminObj.enter = 4;
    //       this.chatContentData.push(adminObj);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    //房间人数发生变化(有人进来)
    // this.$RCVoiceRoomLib.on("AudienceEnter", (item) => {
    //   console.log("房间人数发生变化(有人进来)", item);
    // });
  },
  mounted() {
    this.roomId = this.$route.query.roomId;
    this.wholeData.roomId = this.$route.query.roomId;
    this.roomRanking();
    this.getBroadcastData();
    this.$api.queryUser().then((res) => {
      if (res.code == 1) {
        this.userInfo = res.data;
        // 用户自己加入房间
      }
    });
  },
  destroy() {
    // this.$RongIMLib.removeEventListener(Events.MESSAGES, listener);
  },
  methods: {
    // 进入房间厅通知
    enterInform(text) {
      console.log(text);
      // let obj = {
      //   text,
      //   enter: 5,
      // };
      // this.chatContentData.push(obj);
    },
    // 获取用户房间设置
    getRoomUserSet() {
      this.$api.getRoomUserSet().then((res) => {
        if (res.code == 1) {
          this.roomSetData = res.data;
        }
      });
    },
    checkUserName(item) {
      this.$emit("checkUser", item);
    },
    // 了解财富等级特权
    wealthThatShowChange() {
      this.broadcastHintShow = false;
      this.wealthThatShow = true;
    },
    // 获取全频广播数据
    getBroadcastData() {
      this.$api.getSendAllPrice().then((res) => {
        if (res.code == 1) {
          this.broadcastPrice = res.data;
        }
      });
      this.$api.getSendAllLevel().then((res) => {
        if (res.code == 1) {
          this.broadcastData = res.data;
        }
      });
    },
    getGiftData(info) {
      this.chatContentData.push(info);
      this.scrollToBottom();
    },
    // 添加管理设置消息
    setAdminLocal(info) {
      let obj = info;
      obj.enter = 4;
      this.scrollToBottom();
    },
    //滚动条保持最底部方法
    scrollToBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat_content");
        container.scrollTop = container.scrollHeight;
      });
    },
    goPage(data) {
      // if (data.roomId == this.roomId) {
      // } else {
      // this.$router.go(0);
      this.$router.push({
        path: "/kongbai?roomId=" + data.roomId,
      });
      // this.$parent.enterRoom(data.roomId)
      // }
    },

    // 发送消息
    postMessage() {
      // console.log('asdb')
      if (this.forbidSpeach == 1) {
        this.$message.info("您被禁言了");
        return;
      }
      let that = this;
      let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
      let TencentCloudChat = this.TencentCloudChat;
      // // // chatType 聊天功能 1 正常聊天 2 全频广播
      if (!this.chatInput && this.chatType == 1)
        return this.$message.info("请输入内容");
      if (this.broadcastData.canSend != 1 && this.chatType == 2)
        return (this.broadcastHintShow = true);
      if (this.flage) {
        this.flage = false;
        if (this.chatType == 1) {
          let data = {
            cmd: "CustomCmdMsg",
            data: {
              bubble: this.userInfo.bubble,
              cmd: "chat_msg",
              content: this.chatInput,
              logo: this.userInfo.logo,
              userId: this.userInfo.id,
              userName: this.userInfo.nickname,
              enter: 3,
            },
          };
          let message = that.chat.createCustomMessage({
            to: this.roomId,
            conversationType: TencentCloudChat.TYPES.CONV_GROUP,
            payload: {
              data: JSON.stringify(data),
              description: JSON.stringify(data),
              extension: JSON.stringify(data),
            },
          });
          let promise = this.chat.sendMessage(message);
          promise
            .then(function (imResponse) {
              // 发送成功
              that.chatContentData.push(
                JSON.parse(imResponse.data.message.payload.data)
              );
              that.flage = true;
              that.chatInput = "";
              that.scrollToBottom();
            })
            .catch(function (imError) {
              // 发送失败
              console.warn("sendMessage error:", imError);
            });
        } else {
          that.flage = false;
          //   //全服消息
          this.wholeData = {
            sendUserId: this.userInfo.id, //发消息的用户ID
            content: this.allChatInput, //	推送消息内容
            msgType: 0, //0-全屏广播 1-礼物信息
            roomId: this.roomId, //房间id
            sendUserAvatar: this.userInfo.avatar,
            sendUserName: this.userInfo.nickname,
            giftId: "", //礼物id
            giftNum: "", //礼物数量
            price: "", //价格
            toUserId: "", //接收礼物的用户ID
          };
          this.getRoomUserSet();
        }
      }

      // // this.scrollToBottom();
      // this.getRoomUserSet();
      // // this.roomRanking();
    },
    getRoomUserSet() {
      console.log("aaaaaaabbbbb");
      let that = this;
      that.flage = true;
      this.$api.getRoomUserSet().then((res) => {
        if (res.code == 1) {
          this.roomSetDataBullet = res.data.allBulletChat;
          this.$api.sendWholeMessage(this.wholeData).then((res) => {
            if (res.code == 1) {
              that.flage = true;
              this.allChatInput = "";
            } else {
              this.$message.error(res.msg);
            }
          });
          if (this.roomSetDataBullet == 1) {
            this.vollbildShow = false;
          } else {
            this.vollbildShow = true;
            setTimeout(() => {
              this.vollbildShow = false;
            }, 4000);
          }
        }
      });
    },
    // getRoomUserSet
    // 聊天功能切换
    chatSwitch() {
      if (this.chatType == 1) {
        this.chatType = 2;
      } else {
        this.chatType = 1;
      }
    },
    // 获取聊天历史
    getMessage() {
      //获取本地记录
      const conversation = {
        conversationType: this.$RongIMLib.ConversationType.CHATROOM,
        targetId: this.roomId,
      };
      // 从当前时间开始向前查询
      const option = {
        timestamp: 0,
        count: 20,
        order: 0,
      };
      // this.$RongIMLib.getHistoryMessages(conversation, option).then((res) => {
      //   console.log("本地聊天历史", res);
      // });
    },
    // 排行榜切换
    rankingTypeChange(t) {
      this.rankingType = t;
      this.roomRanking();
    },
    //日期切换
    rankingTwoTypeChange(type) {
      this.rankingTwoType = type;
      this.roomRanking();
    },
    // 厅排行榜
    roomRanking() {
      let data = {
        roomId: this.roomId, //房间id
        type: this.rankingTwoType, //	1-日榜 2-月榜
        rankType: this.rankingType, //类型（1-财富榜 2-VIP 3-魅力榜 4-今日签收 5-在线）
      };
      this.$api.roomRanking(data).then((res) => {
        if (res.code == 1) {
          this.rankingList = res.data.userDiamondAccountBillVO;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
}

.broadcastHint {
  width: 522px;
  height: 346px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;

  .examineClassBtn {
    width: 176px;
    height: 40px;
    margin-top: 77px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
    color: #333;
  }
}

// 右侧广播
.broadcast_right {
  position: absolute;
  top: 50%;
  right: 15%;
  overflow: hidden;

  .broadcast_rightItem {
    width: 314.93px;
    height: 60px;
    background: url(../../../image/popout/2.png) no-repeat;
    animation-name: rightDynamic;
    animation-iteration-count: 1;
    animation-duration: 2s;

    .fullBand_img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
      left: 50px;
    }

    .fullBand_text {
      width: 120px;
      font-size: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      margin-left: 56px;
    }

    .fullBand_ben {
      width: 44px;
      height: 21px;
      color: #f68229;
      font-size: 12px;
      background: #ffffff;
      text-align: center;
      line-height: 21px;
      border-radius: 28px;
    }
  }
}

@keyframes rightDynamic {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.monthly_focus {
  width: 300px;
  height: 740px;
  background: rgba($color: #1d2030, $alpha: 0.73);

  .monthly_top {
    width: 300px;
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba($color: #ffde32, $alpha: 0.09) 0%,
      rgba($color: #ffa200, $alpha: 0.09) 100%
    );
  }

  .monthly_table {
    width: 100%;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .monthly_tableItem {
      color: #ffdd00;
      position: relative;
      transition: all 0.5s ease-in;
    }

    .monthly_tableItem::after {
      content: "";
      width: 16px;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 150%;
      left: 50%;
      transform: translate(-50%);
      background: #ffdd00;
    }
  }

  .daily_list {
    width: 96px;
    height: 24px;
    color: #8e92b1;
    margin: 10px auto 20px auto;
    font-size: 12px;
    background: #252b4a;
    border-radius: 32px;
    display: flex;
    align-items: center;

    .daily_pitchOn {
      width: 48px;
      height: 24px;
      color: #333;
      background: #ffdd00;
      border-radius: 32px;
    }
  }

  //前三
  .monthlyFocus_three {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;

    .monthlyFocus_item {
      flex: 1;

      .monthlyFocus_head {
        width: 52px;
        height: 52px;
        position: relative;
        border-radius: 50%;
        background-color: #a3b0fa;
        animation: fade-in 0.2s ease-in;

        > img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        .monthlyFocus_icon {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -10%;
          right: 0;
          border: 2px solid linear-gradient(179deg, #a9d2fb 0%, #9ecefd 100%);
        }
      }

      .monthlyFocus_name {
        margin: 8px 0 2px 0;
        font-size: 14px;
        text-align: center;
        width: 90px;
        color: #fff;
      }
    }
  }

  .separated_img {
    width: 280px;
    height: 6px;
    margin: 9px auto;
    background: url(../../../static/138.png) no-repeat;
    background-size: 100% 100%;
  }

  .separated_imgAgainst {
    transform: rotate(-180deg);
  }

  // 排行榜
  .billboard {
    width: 100%;
    height: 260px;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: scroll;

    .billboard_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 12px;

      .billboard_head {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin: 0 8px;
      }

      .billboard_num {
        color: #77d769;
      }
    }
  }

  // 聊天
  .chat_max {
    height: 230px !important;
  }

  .chat_box {
    position: relative;
    top: -10px;
    margin: 0 10px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .chat_content {
      width: 100%;
      height: 500px;
      padding-top: 10px;
      padding-bottom: 30px;
      overflow-y: scroll;

      .chat_item {
        width: 80%;
        font-size: 14px;
        flex-wrap: wrap;
        padding: 4px 8px 6px 8px;
        margin-bottom: 8px;
        background: linear-gradient(
          88deg,
          rgba($color: #ffec08, $alpha: 0.1) 0%,
          rgba(241, 115, 252, 0) 100%
        );
        border-radius: 8px;

        // flex-wrap: wrap;
        .chat_itemName {
          color: #c6afff;
          margin: 0 2px;
        }
      }

      .chat_pitch {
        padding: 0;
        border-color: #fff;
        border-style: solid;
        border-width: 14px 20px !important;
        border: 4px solid transparent;
        background: transparent;
        border-image-slice: 22 22 22 22 fill;
        border-image-outset: 0;
        border-image-repeat: stretch;
      }
    }

    .chat_send {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .chat_inputInp{
        position: relative;
      }
      .chat_input {
        width: 181px;
        height: 32px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 44px;
      }
      .chat_radioSwithch{
        position: absolute;
        width: 16px;
        height: 16px;
        right: 12px;
        top:8px;
        cursor: pointer;
      }

      /deep/.chat_input .el-input__inner {
        width: 181px;
        height: 32px;
        color: #fff;
        border-radius: 44px;
        background: rgba($color: #303d4b, $alpha: 0.5);
        border: 1px solid rgba(48, 61, 75, 0.5);
        background-color: #363659 !important;
      }

      .chat_radio {
        width: 32px;
        height: 32px;
        margin: 0 5px 0 6px;
      }

      .chat_btn {
        width: 56px;
        height: 32px;
        color: #333;
        background: rgba(255, 221, 0, 1);
        border-radius: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
