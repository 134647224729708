<template>
  <div class="firstPage">
    <!-- 首充弹窗 -->
    <img
      src="../../image/my/7.png"
      alt=""
      class="first_quit"
      @click="firstQuit()"
    />
    <div class="firstPopup_box">
      <div class="firstPopup">
        <img src="../../image/my/4.png" alt="" class="first_titleImg" />
        <!-- <div class="font-16">时间</div> -->
        <countDown :endTime="endTimeData" v-if="endTimeDataShow" ref="countDown"></countDown>
        <div class="first_btn flex-content" @click.stop="goTopUp()">立即充值</div>
        <div style="font-size: 19px">首次单笔充值满以下金额，即可获得</div>
        <div style="font-size: 21; color: #ebc92f; margin: 13px 0">
          超值大礼包（3选1）
        </div>
        <!-- 礼包一 -->
        <div class="first_giftBag" v-if="firstOne.length > 0">
          <div class="first_title">
            首充
            <span style="color: #ebc92f; font-size: 24px">{{firstOne[0].moneyLimit}}</span>
            元 额外赠送
            <span style="color: #ebc92f; font-size: 24px">{{firstOne[0].prvalue}}</span>
            元大礼包
          </div>
          <div class="flex_giftBox">
            <div class="first_item flex-dir" v-for="item in firstOne" :key="item.id">
              <div class="first_icon flex-content" v-if="item.propsTime">{{`${item.propsTime}${item.propsUnit}`}}</div>
              <img :src="item.resourceImage" alt="" class="first_itemImg" />
              <div style="color:rgba(90, 65, 18, 1)">{{item.resourceName}}</div>
            </div>
          </div>
        </div>
        <!-- 礼包二 -->
        <div class="first_giftBag" v-if="firstTwo.length > 0">
          <div class="first_title">
            首充
            <span style="color: #ebc92f; font-size: 24px">{{firstTwo[0].moneyLimit}}</span>
            元 额外赠送
            <span style="color: #ebc92f; font-size: 24px">{{firstTwo[0].moneyLimit}}</span>
            元大礼包
          </div>
          <div class="flex_giftBox">
            <div class="first_item flex-dir" v-for="item in firstTwo" :key="item.id">
              <div class="first_icon flex-content" v-if="item.propsTime">{{`${item.propsTime}${item.propsUnit}`}}</div>
              <img :src="item.resourceImage" alt="" class="first_itemImg" />
              <div style="color:rgba(90, 65, 18, 1)">{{item.resourceName}}</div>
            </div>
          </div>
        </div>
        <!-- 礼包三 -->
        <div class="first_giftBag" v-if="firstThree.length > 0">
          <div class="first_title">
            首充
            <span style="color: #ebc92f; font-size: 24px">{{firstThree[0].moneyLimit}}</span>
            元 额外赠送
            <span style="color: #ebc92f; font-size: 24px">{{firstThree[0].moneyLimit}}</span>
            元大礼包
          </div>
          <div class="flex_giftBox">
            <div class="first_item flex-dir" v-for="item in firstThree" :key="item.id">
              <div class="first_icon flex-content" v-if="item.propsTime">{{`${item.propsTime}${item.propsUnit}`}}</div>
              <img :src="item.resourceImage" alt="" class="first_itemImg" />
              <div style="color:rgba(90, 65, 18, 1)">{{item.resourceName}}</div>
            </div>
          </div>
        </div>
        <!-- 礼包end -->
        <img src="../../image/my/6.png" alt="" style="margin-top: 30px" />
        <div style="line-height: 30px; margin-top: 20px;width: 550px;">
          <p v-if="firstOne.length > 0">1、单笔首充{{firstOne[0].moneyLimit}}元，额外获得{{firstOneString.toString()}}。</p>
          <p v-if="firstTwo.length > 0">2、单笔首充{{firstTwo[0].moneyLimit}}元，额外获得{{firstTwoString.toString()}}。</p>
          <p v-if="firstThree.length > 0">3、单笔首充{{firstThree[0].moneyLimit}}元，额外获得{{firstThreeString.toString()}}。</p>
        </div>
        <div style="color: #ffe9e9; font-weight: bold; margin-top: 50px">
          注意事项
        </div>
        <div class="serveTextStyle" v-html="serveText"></div>
      </div>
    </div>
  </div>
</template>
<script>
import countDown from "../../components/countDown/index"
export default {
  name: "",
  components: {countDown},
  data() {
    return {
      endTimeData:0,//首充结束时间
      firstOne:[],//首充礼物 1
      firstOneString:[],//首充礼物 1名称
      firstTwo:[],//首充礼物 2
      firstTwoString:[],//首充礼物 2 名称
      firstThree:[],//首充礼物 3
      firstThreeString:[],//首充礼物 3名称
      endTimeDataShow:false,
      serveText:''
    };
  },
  mounted(){
    this.getUserRechargeFirst()
    this.getUserTime()
    this.getAttentionShow()
  },
  methods: {
    // 获取充值协议
    getAttentionShow(){
      this.$api.getAgreement({configName:'firstRule'}).then(res=>{
        if(res.code == 1){
          this.serveText = res.data
        }
      })
    },
    firstQuit() {
      this.$refs.countDown.endTimeData = 0
      this.endTimeData = 0
      this.endTimeDataShow = false
      this.$emit("firstQuit");
    },
    // 去充值
    goTopUp(){
      const now = Date.parse(new Date())
      // 相差的毫秒数
      const msec = this.endTimeData - now
      if(msec < 0) return this.$message.info('首充礼包已过期')
      this.firstQuit()
    },
    // 获取首充礼物信息
    getUserRechargeFirst(){
      this.$api.userRechargeFirst().then(res=>{
        if(res.code == 1){
          if(res.data[0] && res.data[0].length > 0){
            this.firstOne = res.data[0]
            this.firstOneString = []
            res.data[0].map(item=>{
              
              let str1 = `${item.resourceName}${item.propsTime ? 'x'+ item.propsTime :''}${item.propsUnit?item.propsUnit:''}`
              this.firstOneString.push(str1)
            })
          }
          if(res.data[1] && res.data[1].length > 0){
            this.firstTwo = res.data[1]
            this.firstTwoString = []
            res.data[1].map(item=>{
              let str2 = `${item.resourceName}${item.propsTime ? 'x'+ item.propsTime:''}${item.propsUnit?item.propsUnit:''}`
              this.firstTwoString.push(str2)
            })
          }
          if(res.data[2] && res.data[2].length > 0){
            this.firstThree = res.data[2]
            this.firstThreeString = []
            res.data[2].map(item=>{
              let str3 = `${item.resourceName}${item.propsTime ? 'x'+ item.propsTime:''}${item.propsUnit?item.propsUnit:''}`
              this.firstThreeString.push(str3)
            })
          }
        }
      })
    },
    // 获取用户首充倒计时
    getUserTime(){
      this.$api.getUserFirstRech().then(res=>{
        // console.log('时间',res);
        if(res.code == 1){
          this.endTimeData = res.data
          this.endTimeDataShow = true
        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
.serveTextStyle{
  width: 90%;
  margin: auto;
  height: 200px;
  overflow-y: scroll;
}
.flex_giftBox{
  display: flex;
  overflow-x: scroll;
}
.flex_giftBox::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
.first_quit {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7%;
  right: 27%;
}
.firstPage {
  // width: 1920px;
  // height: 1924px; myq
  width: 100%;
  height: 100%;
  font-size: 14px;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  // align-items: center; myq
  justify-content: center;
}
.firstPopup_box {
  width: 724px;
  // width: 100%;
  height: 800px;
  position: absolute;
  top: 100px;
  border-radius: 16px;
  // left: 50%;
  // transform: translateX(-50%); myq
  overflow-y: scroll;
}
.firstPopup {
  width: 720px;
  margin: 0 auto;
  height: 1664px;
  display: flex;
  color: #fff;
  padding-top: 72px;
  flex-direction: column;
  align-items: center;
  background: url(../../static/119.png) no-repeat;
  background-size: 100% 100%;
  // background: red;  myq
  border-radius: 16px;
}
.first_titleImg {
  width: 357px;
  height: 74px;
  margin-bottom: 26px;
}
.first_btn {
  width: 299px;
  height: 73px;
  color: #6d5a05;
  font-size: 26px;
  font-weight: bold;
  margin-top: 34px;
  margin-bottom: 48px;
  background: linear-gradient(318deg, #ebc92f 0%, #fbd942 100%);
  border-radius: 44px;
}
.first_giftBag {
  width: 490px;
  height: 227px;
  margin-top: 30px;
  overflow: hidden;
  background: url(../../image/my/5.png) no-repeat;
  .first_title {
    color: #000;
    font-size: 19px;
    font-weight: bold;
    padding: 15px;
  }
  .first_item {
    width: 118px;
    height: 146px;
    margin-left: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    position: relative;
    box-sizing: border-box;
    .first_icon {
      width: 38px;
      height: 22px;
      font-size: 13px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(112deg, #e17616 0%, #ff9b41 100%);
      border-radius: 4px 16px 1px 16px;
    }
    .first_itemImg {
      width: 63px;
      height: 63px;
      margin-bottom: 11px;
    }
  }
}
.firstPopup_box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
.firstPopup_box::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 5px transparent;
}
</style>
