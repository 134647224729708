<template>
  <!-- 我的消息 -->
  <div class="page">
    <!-- 他人主页 -->
    <othersHome
      :userId="manitoId"
      v-if="othersShow"
      @closeOthers="showChange('others')"
    ></othersHome>
    <div class="message_box">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14 flex-aling">消息</div>
        <img class="messageText" src="../../static/quit.png" alt="" @click="closeMessage()" />
      </div>
      <!-- 头部end -->
      <div class="flex">
        <!-- 左侧tab -->
        <div class="left_tab">
          <img :src="myUser.avatar" alt="" class="head" />
          <div
            class="tab_item flex-dir"
            :class="tabType == 0 ? 'tab_paht' : ''"
            @click="tabTypeChange(0)"
          >
            <img src="../../image/message/1.png" alt="" />
            <div>消息</div>
          </div>
          <div
            class="tab_item flex-dir"
            :class="tabType == 1 ? 'tab_paht' : ''"
            @click="tabTypeChange(1)"
          >
            <img src="../../image/message/2.png" alt="" />
            <div>关注</div>
          </div>
          <div
            class="tab_item flex-dir"
            :class="tabType == 2 ? 'tab_paht' : ''"
            @click="tabTypeChange(2)"
          >
            <img src="../../image/message/3.png" alt="" />
            <div>粉丝</div>
          </div>
          <div
            class="tab_item flex-dir"
            :class="tabType == 3 ? 'tab_paht' : ''"
            @click="tabTypeChange(3)"
          >
            <img src="../../image/message/3.png" alt="" />
            <div>通讯录</div>
          </div>
        </div>
        <!-- 左侧tab end -->
        <!-- 消息列表 -->
        <!-- 关注&&粉丝列表 -->

        <div class="message_list" v-if="tabType == 1">
          <div
            v-if="attentionlist.length == 0"
            class="flex flex-content"
            style="flex-direction: column"
          >
            <img
              src="../../image/room/wu.png"
              alt=""
              style="width: 120px; margin-top: 240px"
            />
            <p style="margin-top: 12px">暂无数据</p>
          </div>
          <div
            class="message_item flex-spacebetween"
            v-else
            v-for="(item, index) in attentionlist"
            :key="index"
            :class="atPresent == index ? 'message_pitch' : ''"
            @click="goSelect(item, '', item.id)"
          >
            <div class="flex">
              <img :src="item.avatar" alt="" class="message_head" />
              <div>
                <div class="font-14" style="display: flex; align-items: center">
                  <div>
                    {{ item.nickname }}
                  </div>
                  <div
                    class="pageSex"
                    style="margin-left: 6px"
                    v-if="item.sex == 0"
                  >
                    <img
                      src="../../image/message/25.png"
                      alt=""
                      style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      "
                    />
                    <div>
                      {{ item.age }}
                    </div>
                  </div>
                  <div
                    class="pageSex1"
                    style="margin-left: 6px"
                    v-if="item.sex == 1"
                  >
                    <img
                      src="../../image/message/25.png"
                      alt=""
                      style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      "
                    />
                    <div>
                      {{ item.age }}
                    </div>
                  </div>
                </div>
                <div class="font-12" style="color: #8e92b1">
                  {{ item.personalSignature }}
                </div>
              </div>
            </div>
            <img
              v-if="item.isConcern == 2"
              src="../../image/message/4.png"
              alt=""
              class="message_icon"
            />
          </div>
        </div>
        <div class="message_list" v-if="tabType == 2">
          <div
            v-if="attentionlist.length == 0"
            class="flex flex-content"
            style="flex-direction: column"
          >
            <img
              src="../../image/room/wu.png"
              alt=""
              style="width: 120px; margin-top: 240px"
            />
            <p style="margin-top: 12px">暂无数据</p>
          </div>
          <div
            class="message_item flex-spacebetween"
            v-else
            v-for="(item, index) in attentionlist"
            :key="index"
            :class="atPresent == index ? 'message_pitch' : ''"
            @click="goSelect(item, '', item.id)"
          >
            <div class="flex">
              <img :src="item.avatar" alt="" class="message_head" />
              <div>
                <div class="font-14" style="display: flex; align-items: center">
                  <div>
                    {{ item.nickname }}
                  </div>
                  <div
                    class="pageSex"
                    style="margin-left: 6px"
                    v-if="item.sex == 0"
                  >
                    <img
                      src="../../image/message/25.png"
                      alt=""
                      style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      "
                    />
                    <div>
                      {{ item.age }}
                    </div>
                  </div>
                  <div
                    class="pageSex1"
                    style="margin-left: 6px"
                    v-if="item.sex == 1"
                  >
                    <img
                      src="../../image/message/25.png"
                      alt=""
                      style="
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        margin-right: 2px;
                      "
                    />
                    <div>
                      {{ item.age }}
                    </div>
                  </div>
                </div>
                <div class="font-12" style="color: #8e92b1">
                  {{ item.personalSignature }}
                </div>
              </div>
            </div>
            <img
              v-if="item.isConcern == 2"
              src="../../image/message/4.png"
              alt=""
              class="message_icon"
            />
          </div>
        </div>

        <!-- 通讯录 -->

        <div class="message_list" v-if="tabType == 3">
          <div
            class="flex"
            style="
              color: #333;
              align-items: center;
              padding: 14px;
              box-sizing: border-box;
            "
          >
            <input class="inpMassage" type="text" />
            <img
              src="../../static/user24.png"
              style="width: 20px; height: 20px; margin-left: 14px"
              alt=""
            />
          </div>
          <div class="massageBtn">
            <div
              :class="
                tongMassage == 0 ? 'massageBtnFlex' : 'massageBtnFlexFriend'
              "
              @click="friendBtn(0)"
            >
              好友
            </div>
            <div
              :class="
                tongMassage == 1 ? 'massageBtnFlex' : 'massageBtnFlexFriend'
              "
              @click="friendBtn(1)"
            >
              分组
            </div>
            <div
              :class="
                tongMassage == 2 ? 'massageBtnFlex' : 'massageBtnFlexFriend'
              "
              @click="friendBtn(2)"
            >
              群聊
            </div>
          </div>
          <div>
            <div style="padding: 14px; margin-top: 24px" v-if="tongMassage==0">
              <div class="flex" v-for="item in tongxuArr">
                <img
                  :src="item.avatar"
                  style="
                    width: 40px;
                    height: 40px;
                    margin-left: 14px;
                    border-radius: 50%;
                  "
                  alt=""
                />
                <div style="margin-left: 10px">
                  <div class="flex">
                    <div style="color: #333">{{ item.nickName }}</div>
                    <div class="messagePerson flex" style="margin-left: 8px">
                      <img
                        style="
                          width: 12px;
                          height: 12px;
                          margin-left: 4px;
                          margin-top: 4px;
                        "
                        src="../../static/user25.png"
                        alt=""
                      />
                      <div
                        style="
                          margin-left: 4px;
                          margin-top: 4px;
                          margin-right: 4px;
                        "
                      >
                        22
                      </div>
                    </div>
                  </div>
                  <div class="tongxunText" style="margin-top: 5px">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 14px; margin-top: 24px" v-if="tongMassage==2">
              <div class="flex" style="align-items: center;" v-for="item in groupArr">
                <img
                  :src="item.avatar"
                  style="
                    width: 40px;
                    height: 40px;
                    margin-left: 14px;
                    border-radius: 50%;
                  "
                  alt=""
                />
                <div style="margin-left: 10px">
                  <div class="flex">
                    <div style="color: #333">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 14px; margin-top: 24px" v-if="tongMassage==1">
              <el-tree
              :data="friendsArr"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
            <template v-slot="{data}">
              <div>
                <div style="display:flex; 
                align-items: center;" @click="goBtnMessage(data)">
                  <img style="width:32px;height:32px;border-radius: 50%;" v-if="data.avatar" :src="data.avatar"></img>
                  <div style="margin-left:4px">
                    <div :style="data.avatar?'line-height:16px':''">{{data.groupingName}}</div> 
                    <div class="textOv" :style="data.avatar?'line-height:16px':''" v-if="data.avatar">{{data.title}}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-tree>
            </div>

          </div>
        </div>
        <!-- 我的消息 -->
        <div class="message_list" v-if="tabType == 0">
          <!-- 狗书公告 -->
          <div
            class="message_item flex-aling"
            v-for="(item, index) in informList"
            :key="index"
            :class="atPresent == index + 1 ? 'message_pitch' : ''"
            @click.stop="queryAllList(item, index + 1)"
          >
            <div v-if="index == 0 && platformNum != 0" class="num_icon">
              {{ platformNum }}
            </div>
            <div v-if="index == 1 && systemNum != 0" class="num_icon">
              {{ systemNum }}
            </div>
            <div v-if="index == 2 && orderNumUnread != 0" class="num_icon">
              {{ orderNumUnread }}
            </div>
            <div v-if="index == 3 && sendOrdersNum != 0" class="num_icon">
              {{ sendOrdersNum }}
            </div>
            <div v-if="index == 4 && interactionNum != 0" class="num_icon">
              {{ interactionNum }}
            </div>
            <div class="flex">
              <img :src="item.imgUrl" alt="" class="message_head" />
              <div style="width: 200px">
                <div class="font-14 flex-spacebetween">
                  <div>{{ item.name }}</div>
                  <div
                    class="font-12"
                    style="color: #6a6a94"
                    v-for="timeItem in newestMessage"
                    :key="timeItem.createTime"
                  >
                    <span v-if="index + 1 == timeItem.type">{{
                      timeItem.createTime
                    }}</span>
                  </div>
                </div>
                <div
                  class="font-12 text-cut"
                  style="color: #8e92b1"
                  v-for="timeItem in newestMessage"
                  :key="timeItem.createTime"
                >
                  <span v-if="index + 1 == timeItem.type">{{
                    timeItem.title
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 会话列表 -->
          <div
            class="message_item flex-spacebetween"
            @click="selectUserChat(dialogueItem)"
            v-for="dialogueItem in dialogueList"
            :key="dialogueItem.conversationID"
          >
            <div class="flex">
              <div class="unreadNum" v-if="dialogueItem.unreadCount > 0">
                {{ dialogueItem.unreadCount }}
              </div>
              <img
                :src="dialogueItem.userProfile.avatar"
                alt=""
                class="message_head"
              />
              <div>
                <div class="font-14">{{ dialogueItem.userProfile.nick }}</div>
                <div
                  class="font-12 text-cut"
                  style="color: #8e92b1; width: 160px"
                  v-if="dialogueItem.lastMessage.type == 'TIMImageElem'"
                >
                  [图片]
                </div>
                <div
                  class="font-12 text-cut"
                  style="color: #8e92b1; width: 160px"
                  v-else
                >
                  {{ dialogueItem.lastMessage.messageForShow }}
                </div>
              </div>
            </div>
            <img src="../../image/message/4.png" alt="" class="message_icon" />
          </div>
        </div>
        <!-- 消息列表end -->
        <!-- 消息内容 -->
        <div class="message_content" v-if="tabType == 0">
          <div
            class="messageType_box"
            v-if="pitchMessageType.name && !userChatType"
          >
            <!-- 标题 -->
            <div class="messageTitle" v-if="messageList.length != 0">
              {{ pitchMessageType.name }}
            </div>
            <div
              style="
                width: 648px;
                height: 0px;
                opacity: 0.11;
                border: 1px solid #ffffff;
              "
            ></div>
            <!-- 消息内容 -->
            <img
              src="../../image/message/5.png"
              v-if="messageList.length == 0"
              alt=""
              class="vacancy"
            />
            <div class="allMsg_box">
              <div
                style="width: 100%"
                v-for="(item, key0) in messageList"
                :key="key0"
              >
                <!-- 时间 -->
                <div class="flex-content font-12" style="margin: 18px 0 13px 0">
                  {{ item.createTime }}
                </div>
                <!-- 内容 -->
                <div class="flex">
                  <img
                    :src="pitchMessageType.imgUrl"
                    alt=""
                    style="margin: 0 15px 0 24px; width: 42px; height: 42px"
                  />
                  <!-- 派单消息 -->
                  <div class="content_box" v-if="item.type == 4">
                    <div class="font-14">类目：{{ item.labelName }}</div>
                    <div class="flex-spacebetween">
                      <div class="font-12 send_style">
                        <p>
                          等级要求：{{ item.segment ? item.segment : "不限" }}
                        </p>
                        <p>
                          性别：{{
                            item.sex == 1 ? "女" : item.sex == 2 ? "男" : "保密"
                          }}
                        </p>
                        <p>
                          派单人：{{ item.disName ? item.disName : "暂无" }}
                        </p>
                        <p>备注：{{ item.remark ? item.remark : "暂无" }}</p>
                      </div>
                      <div
                        style="position: relative; width: 50px"
                        v-if="item.status == 0"
                      >
                        <div class="send_status">派单中</div>
                        <div class="roomSkip" @click.stop="skipOrder(item)">
                          立即跳单
                        </div>
                      </div>
                      <div style="position: relative; width: 50px" v-else>
                        <div class="send_status">已结束</div>
                      </div>
                    </div>
                  </div>
                  <div class="content_box" v-else style="position: relative">
                    <div class="font-14">{{ item.title }}</div>
                    <div
                      v-html="item.content"
                      class="font-12"
                      style="color: #8e92b1; margin-top: 8px"
                    ></div>

                    <div v-if="item.type == 1">
                      <img
                        :src="item.picture"
                        v-if="item.picture"
                        alt=""
                        class="img_style"
                      />
                    </div>

                    <!-- 待续约提示 -->
                    <div
                      v-if="item.surtype == '0'"
                      class="roomSkip"
                      style="position: relative; left: 370px"
                      @click.stop="
                        renewalShow = true;
                        msgId = item.id;
                      "
                    >
                      去续约
                    </div>
                    <div
                      v-if="item.surtype == '1'"
                      style="position: relative; left: 370px; font-size: 12px"
                    >
                      已续约
                    </div>
                    <div
                      v-if="item.surtype == '2'"
                      style="position: relative; left: 370px; font-size: 12px"
                    >
                      已拒绝续约
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 消息内容end -->
          </div>
          <!-- 用户单聊 -->
          <div class="messageType_box" v-else-if="userChatType">
            <!-- 顶部 -->
            <div class="userChat_top flex-spacebetween">
              <div>
                <div class="flex">
                  <span
                    @click="checkOtherPage(pitchChatUser)"
                    class="font-18 pitchChatUserName"
                    >{{ pitchChatUser.nickname }}</span
                  >
                  <div
                    class="userChat_age flex-content"
                    :style="
                      pitchChatUser.sex == 1
                        ? 'background: #ff9280;'
                        : 'background: #85BCFE;'
                    "
                  >
                    <img
                      src="../../image/message/25.png"
                      style="margin-right: 4px"
                      alt=""
                      v-if="pitchChatUser.sex == 1"
                    />
                    <img
                      src="../../image/message/25.png"
                      style="margin-right: 4px"
                      alt=""
                      v-else
                    />
                    {{ pitchChatUser.age }}
                  </div>
                </div>
                <div class="flex-aling" style="margin-top: 8px">
                  <div class="userChat_topIcon">
                    <span
                      v-if="pitchChatUser.onlineStatus == 0"
                      style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: red;
                        border-radius: 50%;
                        margin-right: 4px;
                      "
                    ></span>
                    <span
                      v-if="pitchChatUser.onlineStatus == 1"
                      style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "
                    ></span>
                    <span
                      v-if="pitchChatUser.onlineStatus == 2"
                      style="
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: rgba(58, 229, 26, 1);
                        border-radius: 50%;
                        margin-right: 4px;
                      "
                    ></span>
                    {{
                      pitchChatUser.onlineStatus == 0
                        ? "不在线"
                        : pitchChatUser.onlineStatus == 1
                        ? "在线"
                        : pitchChatUser.onlineStatus == 2
                        ? "房间中"
                        : ""
                    }}
                  </div>
                  <div class="font-14">ID {{ pitchChatUser.userNo }}</div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="userChat_attentionIcon flex-content"
                  style="background: #ccc"
                  v-if="pitchChatUser.isFollow == 1"
                  @click="stopFollowUser()"
                >
                  已关注
                </div>
                <div
                  class="userChat_attentionIcon flex-content"
                  v-else
                  @click="followUser()"
                >
                  关注
                </div>
                <img
                  src="../../image/message/13.png"
                  alt=""
                  class="userChat_img"
                  @click.stop="blockShow = true"
                />
                <img
                  src="../../image/message/14.png"
                  alt=""
                  class="userChat_img"
                  @click.stop="reportShow = true"
                />
              </div>
            </div>
            <div
              class="manitoSkill flex-aling"
              v-if="pitchChatUser && pitchChatUser.userSkillVos.length != 0"
            >
              <img
                src="../../image/message/18.png"
                alt=""
                @click="skillCut(1)"
              />
              <img
                :src="manitoSkillData.skillLogo"
                alt=""
                style="width: 63px; height: 63px; margin: 0 11px"
              />
              <div style="width: 170px">
                <div class="font-16" style="color: #333">
                  {{ manitoSkillData.skillName }}
                </div>
                <div class="font-12" style="margin-top: 19px; color: #333">
                  {{ manitoSkillData.skillLevel }}
                </div>
              </div>
              <div style="position: relative; width: 170px">
                <span
                  style="
                    color: rgba(33, 33, 33, 1);
                    font-size: 18px;
                    font-weight: 600;
                  "
                  >{{ manitoSkillData.price }}</span
                >
                <span style="font-size: 10px; color: #333">元宝/局</span>
                <!-- <div class="discounts_icon flex-content">800元宝</div> -->
              </div>
              <div class="flex-aling">
                <div
                  style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  "
                  @click="orderAddition(1)"
                >
                  -
                </div>
                <span
                  style="
                    display: inline-block;
                    width: 30px;
                    text-align: center;
                    color: #333;
                  "
                >
                  {{ orderNum }}
                </span>
                <div
                  style="
                    width: 22px;
                    height: 22px;
                    line-hegiht: 22px;
                    text-align: center;
                    background: #ffffff;
                    color: #000;
                  "
                  @click="orderAddition(2)"
                >
                  +
                </div>
                <!-- <img
                  src="../../image/message/21.png"
                  alt=""
                  
                /> -->
              </div>
              <div
                class="order_btn flex-content"
                style="margin-left: 23px"
                @click="placeSkipChange()"
              >
                我要下单
              </div>
              <img
                src="../../image/message/19.png"
                alt=""
                style="margin-left: 15px"
                @click="skillCut(2)"
              />
            </div>
            <!-- 聊天内容 -->
            <div
              class="userChat_content"
              :style="{
                height: pitchChatUser.userSkillVos.length != 0 ? '380px' : '',
              }"
            >
              <div
                class="chat_box"
                v-for="(item, index) in chatList"
                :key="index"
              >
                <!-- 我发送的消息 -->
                <div class="my flex" v-if="item.flow == 'out'">
                  <div v-if="item.type == 'TIMVideoFileElem'">
                    <video width="320" height="240" controls>
                      <source
                        :src="item.payload.remoteVideoUrl"
                        type="video/webm"
                      />
                    </video>
                  </div>
                  <div class="my_content" v-if="item.type == 'TIMTextElem'">
                    {{ item.payload.text }}
                  </div>
                  <div class="my_content" v-if="item.type == 'TIMCustomElem'">
                    {{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}

                    {{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
                  </div>
                  <div class="my_content" v-if="item.type == 'TIMImageElem'">
                    <img
                      :src="item.payload.imageInfoArray[0].imageUrl"
                      alt=""
                      class="chatImgUrl"
                    />
                  </div>
                  <img :src="item.avatar" alt="" class="userHead" />
                </div>
                <!-- 对方发送的消息 -->
                <div class="other flex" v-else>
                  <img :src="item.avatar" alt="" class="userHead" />
                  <div class="my_content" v-if="item.type == 'TIMCustomElem'">
                    {{
                      JSON.parse(item.payload.data).actionType == 1
                        ? "发起通话"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 4
                        ? "拒绝通话"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 5
                        ? "未应答"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 3
                        ? "已接听"
                        : ""
                    }}
                    {{
                      JSON.parse(item.payload.data).actionType == 2
                        ? "取消通话"
                        : ""
                    }}
                  </div>
                  <div v-if="item.type == 'TIMVideoFileElem'">
                    <video width="320" height="240" controls>
                      <source
                        :src="item.payload.remoteVideoUrl"
                        type="video/webm"
                      />
                    </video>
                  </div>
                  <div class="other_content" v-if="item.type == 'TIMTextElem'">
                    {{ item.payload.text }}
                  </div>
                  <div class="my_content" v-if="item.type == 'TIMImageElem'">
                    <img
                      :src="item.payload.imageInfoArray[0].imageUrl"
                      alt=""
                      class="chatImgUrl"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 输入内容 -->
            <div class="userChat_inputBox">
              <div class="flex-aling">
                <el-upload
                  ref="upload"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :with-credentials="true"
                  :on-success="handleAvatarSuccess"
                  accept=".png,.jpg,.jpeg"
                  :on-change="handleChange"
                  id="testPasteInput"
                >
                  <img
                    src="../../image/message/15.png"
                    alt=""
                    class="iconImg"
                    style="margin-top:5px;margin-right:5px"
                  />
                </el-upload>
                <img
                  v-if="0"
                  src="../../image/message/16.png"
                  alt=""
                  class="iconImg"
                  @click="clearTheChatShow = true"
                />

                <!-- <el-upload
                  ref="upload"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :with-credentials="true"
                  :on-success="handleAvatarSuccess"
                  accept=".MP3"
                  :on-change="handleChange"
                  id="testPasteInput"
                >
                <img
                src="../../image/message/31.png"
                alt=""
                class="iconImg"
                @click="sendShi"
              />
                </el-upload> -->

                <el-upload
                  v-if="isSendShiping"
                  class="avatar-uploader"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :limit="1"
                  accept=".mp4,"
                  :on-change="handleChange"
                >
                  <img
                    style="margin-right: 5px; width: 22px; height: 22px"
                    src="../../image/message/31.png"
                    alt=""
                  />
                </el-upload>
                <img
                  v-else
                  @click="$message('今日发送视频次数已用完')"
                  style="margin-right: 5px; width: 24px; height: 24px"
                  src="../../image/message/31.png"
                  alt=""
                />
                <img
                  src="../../image/message/30.png"
                  alt=""
                  class="iconImg"
                  @click="callTIM(1)"
                />
                <img
                  src="../../image/message/29.png"
                  alt=""
                  class="iconImg"
                  @click="callTIM(2)"
                />
                <img
                  v-if="0"
                  src="../../image/message/28.png"
                  alt=""
                  class="iconImg"
                  @click="
                    isSet = true;
                    getChargeSetting();
                  "
                />
                <img
                  v-if="0"
                  src="../../image/message/17.png"
                  alt=""
                  class="iconImg"
                  @click="clearTheChatShow = true"
                />
              </div>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入..."
                resize="none"
                v-model="chatText"
                @keydown.enter.native.prevent="sendTextChange($event)"
              >
              </el-input>
              <div
                class="sendBtn flex-content"
                @click.stop="sendTextChange($event)"
              >
                发送
              </div>
            </div>
          </div>
          <!-- 用户单聊end -->
          <img src="../../image/message/5.png" alt="" class="vacancy" v-else />
        </div>

        <div
          class="message_content"
          v-if="tabType == 3"
          style="padding: 24px 20px; box-sizing: border-box"
        >
          <div class="flex" style="justify-content: space-between">
            <div></div>
            <div>
              <img src="../../static/user26.png" alt="" />
              <img
                style="margin-left: 24px"
                src="../../static/user27.png"
                alt=""
              />
            </div>
          </div>
          <div class="messageLine"></div>
          <div class="messageContent"  >
            <div
           
              class="flex"
              style="
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
              "
            >
              <div class="touTitle" style="padding-left: 16px">投诉</div>
              <!-- <img src="../../static/user28.png" alt="" /> -->
              <div class="touTitleRight">TA的主页</div>
            </div>
            <div style="margin-top: 20px">
              <div style="width: 70px; margin: 0 auto">
                <img
                  style="width: 70px; height: 70px; border-radius: 50%;"
                  :src="contentData.avatar"
                  alt=""
                />
              </div>
              <div
                style="
                  width: 100%;
                  color: #333;
                  text-align: center;
                  margin-top: 16px;
                "
              >
                {{ contentData.nickname }}
              </div>
              <div
                class="flex arrdessData"
                style="align-items: center; margin: 8px auto 0; width: 230px"
              >
                ID：{{ contentData.id }}
                <img
                  style="width: 16px; height: 16px; margin-left: 14px"
                  src="../../static/user30.png"
                  alt=""
                />
              </div>
              <div
                class="arrdessData"
                style="
                  align-items: center;
                  width: 100%;
                  text-align: center;
                  margin-top: 16px;
                  margin-bottom: 10px;
                "
              >
                位于 广州市的 处女座小姐姐
              </div>
              <div class="titleDur">少年自由则国自由</div>
            </div>
          </div>
          <div style="width: 360px; margin: 26px auto" class="flex">
            <div class="shareBtn" @click="shareBtnPop">分享</div>
            <div class="sendMassage" @click="sendMassage">发消息</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 清除聊天记录提醒弹窗 -->
    <div class="popUp_box" v-if="clearTheChatShow">
      <div class="remid_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="clearTheChatShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <img src="../../static/129.png" alt="" style="margin-top: 40px" />
          <div style="margin-top: 20px; color: #333">
            清空记录将无法恢复，确认清空吗？
          </div>
          <div class="flex" style="margin: 68px 0 40px 0">
            <div
              class="remind_btn flex-content"
              @click="clearTheChatShow = false"
            >
              再想想
            </div>
            <div
              class="remind_btn flex-content"
              style="
                background: rgba(255, 167, 0, 0.2);
                color: #ffa700;
                margin-left: 20px;
              "
              @click="deleteChattingRecords()"
            >
              确认
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 拉黑提醒弹窗 -->
    <div class="popUp_box" v-if="blockShow">
      <div class="remid_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img src="../../static/quit.png" alt="" @click="blockShow = false" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <img src="../../static/129.png" alt="" style="margin-top: 40px" />
          <div class="flex-dir" style="margin-top: 20px">
            <span style="color: #333">{{
              pitchChatUser.inBlack == 1
                ? "是否将对方从黑名单中移出"
                : "拉黑后将不会收到对方的消息，该用户将无法对你下单"
            }}</span>
            <span style="color: #333">{{
              pitchChatUser.inBlack == 1 ? "确认移出吗？" : "确认拉黑吗？"
            }}</span>
          </div>
          <div class="flex" style="margin: 68px 0 40px 0">
            <div class="remind_btn flex-content" @click="blockShow = false">
              再想想
            </div>
            <div
              class="remind_btn flex-content"
              style="
                background: rgba(255, 167, 0, 0.2);
                margin-left: 20px;
                color: #ffa700;
              "
              @click="addUserBlack(pitchChatUser)"
            >
              确认
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popUp_box" v-if="isGuan">
      <div class="remid_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">选择分组</div>
          <img src="../../static/quit.png" alt="" @click="isGuan = false" />
        </div>
        <!-- 头部end -->
        <div style="height: 219px">
          <div style="width: 382px; margin: 48px auto 60px">
            <el-select
              v-model="FenValue"
              placeholder="请选择"
              style="width: 382px"
            >
              <el-option
                v-for="item in goodsFen"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="BtnSubmit" @click="BtnSubmit">确认</div>
        </div>
      </div>
    </div>
    <div class="popUp_box" style="color: #000" v-if="isSet">
      <div class="remid_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">私信设置</div>
          <img src="../../static/quit.png" alt="" @click="isSet = false" />
        </div>
        <div class="contentSi" style="margin-top: 24px">
          <div>免费</div>
          <el-switch
            v-model="siValue1"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="mianfeiChange"
          >
          </el-switch>
        </div>
        <div class="contentSi">
          <div>付费</div>
          <el-switch
            v-model="siValue2"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="mianfeiChange2"
          >
          </el-switch>
        </div>
        <div style="height: 150px">
          <div class="contentSi1" v-if="siValue2">
            <div>音频通话收费</div>
            <el-select v-model="audioValue" placeholder="请选择">
              <el-option
                v-for="item in audioArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="contentSi1" v-if="siValue2">
            <div>视频通话收费</div>
            <el-select v-model="videoValue" placeholder="请选择">
              <el-option
                v-for="item in videoArr"
                :key="item.value"
                :label="item.title"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="contentSi1" v-if="siValue2">
            <div>短视频收费</div>
            <el-select v-model="shortVideoValue" placeholder="请选择">
              <el-option
                v-for="item in shortVideoArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="BtnSubmit" @click="BtnSubmit1">确认提交</div>
        <div style="height: 20px; width: 100%"></div>
        <!-- 头部end -->
        <!-- <div style="height:219px">
          <div style="width:382px;margin:48px auto 60px">
            <el-select v-model="FenValue" placeholder="请选择" style="width:382px">
              <el-option
                v-for="item in goodsFen"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          
        </div> -->
      </div>
    </div>
    <!-- 举报弹窗 -->
    <div class="popUp_box" v-if="reportShow">
      <div class="remid_box" style="width: 679px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">举报用户</div>
          <img src="../../static/quit.png" alt="" @click="reportShow = false" />
        </div>
        <div class="report_title">
          举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
        </div>
        <!-- 头部end -->
        <div class="report_from">
          <div class="report_fromTitle">举报原因</div>
          <el-select v-model="reportValue" placeholder="请选择">
            <el-option
              v-for="item in reportOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="report_fromTitle">
            举报原因
            <span style="color: #6a6a94; margin-left: 11px"
              >补充说明，协助审核人员尽快处理</span
            >
          </div>
          <el-input
            type="textarea"
            placeholder="填写详情说明"
            v-model="reportTextarea"
            maxlength="200"
            :rows="4"
            show-word-limit
            resize="none"
            class="textarea"
          >
          </el-input>
          <div class="report_fromTitle">截图视频</div>
          <div class="flex-aling">
            <div
              class="imgBox"
              v-for="(item, index) in reportImgs"
              :key="index"
            >
              <img :src="item" alt="" style="width: 100%; height: 100%" />
              <img
                src="../../static/127.png"
                alt=""
                class="imgDeleIcon"
                @click.stop="imgDeleChange(index)"
              />
            </div>
            <el-upload
              action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
              :limit="4"
              :show-file-list="false"
              list-type="picture-card"
              :on-success="reportImgSuccess"
              v-if="reportImgs.length < 4"
              id="imagePicker"
            >
              <img src="../../static/183.png" alt="" />
            </el-upload>
          </div>
          <div style="color: #6a6a94; font-size: 10px; margin-top: 20px">
            最多4张图片，1个视频
          </div>
          <div class="report_btn flex-content" @click="addReport()">
            确认提交
          </div>
        </div>
      </div>
    </div>
    <!-- 电话 -->
    <div
      class="popUp_box1"
      :style="'background-image:url(' + atuserObj.userProfile.avatar + ')'"
      v-if="isCall"
    >
      <div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5)">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin: 10px 20px 0;
            overflow: hidden;
          "
        >
          <div></div>
          <div @click="isCall = false">×</div>
        </div>
        <div
          style="
            width: 170px;
            height: 170px;
            border-radius: 16px;
            margin: 124px auto 20px;
          "
        >
          <img
            style="width: 100%; height: 100%"
            :src="atuserObj.userProfile.avatar"
            alt=""
          />
        </div>
        <div style="text-align: center">
          <div>{{ atuserObj.userProfile.nick }}</div>
          <div v-if="1" style="margin-top: 16px">邀请你进行语音通话</div>
          <div v-if="0">等待对方接受邀请...</div>
        </div>
        <!-- <div
          style="
            width: 230px;
            height: 40px;
            background: rgba(0, 0, 0, 0.7);
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 204px auto 0;
          "
        >
          对方已挂断，通话结束
        </div> -->
        <div
          v-if="guaduanIsSHow"
          style="
            width: 230px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
            margin: 204px auto 0;
          "
        >
          {{ courentTime }}
        </div>
        <div
          style="display: flex; justify-content: space-between"
          :style="
            guaduanIsSHow ? 'margin: 20px 113px;' : 'margin: 270px 113px;'
          "
        >
          <div v-if="guaduanIsSHow && isAudio" @click="closeMicrophone">
            <img
              style="width: 48px; height: 48px"
              src="../../static/230.png"
              alt=""
            />
            <div style="width: 48px; text-align: center">麦克风</div>
          </div>
          <div v-if="guaduanIsSHow && !isAudio" @click="openMicrophone">
            <img
              style="width: 48px; height: 48px"
              src="../../static/231.png"
              alt=""
            />
            <div style="width: 48px; text-align: center">麦克风</div>
          </div>

          <div @click="overCall(1)" v-if="guaduanIsSHow">
            <img
              style="width: 48px; height: 48px"
              src="../../static/228.png"
              alt=""
            />
            <div style="width: 48px; text-align: center">挂断</div>
          </div>
          <div @click="DownBtn(1)" v-if="isBackCall">
            <img
              style="width: 48px; height: 48px"
              src="../../static/228.png"
              alt=""
            />
            <div style="width: 48px; text-align: center">拒绝</div>
          </div>

          <div @click="DownOn(1)" v-if="isBackCall">
            <img
              style="width: 48px; height: 48px"
              src="../../static/229.png"
              alt=""
            />
            <div style="width: 48px; text-align: center">接听</div>
          </div>
        </div>
      </div>
    </div>

    <div class="popUp_box2" v-if="isShopIng">
      <div class="popUp_Title">
        <div class="popUp_TitleFlex">
          <div class="">
            <img class="popUp_TitleImg" src="../../static/to3.png" alt="" />
          </div>
          <div style="margin-left: 10px">
            <div>{{ isShopingBei.nickname }}</div>
            <div>邀请你加入视频通话</div>
          </div>
        </div>
        <div>
          <img src="../../static/169.png" alt="" />
        </div>
      </div>
      <div class="popUp_TitleBottom">
        <div class="popUp_TitleBottomFlex">
          <div>
            <img
              style="width: 48px; height: 48px"
              src="../../static/230.png"
              alt=""
            />
            <div style="width: 48px; text-align: center; font-size: 14px">
              语音
            </div>
          </div>
          <div v-if="isflagJie" @click="DownOn(2)">
            <img
              style="width: 48px; height: 48px"
              src="../../static/229.png"
              alt=""
            />
            <div style="width: 48px; text-align: center; font-size: 14px">
              接听
            </div>
          </div>
          <div @click="overCall(2)" v-if="!isflagJie">
            <img
              style="width: 48px; height: 48px"
              src="../../static/228.png"
              alt=""
            />
            <div style="width: 48px; text-align: center; font-size: 14px">
              挂断
            </div>
          </div>
          <div @click="DownBtn(2)" v-else>
            <img
              style="width: 48px; height: 48px"
              src="../../static/228.png"
              alt=""
            />
            <div style="width: 48px; text-align: center; font-size: 14px">
              拒绝
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popUp_box" v-if="renewalShow">
      <div class="remid_box" style="width: 679px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">续约通知</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="renewalShow = false"
          />
        </div>
        <!-- 头部end -->
        <div
          class="flex-content"
          style="flex-direction: column; margin-top: 16px"
        >
          <h3>续约通知</h3>
          <div>你的大神资格即将到期,请及时续约！</div>
        </div>
        <div class="flex-content" style="margin: 16px 0">
          <div
            class="renewalBtn"
            style="background: #9c9c9c"
            @click.stop="setGreatGodRenewal(msgId, 0)"
          >
            拒绝
          </div>
          <div class="renewalBtn" @click.stop="setGreatGodRenewal(msgId, 1)">
            同意
          </div>
        </div>
      </div>
    </div>

    <div class="popUp_box3" v-if="popUp_message">
      <div
        class="flex popUp_header"
        style="
          justify-content: space-between;
          padding: 16px;
          box-sizing: border-box;
        "
      >
        <div></div>
        <div class="chooseLiao">选择一个聊天</div>
        <img
          style="width: 20px; height: 20px"
          src="../../static/user31.png"
          alt=""
        />
      </div>

      <div>
        <input class="searchInp" type="text" name="" id="">
      </div>
        
    </div>
  </div>
</template>

<script>
import othersHome from "./othersHome.vue";
export default {
  name: "",
  props: {},
  components: { othersHome },
  data() {
    return {
      popUp_message: false,
      othersShow: false,
      renewalShow: false, //续约弹窗
      msgId: "", //消息id
      tabType: 3,
      manitoId: "", //大神ID
      informList: [
        {
          imgUrl: require("../../image/message/6.png"),
          name: "狗书公告",
        },
        // {
        //   imgUrl:require("../../image/message/7.png"),
        //   name:'公会通知'
        // },
        {
          imgUrl: require("../../image/message/8.png"),
          name: "系统通知",
        },
        {
          imgUrl: require("../../image/message/9.png"),
          name: "订单消息",
        },
        {
          imgUrl: require("../../image/message/10.png"),
          name: "派单信息",
        },
        {
          imgUrl: require("../../image/message/11.png"),
          name: "互动消息",
        },
      ],
      tongMassage: 0,
      isBackCall: false,
      isCall: false,
      files1: "",
      atPresent: "",
      isCallShoping: false,
      atuserObj: {}, //当前聊天用户
      newestMessage: [], //最新消息
      pitchMessageType: {}, //选中消息类型
      messageList: [], //消息数据
      userChatType: false, //用户聊天
      chatText: "", //输入内容
      dialogueList: [], //会话列表
      attentionlist: [], //关注&&粉丝列表
      pitchChatUser: {}, //选中用户数据
      chatList: [], //聊天内容
      myUser: {}, //我的数据
      clearTheChatShow: false, //清除聊天记录弹窗
      blockShow: false, //拉黑提醒弹窗
      isGuan: false, //分组弹窗
      reportShow: false, //举报弹窗
      reportOptions: [], //举报原因
      reportValue: "", //举报内容
      reportTextarea: "", //举报说明
      reportImgs: [], //图片
      manitoSkillData: {}, //大神技能数据
      defaultProps: {
        children: "children",
        label: "nickname",
      },
      orderNum: 1, //订单数量
      platformNum: 0, //平台消息未读数
      systemNum: 0, //系统通知 未读数
      orderNumUnread: 0, //订单消息未读数
      sendOrdersNum: 0, //派单消息未读数
      interactionNum: 0, //互动消息未读数
      guaduanIsSHow: false,
      isAudio: false,
      siValue1: true,
      isSet: false,
      siValue2: false,
      goodsFen: [],
      FenValue: "",
      audioArr: [],
      audioValue: "",
      videoArr: [],
      videoValue: "",
      shortVideoArr: [],
      shortVideoValue: "",
      isShopIng: false,
      isShopingBei: {},
      isflagJie: false,
      courentTime: "",
      h: 0, //定义时，分，秒，毫秒并初始化为0；
      m: 0,
      ms: 0,
      s: 0,
      timeClear: "",
      isSendShiping: false,
      tongxuluSearch: "",
      tongxuArr: [],
      groupArr:[],
      friendsArr:[],
      bookData:[],
      contentData:{
        avatar:"",
        id:"",
        nickname:""
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    let TencentCloudChat = this.TencentCloudChat;
    let tuiCallEngine = this.tuiCallEngine;
    let TUICallEvent = this.TUICallEvent;
    let that = this;
    //回显私信
    let userTIMInfo = JSON.parse(localStorage.getItem("userTIMInfo"));
    let promise1 = tuiCallEngine.login({
      userID: userTIMInfo.id,
      userSig: userTIMInfo.userSig,
    });
    promise1
      .then(() => {})
      .catch((error) => {
        console.warn("login error:", error);
      });
    let onMessageReceived = function (event) {
      // event.data - 存储 Message 对象的数组 - [Message]
      const messageList = event.data;
      messageList.forEach((message) => {
        if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
          // 文本消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.TextPayload
          that.chatList.push(message);
          that.scrollToBottom();
        } else if (message.type === TencentCloudChat.TYPES.MSG_IMAGE) {
          that.chatList.push(message);
          that.scrollToBottom();
          // 图片消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.ImagePayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_SOUND) {
          // 音频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.AudioPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_VIDEO) {
          // 视频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.VideoPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_FILE) {
          // 文件消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.FilePayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_CUSTOM) {
          // 自定义消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.CustomPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_MERGER) {
          // 合并消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.MergerPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_LOCATION) {
          // 地理位置消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.LocationPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_GRP_TIP) {
          // 群提示消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupTipPayload
        } else if (message.type === TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE) {
          // 群系统通知 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupSystemNoticePayload
        }
      });
    };
    this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    let onConversationListUpdated = function (event) {
      // console.log(event.data, "会话列表的监听"); // 包含 Conversation 实例的数组
    };
    that.chat.on(
      TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED,
      onConversationListUpdated
    );
  },
  mounted() {
    this.queryLastMessage();
    this.pitchMessageType = this.informList[0];
    this.getUserInfo();
    this.getConversationList(); //获取会话列表
    this.queryReportReason();
    this.getUnreadCount();
    this.getChargesListShi();
    this.getFriendGroupList();
  },
  destroyed() {
    // this.$RongIMLib.removeEventListener(Events.MESSAGES, () => {});
  },
  destroyed() {
    // 页面销毁
    // 移除具体事件的所有监听器
    // this.$RongIMLib.removeEventListeners(Events.MESSAGES);
    // this.$RongIMLib.removeChatRoomEntry(Events.MESSAGES);
  },
  methods: {
    handleNodeClick(e) {
      if (e.userId) {
        e.userProfile = {
          userID: e.userId,
        };
        //
        this.myMessageShow = true;
      }
    },
    mianfeiChange(e) {
      this.siValue1 = e;
      this.siValue2 = !e;
      this.audioValue = "";
      this.shortVideoValue = "";
      this.videoValue = "";
    },
    mianfeiChange2(e) {
      this.siValue1 = !e;
      this.siValue2 = e;
    },
    timer() {
      this.ms = this.ms + 50;
      if (this.ms >= 1000) {
        this.ms = 0;
        this.s = this.s + 1; //秒
      }
      if (this.s >= 60) {
        this.s = 0;
        this.m = this.m + 1; //分钟
      }
      if (this.m >= 60) {
        this.m = 0;
        this.h = this.h + 1; //小时
      }
      // courentTime
      this.courentTime =
        this.toDub(this.h) +
        ":" +
        this.toDub(this.m) +
        ":" +
        this.toDub(this.s) +
        "" /*+this.toDubms(this.ms)+"毫秒"*/;
    },
    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    },
    reset() {
      clearInterval(this.timeClear);
      this.h = 0;
      this.m = 0;
      this.ms = 0;
      this.s = 0;
      this.courentTime = "00:00:00";
    },
    // 打电话唤起
    listenttuiCall() {
      let that = this;
      let handleOnCallReceived = function (event) {
        if (event.callMediaType == 1) {
          that.isBackCall = true;
          that.isCall = true;
        }
        if (event.callMediaType == 2) {
          that.isShopIng = true;
          that.isflagJie = true;
          that.isShopingBei = event.userData;
        }
        let data = {
          userProfile: {
            userID: event.calleeIdList[0],
          },
        };
      };
      this.tuiCallEngine.on(
        this.TUICallEvent.ON_CALL_RECEIVED,
        handleOnCallReceived
      );
    },
    //
    listenttuiCallUser() {
      let that = this;
      let handleOnCallReceived = function (event) {
        this.timeClear = setInterval(that.timer, 50);
      };
      this.tuiCallEngine.on(
        this.TUICallEvent.USER_ACCEPT,
        handleOnCallReceived
      );
    },
    // 打电话拒绝监听
    listenttuiCallNo() {
      let that = this;
      let handleInviteeReject = function (event) {
        // 拒绝视频
        that.isShopIng = false;
        // 拒绝音频
        that.isCall = false;
        that.isBackCall = false;
        that.guaduanIsSHow = false;
      };
      this.tuiCallEngine.on(this.TUICallEvent.REJECT, handleInviteeReject);
    },
    // 监听挂断
    listenttuiCallgua() {
      let that = this;
      let handleInviteeReject = function (event) {
        if (event.callMediaType == 2) {
          that.isShopIng = false;
        }
        if (event.callMediaType == 1) {
          that.isCall = false;
          that.isBackCall = false;
          that.guaduanIsSHow = false;
        }
        that.reset();
      };
      this.tuiCallEngine.on(
        this.TUICallEvent.ON_CALL_CANCELED,
        handleInviteeReject
      );
    },
    listenttuiCallUser() {
      let that = this;
      let handleOnCallReceived = function (event) {
        this.timeClear = setInterval(that.timer, 50);
      };
      this.tuiCallEngine.on(
        this.TUICallEvent.USER_ACCEPT,
        handleOnCallReceived
      );
    },
    friendBtn(type) {
      this.tongMassage = type;
      if(type==0){
        this.tongList()
      }
      if(type==1){
        this.getMyFriendsData()
      }
      // friendsArr
      if(type==2){
        this.groupList()
      }
    },
    groupList() {
      this.$api.groupListGroup({  }).then((res) => {
        if (res.code == 1) {
          this.groupArr=res.data.rows
          // console.log(res.data, "resres");
        } else {
          this.$messages.info(res.msg);
        }
      });
    },
    tongList() {
      console.log(this.$api.bookEntity, "this.$api");
      this.$api.bookEntity({ keywords: this.tongxuluSearch }).then((res) => {
        if (res.code == 1) {
          this.tongxuArr = res.data;
          console.log(res.data, "resres");
        } else {
          this.$messages.info(res.msg);
        }
      });
    },
    // myFriends
    goBtnMessage(data){
      this.$api.userMainInfo({ otherUserId:data.userId }).then((res) => {
        if (res.code == 1) {
          this.contentData=res.data
          console.log(res.data, "resres");
        } else {
          this.$messages.info(res.msg);
        }
      });
      // { keywords: this.tongxuluSearch }
      // this.$api.myFriends().then((res) => {
      //   if (res.code == 1) {
      //     this.friendsArr = res.data;
      //     console.log(res.data, "resres");
      //   } else {
      //     this.$messages.info(res.msg);
      //   }
      // });
    },

    getMyFriendsData() {
      this.friendsArr = [];

      this.$api.myFriends().then((res) => {
        if (res.code == 1) {
          // console.log(res,)

          res.data.forEach((item) => {
            item.nickname = item.groupingName;
          });
          this.$api.followAddressBookList().then((res1) => {
            this.bookData = res1.data;
            res.data.forEach((item) => {
              item.children = [];
              this.bookData.forEach((item1) => {
                if (item.id == item1.groupId) {
                  item1.groupingName = item1.nickName;
                  item.children.push(item1);
                }
              });
              this.friendsArr.push(item);
              console.log(this.friendsArr,'friendsArr')
            });
          });
        }
      });
    },



    // bookEntity

    // 打电话拒绝监听
    listenttuiCallNo() {
      let that = this;
      let handleInviteeReject = function (event) {
        // 拒绝视频
        that.isShopIng = false;
        // 拒绝音频
        that.isCall = false;
        that.isBackCall = false;
        that.guaduanIsSHow = false;
      };
      this.tuiCallEngine.on(this.TUICallEvent.REJECT, handleInviteeReject);
    },
    // 监听挂断
    listenttuiCallgua() {
      let that = this;
      let handleInviteeReject = function (event) {
        if (event.callMediaType == 2) {
          that.isShopIng = false;
        }
        if (event.callMediaType == 1) {
          that.isCall = false;
          that.isBackCall = false;
          that.guaduanIsSHow = false;
        }
        that.reset();
      };
      this.tuiCallEngine.on(
        this.TUICallEvent.ON_CALL_CANCELED,
        handleInviteeReject
      );
    },
    // 通话中挂断
    listenttuiCallgua() {
      let that = this;
      let handleInviteeReject = function (event) {
        if (event.callMediaType == 2) {
          that.isShopIng = false;
        }
        if (event.callMediaType == 1) {
          that.isCall = false;
          that.isBackCall = false;
          that.guaduanIsSHow = false;
        }
        let data = {
          userProfile: {
            userID: event.userID,
          },
        };
        that.reset();
        // that.selectUserChat(data, "", true);
      };
      this.tuiCallEngine.on(this.TUICallEvent.CALLING_END, handleInviteeReject);
    },
    // 拒绝
    DownBtn(type) {
      let that = this;
      this.tuiCallEngine
        .reject()
        .then((res) => {
          if (type == 1) {
            this.guaduanIsSHow = false;
            this.isBackCall = false;
            this.isCall = false;
          }
          if (type == 2) {
            this.isflagJie = true;
            this.isShopIng = false;
          }
        })
        .catch((error) => {
          console.warn("reject error:", error);
        });
    },
    DownOn(type) {
      this.tuiCallEngine
        .accept()
        .then((res) => {
          if (type == 1) {
            this.isBackCall = false;
            this.guaduanIsSHow = true;
          }
          if (type == 2) {
            this.isflagJie = false;
          }
          this.timeClear = setInterval(this.timer, 50);
          this.isAudio = res.audio;
        })
        .catch((error) => {
          console.warn("accept error:", error);
        });
    },
    // 静音
    closeMicrophone() {
      this.tuiCallEngine
        .closeMicrophone()
        .then((res) => {
          this.isAudio = false;
          console.log(
            res,
            "console.log(res);console.log(res);console.log(res);"
          );
          // success
        })
        .catch((error) => {
          console.warn("closeMicrophone error:", error);
        });
    },
    openMicrophone() {
      this.tuiCallEngine
        .openMicrophone()
        .then((res) => {
          // success
          this.isAudio = true;
          console.log(res, "console.log(res);console.log(res);");
        })
        .catch((error) => {
          console.warn("openMicrophone error:", error);
        });
    },
    // 结束通话
    overCall(type) {
      let that = this;
      this.tuiCallEngine
        .hangup()
        .then(() => {
          if (type == 1) {
            this.guaduanIsSHow = false;
            this.isBackCall = false;
            this.isCall = false;
          } else {
            this.isflagJie = true;
            this.isShopIng = false;
          }
          that.reset();
        })
        .catch((error) => {
          console.warn("hangup error:", error);
        });
    },
    callTIM(type) {
      this.isShopingBei.nickname = this.atuserObj.userProfile.nick;
      this.isShopingBei.avatar = this.atuserObj.userProfile.avatar;
      let promise = this.tuiCallEngine.call({
        userID: this.atuserObj.userProfile.userID,
        type: type,
        userData: this.myUser,
      });
      promise
        .then(() => {
          if (type == 1) {
            this.guaduanIsSHow = true;
            this.isCall = true;
          } else {
            this.isflagJie = false;
            this.isShopIng = true;
          }
        })
        .catch((error) => {
          console.warn("call error:", error);
        });
    },
    // 大神续约
    setGreatGodRenewal(messageId, type) {
      /**
       * messageId 	消息id
       * type 是否续约(1续约，0不续约)
       */
      this.$api.greatGodRenewal({ messageId, type }).then((res) => {
        if (res.code == 1) {
          this.queryAllList(this.pitchMessageType, 2);
          this.renewalShow = false;
        } else {
          this.$messages.info(res.msg);
        }
      });
    },
    checkOtherPage(item) {
      this.manitoId = item.id;
      this.othersShow = true;
    },
    getFriendGroupList() {
      this.$api.friendGroupList().then((res) => {
        res.data.forEach((item) => {
          item.label = item.groupingName;
          item.value = item.id;
        });
        this.goodsFen = res.data;
      });
    },

    // 获取消息未读数
    getUnreadCount() {
      this.$api.unreadCount().then((res) => {
        // console.log("消息未读数", res);
        if (res.code == 1) {
          res.data.forEach((item) => {
            //1平台公告 2系统通知 3订单消息 4派单消息 5互动消息
            switch (item.type) {
              case 1: //1平台公告
                this.platformNum = item.unReadCount;
                break;
              case 2: //2系统通知
                this.systemNum = item.unReadCount;
                break;
              case 3: //3订单消息
                this.orderNumUnread = item.unReadCount;
                break;
              case 4: //4派单消息
                this.sendOrdersNum = item.unReadCount;
                break;
              case 5: //5互动消息
                this.interactionNum = item.unReadCount;
                break;
              default:
                break;
            }
          });
        }
      });
    },
    //派单跳单
    skipOrder(i) {
      // 判断聊天室是否存在
      this.$api.enterRoom({ roomId: i.roomId }).then((res) => {
        if (res.code == 1) {
          let data = {
            liveStreamId: res.data.liveStreamingId,
            targetPositionId: "",
            upMicroType: 1,
          };
          // 大神排麦
          this.$api.upMicrophone(data).then((opn) => {
            if (opn.code == 1) {
              this.$router.push({
                path: "/someSingingHall",
                query: {
                  roomId: res.data.id,
                  liveStreamId: res.data.liveStreamingId,
                },
              });
            } else {
              this.$message.info(opn.msg);
            }
          });
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    //滚动条保持最底部方法
    scrollToBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".userChat_content");
        container.scrollTop = container.scrollHeight;
      });
    },
    // 订单数修改
    orderAddition(type) {
      if (this.orderNum == 100) return;
      if (type == 1 && this.orderNum != 1) {
        this.orderNum--;
      } else if (type == 2) {
        this.orderNum++;
      }
    },
    // 收费价格
    getChargesListShi() {
      this.$api.chargesList({ type: "audioCall" }).then((res) => {
        res.data.forEach((item) => {
          item.label = item.text;
        });
        this.audioArr = res.data;
      });
      this.$api.chargesList({ type: "videoCall" }).then((res) => {
        res.data.forEach((item) => {
          item.label = item.text;
        });
        this.videoArr = res.data;
      });
      this.$api.chargesList({ type: "shortVideoCall" }).then((res) => {
        res.data.forEach((item) => {
          item.label = item.text;
        });
        this.shortVideoArr = res.data;
      });
    },
    // 下单跳转
    placeSkipChange() {
      this.$emit("closeMessage");
      this.$emit("placeSkip", this.pitchChatUser.id);
    },
    // 技能切换
    skillCut(type) {
      let skillRank = 0;
      this.pitchChatUser.userSkillVos.forEach((item, index) => {
        // console.log(item);
        if (this.manitoSkillData.skillId == item.skillId) {
          // console.log(this.manitoSkillData.skillId);
          return (skillRank = index);
        }
      });
      if (type == 1 && skillRank == 0) return "";
      if (type == 2 && skillRank == this.pitchChatUser.userSkillVos.length - 1)
        return "";
      if (type == 1) {
        this.manitoSkillData = this.pitchChatUser.userSkillVos[skillRank - 1];
      } else {
        this.manitoSkillData = this.pitchChatUser.userSkillVos[skillRank + 1];
      }
    },
    // 举报
    addReport() {
      let data = {
        content: this.reportTextarea, //	举报内容
        image: this.reportImgs.toString(), //	举报图片（多图逗号分隔）
        reason: this.reportValue, //	举报原因值
        type: 1, //	举报类型（1-用户 2-房间）
        sourceId: this.pitchChatUser.id, //	被举报id
      };
      this.$api.report(data).then((res) => {
        if (res.code == 1) {
          this.reportShow = false;
        }
      });
    },
    // 图片删除
    imgDeleChange(index) {
      this.reportImgs.splice(index, 1);
    },
    handleChange(file, files) {
      this.files1 = file.raw;
    },
    // 举报图片上传
    reportImgSuccess(res) {
      if (res.code == 1) {
        this.reportImgs.push(res.data.url);
      }
    },
    // 举报原因
    queryReportReason() {
      this.$api.queryReportReason().then((res) => {
        if (res.code == 1) {
          this.reportOptions = res.data;
        }
      });
    },
    // 用户拉黑
    async addUserBlack(data) {
      let arr = [];
      let that = this;
      if (data.inBlack == 0) {
        await this.$api.addBlack({ blackUserId: data.id }).then((res) => {
          if (res.code == 1) {
            arr.push(data.id);
            this.chat.addToBlacklist({ userIDList: arr });
            this.blockShow = false;
            this.$message("移入成功");
          }
        });
      } else {
        await this.$api.removeBlack({ blackUserId: data.id }).then((res) => {
          if (res.code == 1) {
            arr.push(data.id);
            this.chat.removeFromBlacklist({ userIDList: arr });
            this.blockShow = false;
            this.$message("移出成功");
          }
        });
      }
      await that.$api.userMainInfo({ otherUserId: data.id }).then((res) => {
        that.pitchChatUser = res.data;
        this.$forceUpdate();
      });
    },
    // 删除会话
    deleteDialogue() {
      // const conversationType = this.$RongIMLib.ConversationType.PRIVATE;
      // const targetId = this.pitchChatUser.id;
      // this.$RongIMLib
      //   .removeConversation({
      //     conversationType,
      //     targetId: targetId,
      //   })
      //   .then((res) => {
      //     // 删除指定会话成功
      //     if (res.code === 0) {
      //       // console.log(res.code);
      //     } else {
      //       // console.log(res.code, res.msg);
      //     }
      //   });
    },
    //

    // 获取会话列表
    getConversationList() {
      let promise = this.chat.getConversationList({
        type: this.TencentCloudChat.TYPES.CONV_C2C,
      });
      promise
        .then((res) => {
          setTimeout(() => {
            // res.data.conversationList.forEach(item=>{
            //   let newavatar=item.userProfile.avatar.replaceAll('https://9gdata1.oss-cn-hangzhou.aliyuncs.com/https://9gdata1.oss-cn-hangzhou.aliyuncs.com/','https://9gdata1.oss-cn-hangzhou.aliyuncs.com/')

            //  item.userProfile.avatar=newavatar
            // })
            console.log(
              res.data.conversationList,
              "res.data.conversationListres.data.conversationList"
            );
            this.dialogueList = res.data.conversationList;
          }, 300);
        })
        .catch((error) => {
          console.warn("call error:", error);
        });
    },
    getChargeSetting() {
      this.$api.getChargeSettings({}).then((res) => {
        if (res.code == 1) {
          this.audioValue = res.data.audioCall;
          this.siValue1 = res.data.freeFlag == 1 ? true : false;
          this.siValue2 = res.data.freeFlag == 1 ? false : true;
          this.shortVideoValue = res.data.shortVideoCall;
          this.videoValue = res.data.videoCall;
        }
      });
    },
    BtnSubmit1() {
      this.$api
        .setChargeSettings({
          audioCall: this.audioValue,
          freeFlag: this.siValue1 ? 1 : 0,
          shortVideoCall: this.shortVideoValue,
          videoCall: this.videoValue,
        })
        .then((res) => {
          if (res.code == 1) {
            this.isSet = false;
            this.$message("私信设置成功");
          }
        });
    },
    // 关注
    // 关注
    followUser() {
      this.isGuan = true;
    },
    shareBtnPop() {},
    sendMassage(){

    },
    // 显示
    showChange(type, id) {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      this.getUserInfo();
      switch (type) {
        case "messageTopup": // 他人主页显示
          this.myMessageShow = true;
          break;
        case "others": // 他人主页显示
          this.manitoId = id;
          this.othersShow = !this.othersShow;
          break;
        case "ranking": // 排行榜显示
          this.ranking = !this.ranking;
          break;
        case "personal": //个人中心
          this.personalShow = !this.personalShow;
          break;
        case "helpindex": // 帮助中心
          this.helpindexShow = !this.helpindexShow;
          break;
        case "blindBox": // 盲盒
          this.blindBoxShow = !this.blindBoxShow;
          break;
        case "first": //首充
          this.firstShow = !this.firstShow;
          // this.walletTopupShow = !this.walletTopupShow;
          break;
        case "enter": //公会入驻
          this.enterShow = !this.enterShow;
          break;
        case "message": //首充
          this.myMessageShow = !this.myMessageShow;
          break;
        case "manito": //成为大神
          this.manitoShow = !this.manitoShow;
          break;
        default:
          break;
      }
    },
    BtnSubmit() {
      this.$api
        .followUser({
          followUserIds: this.pitchChatUser.id,
          groupId: this.FenValue,
        })
        .then((res) => {
          if (res.code == 1) {
            this.isGuan = false;
            this.$set(this.pitchChatUser, "isFollow", 1);
            this.selectUserChat();
          }
        });
    },
    // 取消关注
    stopFollowUser() {
      this.$api
        .stopFollowUser({ followUserId: this.pitchChatUser.id })
        .then((res) => {
          if (res.code == 1) {
            this.$set(this.pitchChatUser, "isFollow", 0);
            this.selectUserChat();
          }
        });
    },
    // 删除聊天记录
    deleteChattingRecords() {
      this.chat.clearHistoryMessage(this.atuserObj.conversationID);
      this.clearTheChatShow = false;
      this.chatList = [];
    },
    //获取用户信息
    getUserInfo() {
      this.$api.queryUser().then((res) => {
        // console.log("用户信息", res);
        if (res.code == 1) {
          this.myUser = res.data;
        }
      });
    },
    // 发送自定义消息
    sendCreateCustomMessage(data) {
      let message = this.chat.createCustomMessage({
        to: data.userProfile.userID,
        conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
        // 消息优先级，用于群聊
        payload: {
          data: JSON.stringify(data), // 用于标识该消息是骰子类型消息
          description: JSON.stringify(data), // 获取骰子点数
          extension: JSON.stringify(data),
        },
      });
      let promise = this.chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          // 发送成功
          this.chatList.push();
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    // 发送消息
    async sendTextChange(event) {
      let that = this;
      let arr2 = [];
      event.preventDefault();
      // this.test();

      console.log(event, "ssssssbbbbb");
      // event.preventDefault();
      await this.chat
        .getBlacklist()
        .then(function (imResponse) {
          imResponse.data.forEach((item) => {
            if (item == that.atuserObj.userProfile.userID) {
              arr2.push(item);
            }
          });
        })
        .catch(function (imError) {
          console.warn("getBlacklist error:", imError); // 获取黑名单列表失败的相关信息
        });
      // if (arr2.length) {
      // this.$message.error("您已拉黑对方");
      // } else {
      if (!this.chatText) return this.$message.info("请输入内容");
      if (this.chatText.split(" ").join("").length == 0)
        return this.$message.info("请输入内容");
      let sendMessage = {
        to: this.atuserObj.userProfile.userID,
        conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
        payload: {
          text: this.chatText,
        },
      };
      let message = this.chat.createTextMessage(sendMessage);
      let promise = this.chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          that.chatList.push(imResponse.data.message);
          that.chatText = "";
          that.scrollToBottom();
        })
        .catch(function (imError) {
          // 发送失败
          if (imError.code == 20007) {
            that.$message.error("您已被对方拉黑");
          }
        });
      // }
    },
    // jianting

    // 调起选择文件
    submitUpload() {
      this.$refs.upload.clearFiles();
    },
    // 上传成功
    handleAvatarSuccess(res) {
      // console.log(res,'ssssss')
      this.sendimg(res.data.url);
    },
    handleAvatarSuccess1(res) {
      console.log(res, "rsesrse");
      this.sendShiping(res.data.url);
      // if (res.code == 1) {
      //   this.fullscreenLoading = false;
      //   this.reportImgList.push(res.data.url);
      //   this.isSHowTu = false;
      // }
    },
    // 发送视频
    sendShiping(url) {
      let that = this;
      let message = that.chat.createVideoMessage({
        to: that.atuserObj.userProfile.userID,
        conversationType: that.TencentCloudChat.TYPES.CONV_C2C,
        // 消息优先级，用于群聊
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          file: that.files1,
        },
        onProgress: function (event) {
          console.log("file uploading:", event);
        },
      });
      let promise = this.chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          // 发送成功
          that.chatList.push(imResponse.data.message);
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    // 发送图片
    sendimg(url) {
      let that = this;
      let message = this.chat.createImageMessage({
        to: this.atuserObj.userProfile.userID,
        conversationType: this.TencentCloudChat.TYPES.CONV_C2C,
        // 消息优先级，用于群聊
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          file: this.files1,
        },
        onProgress: function (event) {
          console.log("file uploading:", event);
        },
      });

      // 2. 发送图片
      let promise = this.chat.sendMessage(message);
      promise
        .then(function (imResponse) {
          // 发送成功
          that.chatList.push(imResponse.data.message);
          console.log(imResponse, "发送图片");
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });

      // const conversation = { targetId: this.pitchChatUser.id };
      // // 实例化待发送消息，RongIMLib.TextMessage 为内置文本型消息
      // const message = new this.$RongIMLib.ImageMessage({
      //   content: "", // 图片缩略图，应为 Base64 字符串，且不可超过 80KB
      //   imageUri: url, // 图片的远程访问地址
      // });
      // // 配置属性
      // const options = {
      //   // 重发消息的 messageId, 可以从 onSendBefore 回调返回的 message 对象中 或 返回结果中获取
      //   messageId: 0,
      // };
      // this.$RongIMLib
      //   .sendMessage(conversation, message, options)
      //   .then((res) => {
      //     if (res.code === this.$RongIMLib.ErrorCode.SUCCESS) {
      //       // 消息发送成功，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送成功。
      //       // console.log("消息发送成功", res.data);
      //       this.chatList.push(res.data);
      //     } else {
      //       // 消息发送失败，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送失败。
      //       // console.log("消息发送失败", res.code, res.msg);
      //     }
      //   });
    },
    // 联系大神
    selectChat(i, index, id) {
      let that = this;
      this.atuserObj = i;
      this.userChatType = true;
      this.$api
        .sendShortVideos({
          othUserId: id,
        })
        .then((res) => {
          this.isSendShiping = res.data.status == 1 ? true : false;
        });
      this.$api.userMainInfo({ otherUserId: id }).then((res) => {
        if (res.code == 1) {
          this.pitchChatUser = res.data;
          this.manitoSkillData = res.data.userSkillVos[0];
          let options = {
            conversationID: "C2C" + id,
            direction: 0,
          };
          this.chat.setMessageRead(options);
          let promise = this.chat.getMessageListHopping({
            conversationID: "C2C" + id,
          });
          promise
            .then(function (imResponse) {
              // 获取成功
              imResponse.data.messageList.forEach((item) => {
                if (item.type == "TIMCustomElem") {
                  let data = JSON.parse(item.payload.data).data;
                  if (data) {
                    if (JSON.parse(data).call_end) {
                      item.call_end = JSON.parse(data).call_end;
                    }
                    console.log(item);
                  }
                }
              });
              that.chatList = imResponse.data.messageList;
            })
            .catch(function (imError) {
              console.warn("getConversationProfile error:", imError); // 获取会话资料失败的相关信息
            });
          if (index) {
          } else {
            this.getConversationList();
          }
        }
      });
    },
    goSelect(i, index, id) {
      i.userProfile = {
        userID: id,
      };
      this.selectChat(i, "", id);
      this.myMessageShow = true;
    },

    // 选中用户聊天
    selectUserChat(i, index, flag) {
      // 获取是否用于发送权限
      this.$api
        .sendShortVideos({
          othUserId: i.userProfile.userID ? i.userProfile.userID : i.id,
        })
        .then((res) => {
          this.isSendShiping = res.data.data == 1 ? true : false;
        });
      if (flag) {
        this.dialogueList.forEach((item) => {
          if (item.userProfile.userID == i.userProfile.userID) {
            i.conversationID = item.conversationID;
          }
        });
      }
      let otherUserId = "";
      this.atuserObj = i;
      if (index) {
        otherUserId = i.id;
      } else {
        otherUserId = i.userProfile.userID;
      }
      let that = this;

      this.atPresent = index - 1;
      this.userChatType = true;
      this.$api.userMainInfo({ otherUserId: otherUserId }).then((res) => {
        if (res.code == 1) {
          this.pitchChatUser = res.data;
          this.manitoSkillData = res.data.userSkillVos[0];
          this.scrollToBottom();
          let options = {
            conversationID: index ? "C2C" + i.id : i.conversationID,
          };
          this.chat.setMessageRead(options);
          let promise = this.chat.getMessageListHopping({
            conversationID: index ? "C2C" + i.id : i.conversationID,
            direction: 0,
          });
          promise
            .then(function (imResponse) {
              // 获取成功ss
              imResponse.data.messageList.forEach((item) => {
                if (item.type == "TIMCustomElem") {
                  let data = JSON.parse(item.payload.data).data;
                  if (data) {
                    if (JSON.parse(data).call_end) {
                      item.call_end = JSON.parse(data).call_end;
                    }
                    console.log(item);
                  }
                }
              });
              that.chatList = imResponse.data.messageList;
            })
            .catch(function (imError) {
              console.warn("getConversationProfile error:", imError); // 获取会话资料失败的相关信息
            });
          if (index) {
          } else {
            this.getConversationList();
          }
        }
      });
      // // // 获取历史记录

      // const conversation = {
      //   conversationType: this.$RongIMLib.ConversationType.PRIVATE,
      //   targetId: i.id,
      // };

      // // 从当前时间开始向前查询
      // const option = {
      //   timestamp: 0,
      //   count: 20,
      //   order: 0,
      // };
      // this.$RongIMLib.getHistoryMessages(conversation, option).then((res) => {
      //   // console.log('历史记录',res);
      //   if (res.code === 0) {
      //     this.chatList = res.data.list;
      //   } else {
      //   }
      // });
    },
    //关闭页面
    closeMessage() {
      this.$emit("closeMessage");
    },
    // 切换消息类型
    tabTypeChange(type) {
      this.atPresent = "";
      this.tabType = type;
      if (this.tabType == 0) {
        this.queryLastMessage();
        this.getConversationList();
      } else if (this.tabType == 1) {
        // 我的关注
        this.followOrFansList(1);
      } else if (this.tabType == 2) {
        // 我的粉丝
        this.followOrFansList(0);
      } else if (this.tabType == 3) {
        this.tongList();
      }
    },
    //获取用户是否可以发视频
    getUserSendShiping() {
      this.$api.sendShortVideos().then((res) => {
        console.log(res);
      });
    },
    //关注&&粉丝列表
    followOrFansList(type) {
      let data = {
        pageNum: 1,
        pageSize: 999,
        onlineType: 0, //	状态 0-全部 1-在线
        type: type, //	类型0-粉丝 1-关注
      };
      this.$api.followOrFansList(data).then((res) => {
        if (res.code == 1) {
          this.attentionlist = res.data.rows;
        }
      });
    },
    // 各类型最新消息
    queryLastMessage() {
      this.$api.queryLastMessage().then((res) => {
        // console.log("最新消息", res);
        if (res.code == 1) {
          this.newestMessage = res.data;
        }
      });
    },
    // 消息列表
    queryAllList(i, type) {
      this.userChatType = false;
      this.pitchMessageType = i;
      this.atPresent = type;
      let data = {
        pageNum: 1,
        pageSize: 999,
        type: type, //消息类型: 1平台公告；2系统通知；3订单消息；4派单消息；5互动消息
      };
      this.$api.queryAllList(data).then((res) => {
        if (res.code == 1) {
          this.messageList = res.data.rows;
          this.$api.readByType({ type: type }).then((res) => {
            if (res.code == 1) {
              this.getUnreadCount();
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.searchInp{
  
}
.popUp_message {
  width: 320px;
  height: 605px;
  background-color: #fff;
}
.popUp_header {
  width: 100%;
  height: 53px;
  background: #f8f8fa;
}
.sendMassage {
  width: 140px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #212121;
  background-color: rgba(255, 221, 0, 1);
  border: 1px solid rgba(255, 221, 0, 1);
  margin-left: 12px;
  cursor: pointer;
}
.shareBtn {
  width: 100px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #1b85fb;
  text-align: center;
  line-height: 36px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #1b85fb;
  margin-left: 69px;
  cursor: pointer;
}
.arrdessData {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.massageBtn {
  width: 266px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 4px 4px 4px 4px;
  color: rgba(33, 33, 33, 1);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 4px;
  box-sizing: border-box;
}
.messagePerson {
  background: rgba(133, 188, 254, 1);
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  font-size: 10px;
  color: #ffffff;
  border-radius: 15px 15px 15px 15px;
}
.massageBtnFlex {
  width: 86px;
  height: 32px;
  background: #212121;
  border-radius: 4px 4px 4px 4px;
  color: #fff;
  text-align: center;
  line-height: 32px;
}
.massageBtnFlexFriend {
  width: 86px;
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  color: #333;
  text-align: center;
  line-height: 32px;
}
.allMsg_box {
  width: 100%;
  height: 650px;
  overflow-y: scroll;
}
.img_style {
  width: 360px;
  margin-top: 12px;
  border-radius: 8px;
}
.renewalBtn {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #8458f4;
  border-radius: 8px;
  margin: 16px 16px;
}
.num_icon {
  width: 18px;
  height: 18px;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 45px;
  display: flex;
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: red;
}
.send_style {
  margin-top: 8px;
  color: #8e92b1;
  > p {
    line-height: 18px;
  }
}
//大神技能
.manitoSkill {
  width: 603px;
  height: 85px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 20px;
  background: rgba($color: #ffa700, $alpha: 0.3);
  border-radius: 14px;
  box-sizing: border-box;
  .order_btn {
    width: 88px;
    height: 36px;
    font-size: 14px;
    color: #212121;
    background: linear-gradient(102deg, #ffa700 0%, #ffdd00 100%);
    border-radius: 18px;
  }
  .discounts_icon {
    width: 45px;
    height: 20px;
    position: absolute;
    top: -18px;
    right: -40px;
    color: #fff;
    font-size: 12px;
    background: url(../../image/message/22.png) no-repeat;
  }
}
//举报弹窗
.report_title {
  width: 679px;
  height: 36px;
  color: #ff7268;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 114, 104, 0.1);
}
.report_from {
  width: 320px;
  margin: 0 180px;
  .report_fromTitle {
    color: #000;
    font-size: 14px;
    margin: 20px 0 10px 0;
    text-align: left;
  }
  .report_btn {
    width: 320px;
    height: 40px;
    color: #333;
    margin: 30px 0 40px 0;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.imgBox {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  .imgDeleIcon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
.popUp_box3 {
  width: 320px;
  height: 605px;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 background-color: #fff;
}
.popUp_box2 {
  width: 886px;
  height: 554px;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  background-image: url(../../static/to3.png);
}
.popUp_TitleBottom {
  width: 100%;
  height: 128px;
  background: rgba(57, 57, 57, 0.8);
  position: absolute;
  bottom: 0;
}
.chooseLiao {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.tongxunText {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}
.popUp_TitleBottomFlex {
  display: flex;
  width: calc(100% - 578px);
  margin: 24px auto;
  justify-content: space-between;
}
.popUp_Title {
  width: calc(100% - 48px);
  margin: 24px auto 0;
  display: flex;
  justify-content: space-between;
}
.popUp_TitleImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.popUp_TitleFlex {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
// 弹窗背景
.popUp_box1 {
  width: 442px;
  height: 788px;
  position: absolute;
  z-index: 9999;
  background-size: 100% 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  .remid_box {
    width: 522px;
    position: absolute;
    top: 40%;
    left: 50%;
    font-size: 14px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
  }
  .contentSi1 {
    width: 334px;
    margin: 0px auto 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .contentSi {
    width: 334px;
    margin: 0px auto 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 12px 10px;
    box-sizing: border-box;
  }
  .BtnSubmit {
    width: 256px;
    height: 40px;
    background: #ffdd00;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 0 auto;
  }
  .remind_btn {
    width: 176px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px 4px 4px 4px;
    color: #333;
  }
}
//聊天内容
.chat_box {
  color: #333333;
  font-size: 14px;
  margin-top: 18px;
  .my {
    align-items: flex-start;
    justify-content: flex-end;
  }
  .other {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .userHead {
    width: 45px;
    height: 45px;
    margin: 0 10px;
    border-radius: 50%;
  }
  .my_content {
    max-width: 500px;
    position: relative;
    padding: 13px 9px 13px 13px;
    background: #ffffff;
    border-radius: 6px;
    margin-right: 4px;
  }
  .my_content::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: -7px;
    top: 6px;
    border-top: solid 7px transparent;
    border-left: solid 7px #fff;
    border-bottom: solid 7px transparent;
  }
  .other_content {
    max-width: 500px;
    padding: 13px 9px 13px 13px;
    background: #ffffff;
    position: relative;
    border-radius: 6px;
    margin-left: 4px;
    color: #333;
  }
  .other_content::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -7px;
    top: 6px;
    border-top: solid 7px transparent;
    border-right: solid 7px #fff;
    border-bottom: solid 7px transparent;
  }
  .chatImgUrl {
    max-width: 450px;
    height: auto;
  }
}
.userChat_age {
  width: 62px;
  height: 22px;
  margin: 0 13px;
  color: #333;
  font-size: 12px;
  border-radius: 11px;
}
//用户聊天
.sendBtn {
  width: 68px;
  height: 32px;
  font-size: 14px;
  color: #212121;
  background: #ffdd00;
  border-radius: 7px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.userChat_top {
  width: 648px;
  height: 74px;
  color: #333;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  .pitchChatUserName {
    font-size: 18px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    color: #212121;
  }
  .userChat_topIcon {
    height: 22px;
    padding: 0 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    margin-right: 15px;
    border-radius: 11px 11px 11px 11px;
    color: #fff;
  }
  .userChat_attentionIcon {
    width: 70px;
    height: 28px;
    font-size: 16px;
    background: linear-gradient(91deg, #ffa700 0%, #ffdd00 100%);
    border-radius: 8px 2px 8px 2px;
  }
  .userChat_img {
    width: 23px;
    height: 23px;
    margin-left: 20px;
  }
}
.userChat_content {
  width: 100%;
  height: 490px;
  padding: 10px 0 20px 0;
  overflow-y: scroll;
  box-sizing: border-box;
}
.userChat_inputBox {
  padding: 0 17px 20px 17px;
  border-top: 1px solid #31364c;
  .iconImg {
    width: 24px;
    height: 24px;
    margin-right:5px;
  }
}
.send_status {
  width: 40px;
  color: #e78034;
  font-size: 12px;
  position: absolute;
  top: 0;
}
.roomSkip {
  width: 121px;
  height: 31px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  left: -80px;
}
.messageType_box {
  width: 648px;
  height: 720px;
  margin: 13px 0 0 5px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 10px;
  padding-top: 10px;
  overflow: hidden;
  .messageTitle {
    font-size: 16px;
    padding: 18px 24px;
  }
  .content_box {
    width: 500px;
    padding: 10px;
    color: #212121;
    background: #f5f5f5;
    border-radius: 8px;
  }
}
.page {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: -60px;
  left: 0;
  font-size: 16px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.8);
}
.message_box {
  width: 1106px;
  height: 798px;
  background: #f5f5f5;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .left_tab {
    width: 121px;
    height: 752px;
    background: #faf8ec;
    display: flex;
    flex-direction: column;
    align-items: center;
    .head {
      width: 63px;
      height: 63px;
      border-radius: 50%;
      margin: 24px 0 0 0;
    }
    .tab_item {
      width: 90px;
      height: 90px;
      color: #333;
      font-size: 12px;
      margin: 0 auto;
      margin-top: 24px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      > img {
        margin-bottom: 8px;
      }
    }
    .tab_paht {
      background: linear-gradient(138deg, #ffa700 0%, #ffdd00 100%);
    }
  }
  .message_list {
    width: 300px;
    height: 725px;
    margin: 0px 10px 0 12px;
    background: #fff;
    box-sizing: border-box;
    overflow-y: scroll;
    .message_item {
      width: 291px;
      height: 64px;
      padding: 0 16px;
      color: #333;
      margin-bottom: 10px;
      background: #fff;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      .message_head {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .message_icon {
        width: 20px;
        height: 20px;
      }
    }
    .message_pitch {
      background: rgba($color: #fff, $alpha: 0.1);
    }
    .unreadNum {
      width: 18px;
      height: 18px;
      font-size: 12px;
      background-color: red;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 16%;
    }
  }
  .message_content {
    width: 660px;
    height: 752px;
    position: relative;
    background: #fff;
    .vacancy {
      width: 211px;
      height: 211px;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .messageContent {
    width: 360px;
    height: 282px;
    background: #f4f4f4;
    border-radius: 8px 8px 8px 8px;
    margin: 81px auto 0;
  }
  .touTitle {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #1b85fb;
    padding-left: 16px;
  }
  .touTitleRight {
    width: 85px;
    height: 26px;
    background: url(../../static/user28.png) no-repeat;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: center;
    line-height: 26px;
  }
  .titleDur {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .messageLine {
    width: 616px;
    height: 1px;
    background-color: rgba(238, 238, 238, 1);
    margin: 24px auto 0;
  }
}
.pageSex1 {
  width: 34px;
  height: 16px;
  background: rgb(255, 128, 124);
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.pageSex {
  width: 34px;
  height: 16px;
  background: #85bcfe;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.inpMassage {
  width: 232px;
  height: 36px;
  background: #f2f2f2;
  border-radius: 18px 18px 18px 18px;
  padding-left: 32px;
  border: none;
}
.messageText{
  cursor: pointer;
}
/deep/.el-textarea__inner {
  border: 0;
  color: #333;
  background-color: #f5f5f5;
}
.textarea /deep/.el-textarea {
  background: transparent !important;
}
.textarea /deep/.el-input__count {
  background: transparent !important;
}
.textarea /deep/.el-textarea__inner {
  background: #f5f5f5 !important;
}
/deep/.el-input__inner {
  color: #333;
  background: #f5f5f5 !important;
}
/deep/.el-input__inner::placeholder {
  color: #333 !important;
}
// 上传文件样式
/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}
::-webkit-scrollbar {
  width: 0;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0,0.2);
  border-radius: 0;
  background: rgba(0, 0, 0,0.2);
}
</style>
